import { combineReducers } from "redux";
import userReducer from "./users/userReducer";
import errorReducer from "./errors/errorReducer";
import fullVertragReducer from "./fullVertrags/fullVertragReducer";
import vertragsListReducer from "./vertragsArray/vertragsArrayReducer";
import gesellschaftenReducer from "./gesellschaften/gesellschaftenReducer";
import filesReducer from "./files/filesReducer";
import householdInterviewReducer from "./householdInterview/householdInterviewReducer";
import appSettingsReducer from "./appSettings/appSettingsReducer";
import { HouseholdInterviewStateProps } from "./householdInterview/householdInterviewTypes";
import { CompaniesArrayStateType } from "./vertragsArray/vertragsArrayTypes";

export interface StateType {
  userReducer: any;
  errorReducer: any;
  fullVertragReducer: any;
  vertragsListReducer: CompaniesArrayStateType;
  gesellschaftenReducer: any;
  filesReducer: any;
  appSettingsReducer: any;
  householdInterviewReducer: HouseholdInterviewStateProps;
}

const appReducer = combineReducers({
  userReducer: userReducer,
  errorReducer: errorReducer,
  fullVertragReducer: fullVertragReducer,
  vertragsListReducer: vertragsListReducer,
  gesellschaftenReducer: gesellschaftenReducer,
  filesReducer: filesReducer,
  householdInterviewReducer: householdInterviewReducer,
  appSettingsReducer: appSettingsReducer,
});

const rootReducer = (state: StateType | undefined, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state as undefined, action);
};

export default rootReducer;
