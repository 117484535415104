import axios from "axios";
import { getURLParam } from "../../components/Helper/URLHelper/getURLParam";
import {
  clearAppSettings,
  showProcessAlert,
  showProcessSpinner,
} from "../appSettings/appSettingsActions";
import { returnErrors, clearErrors } from "../errors/errorActions";
import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ADD_USER_PSWD,
  PSWD_CONFIRM,
  PSWD_CHANGE_MSG,
  CLEAN_PSWD_STATE,
  HANDLE_MANDAT,
  GET_USER_ID,
  SAVE_USER_AVATAR,
} from "./userTypes";
import { SET_USER_DISK_DATA } from "../files/filesTypes";
import { diskFolderuploadFile } from "../files/filesActions";
import { getFullVertrag } from "../fullVertrags/fullVertragActions";

export const getUserId = () => (dispatch, getState) => {
  return axios
    .get("/getUserId", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_USER_ID, payload: res.data.id });

      return res.data.id;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const loadUser = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  await axios({
    method: "post",
    url: `/getUserById`,
    headers: tokenConfig(getState).headers,
    data: {
      userId: id,
    },
  })
    .then((res) => {
      const userData = res?.data?.result;
      dispatch({
        type: USER_LOADED,
        payload: {
          _id: userData.ID,
          isAdmin: parseInt(userData.UF_CRM_1647175335133) ? true : false,
          user: {
            contactVorname: userData.NAME ? userData.NAME : "",
            contactNachname: userData.LAST_NAME ? userData.LAST_NAME : "",
            contactStrasse: userData.UF_CRM_1583877162820 ? userData.UF_CRM_1583877162820 : "",
            contactPostleitzahl: userData.UF_CRM_1583877184993 ? userData.UF_CRM_1583877184993 : "",
            contactOrt: userData.UF_CRM_1583877201138 ? userData.UF_CRM_1583877201138 : "",
            contactLand: "Deutschland",
            contactGeburtsdatum: userData.BIRTHDATE
              ? userData.BIRTHDATE.substr(0, 10).replace(/-/g, ".")
              : "",
            contactHandynummer:
              ("Y" === userData.HAS_PHONE &&
                userData.PHONE.filter((phone) => phone.VALUE_TYPE === "MOBILE")[0]?.VALUE) ||
              "",
            contactEmail: userData.EMAIL ? userData.EMAIL[0].VALUE : "",
            contactHandynummerId:
              ("Y" === userData.HAS_PHONE &&
                userData.PHONE.filter((phone) => phone.VALUE_TYPE === "MOBILE")[0]?.ID) ||
              "",
            contactEmailId: userData.EMAIL ? userData.EMAIL[0].ID : "",
            contactDocumentsLink: userData.UF_CRM_1600688806322
              ? userData.UF_CRM_1600688806322
              : "",
            contactDocumentsId: userData.UF_CRM_1600688806322
              ? getURLParam(userData.UF_CRM_1600688806322, "folderId")
              : "",
            contactBIC: userData?.UF_CRM_1603802624349 ?? "",
            contactIBAN: userData?.UF_CRM_1603802581409 ?? "",
            personalBitrixId: userData?.UF_CRM_1667576214 ?? "",
            lastLoginDate: userData?.UF_CRM_1669213343 ?? "",
            householdInterviewLink: userData?.UF_CRM_1583875236964 ?? "",
          },
        },
      });

      if (Number(userData?.UF_CRM_1695409007)) {
        getState().householdInterviewReducer.relationsData.forEach((relationData) => {
          const relation = userData[relationData.dataId];
          if (relation.length) {
            if (Array.isArray(relation)) {
              relation.forEach((relationId) => dispatch(getFullVertrag(relationId)));
            } else {
              dispatch(getFullVertrag(relation));
            }
          }
        });
      }
    })
    .catch((error) => {
      dispatch(returnErrors(error.response.data, error.response.status));
      dispatch({ type: AUTH_ERROR });
    });

  const contactDocumentsId = getState().userReducer.user.contactDocumentsId;

  if (contactDocumentsId.length !== 0) {
    axios({
      method: "post",
      url: `/diskFolderGetChildren`,
      headers: tokenConfig(getState).headers,
      data: {
        contactDocumentId: contactDocumentsId,
      },
    })
      .then(async (res) => {
        dispatch({ type: SET_USER_DISK_DATA, payload: res.data.result });
        res.data.result?.forEach((el) => {
          if (el.NAME.split(".").slice(0, -1).join(".") === "avatar") {
            axios({
              method: "post",
              url: "/vertragsUebersicht/getFilesPath",
              headers: tokenConfig(getState).headers,
              data: {
                filesId: el.FILE_ID,
              },
            })
              .then((res) => {
                dispatch({
                  type: SAVE_USER_AVATAR,
                  payload: {
                    contactAvatar: `https://bitrix.vertragsengel.de/upload/${res.data.results[0].subDirLogo}/${res.data.results[0].filename}`,
                    contactAvatarName: el.NAME,
                  },
                });
              })
              .catch((err) => console.log(err));
          }
        });

        const mandatFileExistsInMainFolder = res?.data?.result?.filter(
          (file) =>
            file.TYPE === "file" &&
            (file.NAME === "Vollmacht.pdf" || file.NAME === "_Vollmacht_.pdf")
        );

        if (!mandatFileExistsInMainFolder.length) {
          const mandatFolderExists = res?.data?.result?.filter(
            (file) => file.TYPE === "folder" && file.NAME === "Vollmachten"
          );

          let mandatFolder = [];

          if (mandatFolderExists?.length) {
            mandatFolder = await axios({
              method: "post",
              url: `/diskFolderGetChildren`,
              headers: tokenConfig(getState).headers,
              data: {
                contactDocumentId: mandatFolderExists[0]?.ID,
              },
            });
          }
          const mandatFileExists = mandatFolder?.data?.result?.filter(
            (file) => file.TYPE === "file" && file.NAME === "_Vollmacht_.pdf"
          );

          if (!mandatFileExists?.length) {
            dispatch({ type: HANDLE_MANDAT, payload: true });
          }
        }
      })
      .catch((err) => console.log(err));
  }
};

export const checkIfUserExists = (email) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  email = email.toLowerCase();
  return axios
    .post("/checkIfUserExists", { email }, config)
    .then((res) => res)
    .catch((err) => err);
};

export const addContact =
  (firstname, lastname, email, password = "", sourceDescription = "") =>
  async (dispatch, getState) => {
    dispatch(showProcessSpinner(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let bcryptedPassword = password;

    if ("" !== password) {
      await axios
        .post("/bcryptPassword", { password }, config)
        .then((res) => {
          bcryptedPassword = res.data.passwordHash;
          password = bcryptedPassword;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const { userReducer, householdInterviewReducer } = getState();

    const mainContactAssignedBy =
      householdInterviewReducer?.mainContactData?.contactToInterview?.ASSIGNED_BY_ID;

    return await axios
      .post(
        `/addContact`,
        {
          firstname: firstname,
          lastname: lastname,
          bcryptedPassword: bcryptedPassword,
          email: email,
          sourceDescription: sourceDescription,
          assignedById: mainContactAssignedBy
            ? mainContactAssignedBy
            : userReducer?.user?.personalBitrixId || "",
        },
        config
      )
      .then(async (res) => {
        return await axios
          .post("/signup", { contactData: { email: email }, contactId: res.data }, config)
          .then((res) => {
            dispatch(showProcessSpinner(false));
            return res;
          })
          .catch((err) => {
            return err;
          });
      })
      .catch((err) => {
        return err;
      });
  };

//BITRIX
export const register =
  ({
    lastname,
    firstname,
    email,
    password,
    registerOptionen = "",
    empfehlung = "",
    berater = "",
  }) =>
  async (dispatch) => {
    dispatch(showProcessSpinner(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const contactData = {
      lastname,
      firstname,
      email,
      password,
      registerOptionen,
      empfehlung,
      berater,
    };

    dispatch(clearErrors());
    email = email.toLowerCase();

    let bcryptedPassword = password;

    await axios
      .post("/bcryptPassword", { password }, config)
      .then((res) => {
        bcryptedPassword = res.data.passwordHash;
        contactData.password = bcryptedPassword;
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .post("/checkIfUserExists", { email }, config)
      .then(async (res) => {
        if (res.data.userAlreadyExists) {
          dispatch(returnErrors(res.data.msgText, res.status, "REGISTER_FAIL"));
          dispatch({ type: REGISTER_FAIL });

          return;
        }

        await axios
          .post(
            `/addContact`,
            {
              firstname: firstname,
              lastname: lastname,
              bcryptedPassword: bcryptedPassword,
              email: email,
            },
            config
          )
          .then(async (res) => {
            await axios
              .post("/signup", { contactData, contactId: res.data }, config)
              .then((res) => {
                dispatch({
                  type: REGISTER_SUCCESS,
                });
                dispatch(returnErrors(res.data.text, res.status, "REGISTER_SUCCESS"));
              })
              .catch((err) => console.log(err));
          })
          .catch((error) => {
            dispatch(returnErrors(error.response.data, error.response.status, "REGISTER_FAIL"));
            dispatch({ type: REGISTER_FAIL });
          });
      })
      .catch((err) => console.log(err));

    dispatch(showProcessSpinner(false));
  };

export const logout = () => (dispatch, getState) => {
  dispatch(clearAppSettings());
  dispatch({ type: LOGOUT_SUCCESS });
};

export const saveUserFilesToDoc = (values) => async (dispatch, getState) => {
  let existsFiles = [];
  let delFile = false;
  let fileId = 0;
  if (values.contactDocumentsId !== undefined) {
    existsFiles = await axios({
      method: "post",
      url: `/diskFolderGetChildren`,
      headers: tokenConfig(getState).headers,
      data: {
        contactDocumentId: values.contactDocumentsId,
      },
    });

    if (existsFiles.data.result.length !== 0) {
      // eslint-disable-next-line
      existsFiles.data.result.map((el) => {
        if (
          (values.file === null || values.file !== getState().userReducer.contactAvatar) &&
          el.NAME.split(".").slice(0, -1).join(".") ===
            values.fileName.split(".").slice(0, -1).join(".")
        ) {
          delFile = true;
          fileId = el.ID;
        }
      });
    }

    if (delFile) {
      await axios({
        method: "post",
        url: `/diskFileDelete`,
        headers: tokenConfig(getState).headers,
        data: { fileId: fileId },
      })
        .then((res) => {
          dispatch({
            type: SAVE_USER_AVATAR,
            payload: {
              contactAvatar: null,
              contactAvatarName: null,
            },
          });
        })
        .catch((err) => console.log(err));
    }

    if (values.file !== getState().userReducer.contactAvatar && values.file !== null) {
      let data = new URLSearchParams();
      data.append(`id`, `${values.contactDocumentsId}`);
      data.append(`data[NAME]`, `${values.fileName}`);
      data.append(`fileContent[0]`, `${values.fileName}`);
      data.append(`fileContent[1]`, `${values.file.split(",")[1]}`);

      const uploadedFile = await dispatch(diskFolderuploadFile(data));

      axios({
        method: "post",
        url: "/vertragsUebersicht/getFilesPath",
        headers: tokenConfig(getState).headers,
        data: {
          filesId: uploadedFile.FILE_ID,
        },
      })
        .then((res) => {
          dispatch({
            type: SAVE_USER_AVATAR,
            payload: {
              contactAvatar: `https://bitrix.vertragsengel.de/upload/${res.data.results[0].subDirLogo}/${res.data.results[0].filename}`,
              contactAvatarName: uploadedFile.NAME,
            },
          });
        })
        .catch((err) => console.log(err));
    }
  }
};

export const removeAccessToApp =
  (userId, user, removeAccountReason) => async (dispatch, getState) => {
    dispatch(showProcessSpinner(true));

    await axios({
      method: "post",
      url: "/updateContactById",
      headers: tokenConfig(getState).headers,
      data: { userId: userId, UF_CRM_1616490815: "", UF_CRM_1616490835: "" },
    })
      .then((res) => {
        axios.post(
          `/sendInfoEmail`,
          {
            subject: "Konto gelöscht",
            text: `${user.contactVorname} ${user.contactNachname} hat seinen Account gelöscht.\nKunden-ID: ${userId}\nGrund: ${removeAccountReason}`,
          },
          tokenConfig(getState)
        );
        dispatch(logout());
      })
      .catch((error) =>
        dispatch(showProcessAlert("info-header2", "Ist etwas schief gelaufen", true))
      );

    dispatch(showProcessSpinner(false));
  };

export const updateUserData = (user) => (dispatch, getState) => {
  dispatch(saveUserFilesToDoc(user));

  let oldUserData = getState().userReducer.user;
  let userId = getState().userReducer.id;
  let setPhoneIdToRequestIfExist = "";
  if (oldUserData.contactHandynummerId !== "") {
    setPhoneIdToRequestIfExist = oldUserData.contactHandynummerId;
  }

  var oldUserDataArr = Object.keys(oldUserData).map((key) => [key, oldUserData[key]]);
  var newUserDataArr = Object.keys(user).map((key) => [key, user[key]]);

  let whatIsChanged = "";

  // eslint-disable-next-line
  newUserDataArr.map((el) => {
    // eslint-disable-next-line
    oldUserDataArr.map((element) => {
      if (el[0] === element[0] && el[1] !== element[1]) {
        oldUserData[el[0]] = el[1];
        whatIsChanged +=
          "War: " + element[0] + ": " + element[1] + " Ist: " + el[0] + ": " + el[1] + "<br>";
      }
    });
  });

  if (whatIsChanged !== "") {
    whatIsChanged += "Kontakt id: " + userId;
  }

  axios({
    method: "post",
    url: "/updateUserData",
    headers: tokenConfig(getState).headers,
    data: {
      userId: userId,
      firstName: user.contactVorname,
      lastName: user.contactNachname,
      street: user.contactStrasse,
      postCode: user.contactPostleitzahl,
      city: user.contactOrt,
      birthday: user.contactGeburtsdatum,
      phoneId: setPhoneIdToRequestIfExist,
      phone: user.contactHandynummer,
    },
  })
    .then((res) => {
      if (res.data && whatIsChanged !== "") {
        axios
          .post("/profil", { whatIsChanged: whatIsChanged }, tokenConfig(getState))
          .catch((error) => console.log(error));
        dispatch({
          type: USER_LOADED,
          payload: {
            user: oldUserData,
            isAdmin: getState().userReducer.isAdmin,
            _id: user.id !== undefined ? user.id : user.contactId,
          },
        });
      }
      dispatch(showProcessAlert("info-header", "Profil erfolgreich geändert", true));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateUserBankData = (user) => (dispatch, getState) => {
  dispatch(saveUserFilesToDoc(user));
  let oldUserData = getState().userReducer.user;

  var oldUserDataArr = Object.keys(oldUserData).map((key) => [key, oldUserData[key]]);
  var newUserDataArr = Object.keys(user).map((key) => [key, user[key]]);

  let whatIsChanged = "";

  newUserDataArr.forEach((el) => {
    oldUserDataArr.forEach((element) => {
      if (el[0] === element[0] && el[1] !== element[1]) {
        oldUserData[el[0]] = el[1];
        whatIsChanged +=
          "War: " + element[0] + ": " + element[1] + " Ist: " + el[0] + ": " + el[1] + "<br>";
      }
    });
  });

  if (whatIsChanged !== "") {
    whatIsChanged += "Kontakt id: " + user.id;
  }

  axios({
    method: "post",
    url: "/updateUserBankData",
    headers: tokenConfig(getState).headers,
    data: {
      userId: user.id,
      bic: user.contactBIC,
      iban: user.contactIBAN,
    },
  })
    .then((res) => {
      if (res.data && whatIsChanged !== "") {
        axios
          .post("/profil", { whatIsChanged: whatIsChanged }, tokenConfig(getState))
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          });
        dispatch({
          type: USER_LOADED,
          payload: {
            user: oldUserData,
            isAdmin: getState().userReducer.isAdmin,
            _id: user.id !== undefined ? user.id : user.contactId,
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const userMandate = (values, imageURL, contactDocumentsId) => (dispatch, getState) => {
  dispatch(updateUserData(values));

  axios({
    method: "post",
    url: "/sendMandat",
    headers: tokenConfig(getState).headers,
    data: {
      values,
      imageURL: imageURL,
      contactDocumentsId: contactDocumentsId,
    },
  })
    .then((res) => {
      dispatch({ type: HANDLE_MANDAT, payload: res.data.showMandat });
    })
    .catch((err) => console.log(err));
};

//BITRIX LOGIN
export const login =
  ({ email, password }) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, password });
    axios
      .post("/login", body, config)
      .then(async (res) => {
        dispatch(
          updateDynamicUserData(res.data.user.id, [
            {
              fieldName: "UF_CRM_1669213343",
              value: new Date().toLocaleDateString() + "T" + new Date().toLocaleTimeString(),
            },
          ])
        );
        dispatch(clearErrors());
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status, "LOGIN_FAIL"));
        dispatch({
          type: LOGIN_FAIL,
        });
      });
  };

export const updateDynamicUserData = (userId, dataArr) => (dispatch, getState) => {
  return axios({
    method: "post",
    url: "/updateDynamicUserData",
    headers: tokenConfig(getState).headers,
    data: { userId: userId, dataArr: dataArr },
  })
    .then((res) => {})
    .catch((e) => console.log(e));
};

export const passwortVergessen = (data) => async (dispatch, getState) => {
  dispatch(showProcessSpinner(true));

  axios({
    method: "post",
    url: "/contactListOnEmail",
    headers: tokenConfig(getState).headers,
    data: { email: data.email },
  })
    .then((res) => {
      if (!res.data.length) {
        dispatch(clearErrors());
        dispatch(
          returnErrors("Benutzer mit dieser E-Mail-Adresse existiert nicht", 400, "FORGOT_PASSWORD")
        );
      } else if (res.data.length === 1) {
        dispatch(clearErrors());
        dispatch(
          returnErrors(
            "Besuchen Sie Ihre Mailbox, an die wir den Link zum Zurücksetzen des Passworts gesendet haben",
            200,
            "FORGOT_PASSWORD"
          )
        );
        axios
          .put("/passwort-vergessen", {
            userId: res.data[0].ID,
            email: data.email,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(clearErrors());
        dispatch(
          returnErrors(
            "Irgendetwas ist schief gelaufen, bitte kontaktieren Sie uns",
            400,
            "FORGOT_PASSWORD"
          )
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });

  dispatch(showProcessSpinner(false));
};

export const comparePswd = (pswd, pswdToCompare) => (dispatch, getState) => {
  axios({
    method: "post",
    url: "/comparePswd",
    headers: tokenConfig(getState).headers,
    data: {
      pswd: pswd,
      pswdToCompare: pswdToCompare,
    },
  })
    .then((res) => {
      dispatch({
        type: PSWD_CONFIRM,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changePswd = (values, userId) => (dispatch, getState) => {
  axios({
    method: "post",
    url: "/changePswd",
    headers: tokenConfig(getState).headers,
    data: {
      values: values,
      userId: userId,
    },
  })
    .then((res) => {
      dispatch({
        type: PSWD_CHANGE_MSG,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUserPswd = (userId) => (dispatch, getState) => {
  axios({
    method: "post",
    url: "/getUserPswd",
    headers: tokenConfig(getState).headers,
    data: {
      userId: userId,
    },
  })
    .then((res) => {
      dispatch({
        type: ADD_USER_PSWD,
        payload: res.data.userPswd,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPassword = (data) => (dispatch) => {
  axios({
    method: "patch",
    url: "/passwort-zuruecksetzen/:token",
    data: {
      token: data.token,
      newPassword: data.newPassword,
    },
  })
    .then((res) => {
      dispatch(clearErrors());
      dispatch(returnErrors(res.data.message, res.status, "PASSWORD_CHANGED_SUCCESS"));
    })
    .catch((err) => {
      dispatch(clearErrors());
      dispatch(
        returnErrors(err.response.data.error, err.response.status, "PASSWORD_CHANGED_FAILED")
      );
    });
};

export const getContactsByFirstNameAndLastName = (firstName, lastName) => (dispatch, getState) => {
  return axios({
    method: "post",
    url: "/getContactsByFirstNameAndLastName",
    headers: tokenConfig(getState).headers,
    data: { firstName: firstName, lastName: lastName },
  })
    .then((res) => res.data)
    .catch((err) => err);
};

export const cleanPswdState = () => (dispatch) => {
  dispatch({
    type: CLEAN_PSWD_STATE,
  });
};

export const tokenConfig = (getState) => {
  const token = getState().userReducer.token;

  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
};
