import React, { Fragment, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import MyTextField from "../../vertragsUebersicht/VertragsContract/Contracts/ContractsElements/MyTextField";
import { resetPassword } from "../../../redux/index";

interface DataProps {
  newPassword: string;
  token: string | undefined;
}

interface Error {
  id: string;
  msg: string;
}

interface PasswortZuruecksetzenProps {
  error: Error;
  resetPassword: (data: DataProps) => void;
}

const SignupSchema = Yup.object().shape({
  newPassword: Yup.string().required("Dieses Feld ist erforderlich"),
});

export const PasswortZuruecksetzen = ({ error, resetPassword }: PasswortZuruecksetzenProps) => {
  const [state, setState] = useState<Error>({ id: "", msg: "" });

  useEffect(() => {
    setState(error);
  }, [error]);

  const { token } = useParams();

  const backgroundImage2 = require(`../../../assets/images/logo.png`);
  return (
    <div className="loginWrapper">
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="my-text-field">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
              }}
            ></div>
            {state?.id === "PASSWORD_CHANGED_FAILED" ? (
              <h4 className="alert-info">{state.msg}</h4>
            ) : null}
            {state?.id === "PASSWORD_CHANGED_SUCCESS" ? (
              <Fragment>
                <h4 className="alert-info3">{state.msg}</h4>
                <h4 className="alert-info3">Sie können sich mit diesen Daten anmelden.</h4>
              </Fragment>
            ) : null}
            <h4 style={{ color: "#3a8169", textAlign: "center" }}>
              Bitte geben Sie ein neues Passwort ein
            </h4>
          </div>
          <Formik
            initialValues={{
              newPassword: "",
              token: token,
            }}
            validationSchema={SignupSchema}
            onSubmit={(data) => {
              resetPassword(data);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  margin: "auto",
                }}
              >
                <MyTextField
                  placeholder="Neues Passwort"
                  name="newPassword"
                  type="password"
                  label="Neues Passwort"
                />
                <Button type="submit" variant="contained" className="button w-auto">
                  Passwort bestätigen
                </Button>
                {state.id === "PASSWORD_CHANGED_SUCCESS" ? (
                  <h4 style={{ textAlign: "center" }}>
                    <Link style={{ color: "#3a8169" }} to="/login">
                      Das Passwort wurde geändert. <br />
                      Einloggen
                    </Link>
                  </h4>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  error: state.errorReducer,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ resetPassword }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswortZuruecksetzen);
