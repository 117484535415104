import {
  LOAD_CONTACT,
  LOAD_STATE,
  START_LOADING_FORM_FIELD,
  SUCCESS_LOADED_FORM_FIELD,
  CLEAR_INTERVIEW_FIELDS_ARR,
  LOAD_CONTACT_RELATIONSHIP,
  CHANGE_CURRENT_CONTACT_DATA,
  SET_STEP_INVALID_ELEMENT,
  CLEAR_STEP_INVALID_ELEMENT,
  RESET_STEP,
  REMOVE_RELATION,
  MANAGE_DEALS,
  REMOVE_DEALS_BY_CONTACT_ID,
  SET_STATUS,
  REMOVE_DEAL_BY_DEAL_ID,
  UPDATE_RELATION_STATE_BY_ID,
  SET_SAVING_DATA_STATUS,
  DISABLE_REINITIALIZE_FORM,
  ADD_INCOMPLETE_CONTACT,
  REMOVE_INCOMPLETE_CONTACT,
  SET_CURRENT_PERSON,
  HouseholdInterviewStateProps,
  HouseholdInterviewActionTypes,
} from "./householdInterviewTypes";
import {
  faUserGroup,
  faChild,
  faPersonBreastfeeding,
  faPerson,
  faChildren,
  faPlug,
  faFireFlameSimple,
  faWifi,
  faMobileScreenButton,
  faServer,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { initialContactToInterview, initialSPAUserData } from "./mockData";

const initialState: HouseholdInterviewStateProps = {
  currentPerson: "me",
  contactsList: [],
  status: {
    allRelationsAreLoaded: false,
  },
  deals: {
    allDeals: [],
    stepTwoSectionIds: [39, 40, 41, 38, 196, 42],
    stepThreeSectionIds: [
      322, 599, 404, 405, 326, 328, 406, 576, 330, 409, 331, 411, 335, 337, 413, 338, 601, 414, 416,
      418, 340, 602, 603, 604, 605, 606, 607, 608, 341, 420, 609, 612, 613, 348, 349, 615, 616, 423,
      617, 426, 427, 620, 430,
    ],
    stepFourSectionIds: [324, 407, 408, 578, 412, 419, 610, 611, 619, 354, 357],
    stepFiveSectionIds: [325, 202, 614, 351, 352, 417],
    stepSixSectionIds: [403, 589, 329, 332, 593, 592, 415, 344, 591, 590, 353, 355, 323, 579, 399],
    stepSevenSectionIds: [401, 600, 193, 342],
    sortedDealsStepTwo: [],
    sortedDealsStepThree: [],
    sortedDealsStepFour: [],
    sortedDealsStepFive: [],
    sortedDealsStepSix: [],
    sortedDealsStepSeven: [],
  },
  initialStepStates: {
    stepTwo: [
      { id: 0, sectionId: 39, name: "Strom", icon: faPlug, radioCount: 2 },
      { id: 1, sectionId: 40, name: "Gas", icon: faFireFlameSimple, radioCount: 2 },
      { id: 2, sectionId: 41, name: "Dsl", icon: faWifi, radioCount: 2 },
      {
        id: 3,
        sectionId: 38,
        name: "Mobilfunk",
        icon: faMobileScreenButton,
        radioCount: 4,
      },
      { id: 4, sectionId: 196, name: "Mobile Daten", icon: faServer, radioCount: 2 },
      { id: 5, sectionId: 42, name: "TV", icon: faTv, radioCount: 2 },
    ],
  },
  relationsData: [
    {
      id: 1,
      name: "wife",
      dataId: "UF_CRM_1604482379",
      multiple: false,
      count: 0,
      icon: faUserGroup,
      familyMemberDE: "Ehefrau",
      group: 1,
    },
    {
      id: 2,
      name: "husband",
      dataId: "UF_CRM_1604482419",
      multiple: false,
      count: 0,
      icon: faUserGroup,
      familyMemberDE: "Ehemann",
      group: 1,
    },
    {
      id: 3,
      name: "life_partner",
      dataId: "UF_CRM_1604483955",
      multiple: false,
      count: 0,
      icon: faUserGroup,
      familyMemberDE: "Lebenspartner/-in",
      group: 1,
    },
    {
      id: 4,
      name: "kid",
      dataId: "UF_CRM_1604487523",
      multiple: true,
      count: 0,
      icon: faChild,
      familyMemberDE: "Kind",
      group: 2,
    },
    {
      id: 5,
      name: "mother",
      dataId: "UF_CRM_1604483871",
      multiple: false,
      count: 0,
      icon: faPersonBreastfeeding,
      familyMemberDE: "Mutter",
      group: 3,
    },
    {
      id: 6,
      name: "father",
      dataId: "UF_CRM_1604483912",
      multiple: false,
      count: 0,
      icon: faPerson,
      familyMemberDE: "Vater",
      group: 3,
    },
    {
      id: 7,
      name: "siblings",
      dataId: "UF_CRM_1605020723",
      multiple: true,
      count: 0,
      icon: faChildren,
      familyMemberDE: "Geschwister",
      group: 4,
    },
  ],
  mainContactData: {
    contactToInterview: initialContactToInterview,
    SPAUserData: initialSPAUserData,
  },
  currentContactData: {
    contactToInterview: initialContactToInterview,
    SPAUserData: initialSPAUserData,
  },
  contactRelationsData: [],
  incompleteContactRelationsData: { kidArray: [], marriedPartnerArray: [] },
  interviewFields: [],
  stepsValidation: {
    stepTwo: { valid: true, invalidFields: [] },
    stepThree: { valid: true, invalidFields: [] },
    stepFour: { valid: true, invalidFields: [] },
    stepFive: { valid: true, invalidFields: [] },
    stepSix: { valid: true, invalidFields: [] },
    stepSeven: { valid: true, invalidFields: [] },
  },
  resetState: {
    stepTwo: false,
    stepThree: false,
    stepFour: false,
    stepFive: false,
    stepSix: false,
    stepSeven: false,
  },
  savingDataStatus: false,
  enableReinitializeForm: false,
};

let newIncompleteContactRelationsData, contactListElementExistsAlready;

const reducer = (state = initialState, action: HouseholdInterviewActionTypes) => {
  switch (action.type) {
    case SET_CURRENT_PERSON:
      return {
        ...state,
        currentPerson: action.payload,
      };
    case REMOVE_INCOMPLETE_CONTACT:
      newIncompleteContactRelationsData =
        state.incompleteContactRelationsData[`${action.payload.data.name.split("-")[0] + "Array"}`];
      newIncompleteContactRelationsData.pop();

      return {
        ...state,
        incompleteContactRelationsData: {
          ...state.incompleteContactRelationsData,
          [action.payload.data.name.split("-")[0] + "Array"]: newIncompleteContactRelationsData,
        },
      };
    case ADD_INCOMPLETE_CONTACT:
      newIncompleteContactRelationsData = [
        ...state.incompleteContactRelationsData[action.payload.name.split("-")[0] + "Array"],
        action.payload,
      ];

      return {
        ...state,
        incompleteContactRelationsData: {
          ...state.incompleteContactRelationsData,
          [action.payload.name.split("-")[0] + "Array"]: newIncompleteContactRelationsData,
        },
      };
    case SET_SAVING_DATA_STATUS:
      return {
        ...state,
        savingDataStatus: action.payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.statusName]: action.payload.statusValue,
        },
      };
    case REMOVE_DEAL_BY_DEAL_ID:
      const updatedDealsByDealId = state.deals.allDeals.filter(
        (deal) => deal.ID !== action.payload
      );

      return {
        ...state,
        deals: {
          ...state.deals,
          allDeals: updatedDealsByDealId,
        },
      };
    case REMOVE_DEALS_BY_CONTACT_ID:
      const updatedDealsByContactId = state.deals.allDeals.filter(
        (deal) => deal.CONTACT_ID !== action.payload
      );

      return {
        ...state,
        deals: {
          ...state.deals,
          allDeals: updatedDealsByContactId,
        },
      };
    case MANAGE_DEALS:
      const dealAlreadyExists = state.deals.allDeals.findIndex(
        (deal) => deal.ID === action.payload.ID
      );

      const newSortedDealsStepTwo = [];
      const newSortedDealsStepThree = [];
      const newSortedDealsStepFour = [];
      const newSortedDealsStepFive = [];
      const newSortedDealsStepSix = [];
      const newSortedDealsStepSeven = [];

      if (-1 === dealAlreadyExists && 89 === parseInt(action.payload.UF_CRM_1597417346297)) {
        if (state.deals.stepTwoSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))) {
          action.payload.step_two = true;
          newSortedDealsStepTwo.push(action.payload);
        } else if (
          state.deals.stepThreeSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))
        ) {
          action.payload.step_three = true;
          newSortedDealsStepThree.push(action.payload);
        } else if (
          state.deals.stepFourSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))
        ) {
          action.payload.step_four = true;
          newSortedDealsStepFour.push(action.payload);
        } else if (
          state.deals.stepFiveSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))
        ) {
          action.payload.step_five = true;
          newSortedDealsStepFive.push(action.payload);
        } else if (
          state.deals.stepSixSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))
        ) {
          action.payload.step_six = true;
          newSortedDealsStepSix.push(action.payload);
        } else if (
          state.deals.stepSevenSectionIds.includes(parseInt(action.payload.UF_CRM_5F365448B9021))
        ) {
          action.payload.step_seven = true;
          newSortedDealsStepSeven.push(action.payload);
        }
      }

      return {
        ...state,
        deals: {
          ...state.deals,
          allDeals:
            -1 === dealAlreadyExists
              ? [...state.deals.allDeals, action.payload]
              : state.deals.allDeals,
          sortedDealsStepTwo: [...state.deals.sortedDealsStepTwo, ...newSortedDealsStepTwo],
          sortedDealsStepThree: [...state.deals.sortedDealsStepThree, ...newSortedDealsStepThree],
          sortedDealsStepFour: [...state.deals.sortedDealsStepFour, ...newSortedDealsStepFour],
          sortedDealsStepFive: [...state.deals.sortedDealsStepFive, ...newSortedDealsStepFive],
          sortedDealsStepSix: [...state.deals.sortedDealsStepSix, ...newSortedDealsStepSix],
          sortedDealsStepSeven: [...state.deals.sortedDealsStepSeven, ...newSortedDealsStepSeven],
        },
      };
    case RESET_STEP:
      return {
        ...state,
        resetState: {
          ...state.resetState,
          [action.payload.step]: !state.resetState[action.payload.step],
        },
      };
    case CLEAR_STEP_INVALID_ELEMENT:
      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [action.payload.step]: {
            valid: true,
            invalidFields: [],
          },
        },
      };
    case SET_STEP_INVALID_ELEMENT:
      return {
        ...state,
        stepsValidation: {
          ...state.stepsValidation,
          [action.payload.step]: {
            valid: false,
            invalidFields: [
              ...state.stepsValidation[action.payload.step].invalidFields,
              action.payload.data,
            ],
          },
        },
      };
    case CLEAR_INTERVIEW_FIELDS_ARR:
      return initialState;

    case SUCCESS_LOADED_FORM_FIELD:
      return {
        ...state,
        interviewFields: state.interviewFields.map((el) => {
          var returnValue = { ...el };

          if (el.fieldName === action.payload.title) {
            returnValue.loading = false;
            returnValue.data = action.payload.data;
          }

          return returnValue;
        }),
      };
    case START_LOADING_FORM_FIELD:
      return {
        ...state,
        interviewFields: [...state.interviewFields, { fieldName: action.payload, loading: true }],
      };
    case LOAD_CONTACT:
      contactListElementExistsAlready = state.contactsList.filter(
        (contact) => contact.contactId === action.payload.ID
      );

      return {
        ...state,
        currentContactData: { ...state.currentContactData, contactToInterview: action.payload },
        mainContactData: { ...state.mainContactData, contactToInterview: action.payload },
        contactsList: contactListElementExistsAlready.length
          ? state.contactsList
          : [
              ...state.contactsList,
              {
                contactId: action.payload.ID,
                contactName: action.payload.NAME,
                contactLastName: action.payload.LAST_NAME,
              },
            ],
      };
    case LOAD_STATE:
      return {
        ...state,
        currentContactData: { ...state.currentContactData, SPAUserData: action.payload },
        mainContactData: { ...state.mainContactData, SPAUserData: action.payload },
      };
    case UPDATE_RELATION_STATE_BY_ID:
      const updatedContactRelationsData = state.contactRelationsData.map((relation) => {
        if (parseInt(action.payload.relationId) === parseInt(relation.data.contactToInterview.ID)) {
          relation.data.SPAUserData = action.payload.relationState;
        }

        return relation;
      });

      return {
        ...state,
        currentContactData: {
          ...state.currentContactData,
          SPAUserData: action.payload.updateCurrentContact
            ? action.payload.relationState
            : state.currentContactData.SPAUserData,
        },
        contactRelationsData: updatedContactRelationsData,
      };
    case LOAD_CONTACT_RELATIONSHIP:
      const newContactRelationsData = [...state.contactRelationsData];
      const index = state.contactRelationsData.findIndex(
        (contact) => action.payload.relationName === contact.name
      );

      const newObj = {
        id: action.payload.id,
        dataId: action.payload.dataId,
        group: action.payload.group,
        familyMemberDE: action.payload.familyMemberDE,
        name: action.payload.relationName,
        data: action.payload.relationObj,
      };

      if (-1 === index) {
        newContactRelationsData.push(newObj);
      } else {
        newContactRelationsData[index] = newObj;
      }

      newContactRelationsData.sort((a, b) => a.id - b.id);

      contactListElementExistsAlready = state.contactsList.filter(
        (contact) => contact.contactId === newObj.data.contactToInterview.ID
      );

      return {
        ...state,
        contactRelationsData: newContactRelationsData,
        contactsList: contactListElementExistsAlready.length
          ? state.contactsList
          : [
              ...state.contactsList,
              {
                name: newObj.name,
                contactId: newObj.data.contactToInterview.ID,
                contactName: newObj.data.contactToInterview.NAME,
                contactLastName: newObj.data.contactToInterview.LAST_NAME,
              },
            ],
      };
    case CHANGE_CURRENT_CONTACT_DATA:
      return {
        ...state,
        currentContactData: {
          contactToInterview: action.payload.contactToInterview,
          SPAUserData: action.payload.SPAUserData,
        },
        enableReinitializeForm: true,
      };
    case DISABLE_REINITIALIZE_FORM:
      return {
        ...state,
        enableReinitializeForm: action.payload,
      };
    case REMOVE_RELATION:
      return {
        ...state,
        contactRelationsData: state.contactRelationsData.filter(
          (relation) => action.payload.relationName !== relation.name
        ),
        contactsList: state.contactsList.filter(
          (contact) => action.payload.relationName !== contact.name
        ),
      };
    default:
      return state;
  }
};

export default reducer;
