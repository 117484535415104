import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MyTextField from "../../vertragsUebersicht/VertragsContract/Contracts/ContractsElements/MyTextField";
import { login } from "../../../redux/index";
import { ErrorStateProps } from "../../../redux/errors/errorTypes";

const SignupSchema = Yup.object().shape({
  email: Yup.string().required("Dieses Feld ist erforderlich"),
  password: Yup.string().required("Dieses Feld ist erforderlich"),
});

interface Data {
  email: string;
  password: string;
}

interface Props {
  login: (data: Data) => void;
  errorReducer: ErrorStateProps;
  redirectToPath: string;
  isAuthenticated: boolean | null;
  token: string;
}

const Formular: React.FC<Props> = ({
  login,
  errorReducer,
  redirectToPath,
  isAuthenticated,
  token,
}) => {
  const backgroundImage2 = require(`../../../assets/images/logo.png`);

  return isAuthenticated && redirectToPath ? (
    <Navigate to={redirectToPath} />
  ) : token ? (
    <Navigate to="/vertragsUebersicht" />
  ) : (
    <div className="loginWrapper">
      <div className="drawTopLeftLine"></div>
      <div className="drawTopRightLine"></div>
      <div className="drawDownLeftLine"></div>
      <div className="drawDownRightLine"></div>
      <div className="drawMiddleLeftLine"></div>
      <div className="drawMiddleRightLine"></div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="my-text-field">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
              }}
            ></div>
            {errorReducer.id === "LOGIN_FAIL" ? (
              <h4 className="alert-info">{errorReducer.msg}</h4>
            ) : null}
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(data) => {
              login(data);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "300px",
                  margin: "auto",
                }}
              >
                <MyTextField placeholder="Email" name="email" type="text" label="Email" />
                <MyTextField
                  placeholder="Passwort"
                  name="password"
                  type="password"
                  label="Passwort"
                />
                <Button type="submit" variant="contained" className="button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Link to="/register">
                  <p className="text register">kostenlos anmelden</p>
                </Link>
                <Link to="/forgotPassword">
                  <p className="text">Passwort vergessen?</p>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  token: state.userReducer.token,
  isAuthenticated: state.userReducer.isAuthenticated,
  errorReducer: state.errorReducer,
  redirectToPath: state.appSettingsReducer.redirectToPath,
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ login }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Formular);
