import axios from "axios";
import { showProcessSpinner, tokenConfig } from "../index";
import { IRootState, TypedDispatch } from "../store";
import {
  GET_SECTIONS,
  SECTIONS_LOADING,
  GET_COMPANIES,
  COMPANIES_LOADING,
  ADD_PRODUCT_SECTION_LIST,
  DealsArray,
  SectionListElement,
} from "./vertragsArrayTypes";

export const sendGesellschaft =
  (companyName: string, sparteId: string) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(showProcessSpinner(true));

    let companyId = "";
    await axios
      .post(`/addCompany`, { companyName: companyName, sparteId: sparteId }, tokenConfig(getState))
      .then((res) => {
        companyId = res.data.result;
        if (companyId) {
          // eslint-disable-next-line
          getState().vertragsListReducer.vertragsList.map((el) => {
            if (el.productSectionId === parseInt(sparteId)) {
              el.vertragsList.push({ id: companyId, title: companyName });
              el.vertragsList2.push({ id: companyId, title: companyName });
            }
          });
          axios({
            method: "post",
            url: `/vertragsUebersicht/sendEmailNewCompany`,
            headers: tokenConfig(getState).headers,
            data: {
              userData: getState().userReducer.user,
              companyName: companyName,
              companyId: companyId,
            },
          })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        companyId = "";
      });

    dispatch(showProcessSpinner(false));
    return companyId;
  };

export const getVertragsArray = () => (dispatch: TypedDispatch, getState: () => IRootState) => {
  dispatch(vertragsArrayLoading());
  let vertragsArray: Array<DealsArray> = [];
  axios({
    method: "get",
    url: `/getSection`,
    headers: tokenConfig(getState).headers,
  })
    .then((res) => {
      if (res.status === 200) {
        let i = 0;
        // eslint-disable-next-line
        res.data.result.LIST.map((el: SectionListElement) => {
          if (el.VALUE !== "BITRIX") {
            vertragsArray.push({
              id: i,
              sparteId: parseInt(el.ID),
              text: el.VALUE,
              selectId: el.VALUE.toLowerCase() + "-select",
              isOpen: false,
              vertragsList: [],
              vertragsList2: [],
            });
            i++;
          }
        });
        dispatch({ type: GET_SECTIONS, payload: vertragsArray });
      }
    })
    .catch((err) => console.log(err));
};

export const getGesellschaften = () => (dispatch: TypedDispatch, getState: () => IRootState) => {
  dispatch(vertragsGesellschaftenLoading());

  axios({
    method: "get",
    url: `/getProductSection`,
    headers: tokenConfig(getState).headers,
  })
    .then((res) => {
      dispatch({ type: ADD_PRODUCT_SECTION_LIST, payload: res.data });
    })
    .catch((err) => console.log(err));

  axios({
    method: "get",
    url: `/getGesellschaften`,
    headers: tokenConfig(getState).headers,
  })
    .then((res) => {
      dispatch({ type: GET_COMPANIES, payload: res.data.gesellschaften });
    })
    .catch((err) => console.log(err));
};

export const vertragsArrayLoading = () => {
  return {
    type: SECTIONS_LOADING,
  };
};

export const vertragsGesellschaftenLoading = () => {
  return {
    type: COMPANIES_LOADING,
  };
};

export const addProductionSectionIdToVertragsList =
  () => (dispatch: TypedDispatch, getState: () => IRootState) => {
    let vertragsList = getState().vertragsListReducer.vertragsList;
    let productSectionList = getState().vertragsListReducer.productSectionList;

    for (let i = 0; i < vertragsList.length; i++) {
      for (let j = 0; j < productSectionList.length; j++) {
        if (vertragsList[i].text === productSectionList[j].NAME) {
          vertragsList[i]["productSectionId"] = parseInt(productSectionList[j].ID);
        }
      }
    }
  };
