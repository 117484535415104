export const AUTH_ERROR = "AUTH_ERROR";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export interface ErrorStateProps {
  msg: string;
  status: string | null | number;
  id: string | null;
}

interface AuthError {
  type: typeof AUTH_ERROR;
}

interface GetErrors {
  type: typeof GET_ERRORS;
  payload: { msg: string; status: string; id: string | null };
}

interface ClearErrors {
  type: typeof CLEAR_ERRORS;
}

export type ErrorsActionTypes = AuthError | GetErrors | ClearErrors;
