import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledBackdrop } from "../StyledMUIComponentsHelper/StyledComponents";

export const ProcessLoadingSpinner = () => {
  return (
    <StyledBackdrop open={true}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
};
