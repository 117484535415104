import {
  GET_ALL_GESELLSCHAFTEN,
  ALL_COMPANIES_LOADING,
  GET_GESELLSCHAFT_BY_ID,
  CLEAR_GESELLSCHAFT_BY_ID,
  ADD_COMPANY_LOGO_DATA,
} from "./gesellschaftenTypes";

const initialState = {
  companiesLogos: [],
  gesellschaftById: {},
  gesellschaftenList: [],
  loading: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPANY_LOGO_DATA:
      return {
        ...state,
        companiesLogos: [...state.companiesLogos, action.payload],
      };
    case ALL_COMPANIES_LOADING:
      return {
        ...state,
        loading: state.loading ? false : true,
      };
    case GET_ALL_GESELLSCHAFTEN:
      return {
        ...state,
        gesellschaftenList: action.payload,
      };
    case GET_GESELLSCHAFT_BY_ID:
      return {
        ...state,
        gesellschaftById: action.payload,
        loading: false,
      };
    case CLEAR_GESELLSCHAFT_BY_ID:
      return {
        ...state,
        gesellschaftById: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
