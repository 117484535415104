import { SET_USER_DISK_DATA } from "./filesTypes";

const initialState = {
  userDiskData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DISK_DATA:
      return {
        ...state,
        userDiskData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
