import {
  ADD_FULLVERTRAG,
  SHOW_VALUE_ROUTE,
  CHANGE_LOADING_STATUS_ADD_EDIT_FORM,
  CLEAR_FULLVERTRAGBYID,
  ADD_TERMINATED_DEAL,
  CHANGE_LOADING_STATUS,
  ADD_FILE_PATH_ARRAY,
  CHANGE_STATUS_FULLVERTRAG,
  GET_FULLVERTRAGBYID,
  GET_FULLVERTRAG,
  GET_ACTIVE_KEY_DEAL,
  GET_NOT_ACTIVE_KEY_DEAL,
  REMOVE_NOT_ACTIVE_KEY_DEAL,
  REMOVE_ACTIVE_KEY_DEAL,
  UPDATE_DEAL_BY_ID,
  ALLOW_TO_SHOW_KEY_DEAL,
  REMOVE_DEAL_BY_ID,
} from "./fullVertragTypes";

const initialState = {
  fullVertragById: {},
  fullVertrags: [],
  dealTerminated: [],
  fullVertragsPhotoPaths: [],
  keyDeals: { active: [], notActive: [] },
  showValueRoute: false,
  showKeyDealsRoute: false,
  loading: null,
  addEditLoading: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEAL_BY_ID:
      return {
        ...state,
        fullVertrags: state.fullVertrags.map((contract) => {
          if (contract.ID === action.payload.contractId) {
            return { ...contract, ...action.payload.dataToUpdate };
          }

          return contract;
        }),
      };
    case SHOW_VALUE_ROUTE:
      return {
        ...state,
        showValueRoute:
          state.fullVertrags.length &&
          state.fullVertrags.filter(
            (el) =>
              parseInt(el.UF_CRM_1597411685) === 3339 || parseInt(el.UF_CRM_1597411685) === 3260
          ),
      };
    case ALLOW_TO_SHOW_KEY_DEAL:
      return {
        ...state,
        showKeyDealsRoute: action.payload,
      };
    case ADD_FULLVERTRAG:
      return {
        ...state,
        fullVertrags: [...state.fullVertrags, action.payload],
      };
    case REMOVE_NOT_ACTIVE_KEY_DEAL:
      return {
        ...state,
        keyDeals: {
          ...state.keyDeals,
          notActive: state.keyDeals.notActive.filter((el) => el.ID !== action.payload),
        },
      };
    case REMOVE_ACTIVE_KEY_DEAL:
      return {
        ...state,
        keyDeals: {
          ...state.keyDeals,
          active: state.keyDeals.active.filter((el) => el.ID !== action.payload),
        },
      };
    case GET_ACTIVE_KEY_DEAL:
      return {
        ...state,
        keyDeals: { ...state.keyDeals, active: [...state.keyDeals.active, action.payload] },
      };
    case GET_NOT_ACTIVE_KEY_DEAL:
      return {
        ...state,
        keyDeals: { ...state.keyDeals, notActive: [...state.keyDeals.notActive, action.payload] },
      };
    case GET_FULLVERTRAG:
      return {
        ...state,
        fullVertrags: [...state.fullVertrags, action.payload],
      };
    case ADD_TERMINATED_DEAL:
      return {
        ...state,
        dealTerminated: [...state.dealTerminated, action.payload],
      };
    case ADD_FILE_PATH_ARRAY:
      return {
        ...state,
        fullVertragsPhotoPaths: [...state.fullVertragsPhotoPaths, action.payload],
      };
    case GET_FULLVERTRAGBYID:
      return {
        ...state,
        fullVertragById: action.payload,
      };
    case CLEAR_FULLVERTRAGBYID:
      return {
        ...state,
        fullVertragById: {},
      };
    case CHANGE_STATUS_FULLVERTRAG:
      return {
        ...state,
        fullVertrags: action.payload,
      };
    case REMOVE_DEAL_BY_ID:
      return {
        ...state,
        fullVertrags: state.fullVertrags.filter(
          (fullVertrag) => parseInt(fullVertrag.ID) !== parseInt(action.payload)
        ),
      };
    case CHANGE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_LOADING_STATUS_ADD_EDIT_FORM:
      return {
        ...state,
        addEditLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
