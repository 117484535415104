export const bitrixRequestHelper = (total: number, url: string) => {
  let howManyRequest = Math.ceil(total / 50);

  let reqArray = [];
  let newReqVal = 0;
  for (let i = 1; i < howManyRequest; i++) {
    newReqVal = i * 50;
    reqArray.push(newReqVal !== 0 ? `${url}?start=${newReqVal}` : url);
  }

  return reqArray;
};
