import axios from "axios";
import { dbNameHelper } from "../../components/Helper/BitrixHelper/bitrixDatabaseHelper";
import { bitrixRequestHelper } from "../../components/Helper/BitrixHelper/bitrixRequestHelper";
import { translatePaymentNames } from "../../components/Helper/BitrixHelper/translatePaymentNames";
import { showProcessAlert, showProcessSpinner } from "../appSettings/appSettingsActions";
import { tokenConfig } from "../index";
import {
  CLEAR_FULLVERTRAGBYID,
  SHOW_VALUE_ROUTE,
  GET_FULLVERTRAGBYID,
  ADD_FILE_PATH_ARRAY,
  CHANGE_LOADING_STATUS,
  CHANGE_LOADING_STATUS_ADD_EDIT_FORM,
  GET_ACTIVE_KEY_DEAL,
  REMOVE_NOT_ACTIVE_KEY_DEAL,
  REMOVE_ACTIVE_KEY_DEAL,
  REMOVE_DEAL_BY_ID,
  UPDATE_DEAL_BY_ID,
} from "./fullVertragTypes";
import {
  ADD_COMPANY_LOGO_DATA,
  GET_GESELLSCHAFT_BY_ID,
} from "../gesellschaften/gesellschaftenTypes";
import { HANDLE_PROCESS_SPINNER } from "../appSettings/appSettingsTypes";
import { updateUserData } from "../users/userActions";
import { updateCompanyAddress } from "../gesellschaften/gesellschaftenActions";
import { convertBitrixAmount } from "../../components/Helper/MoneyHelper/ConvertBitrixAmount";
import { deleteInterviewDealByDealId } from "../householdInterview/householdInterviewActions";
import { imageFileResizer } from "../../components/Helper/FileHelper/imageFileResizer";

export const getFullVertrag =
  (userId, data = null, numbersOfContracts = null) =>
  (dispatch, getState) => {
    dispatch(changeLoadingStatus(true));

    if (null !== userId) {
      getState().fullVertragReducer.fullVertrags = [];
      getState().fullVertragReducer.dealTerminated = [];
    }

    const getContractsReq = async (data, numbersOfContracts) => {
      if (numbersOfContracts === 0 && data.length === 0) {
        dispatch(changeLoadingStatus(false));
      }

      await axios({
        method: "post",
        url: "/getDeals",
        headers: tokenConfig(getState).headers,
        data: { data: data },
      })
        .then((res) => {
          for (const [actionType, data] of Object.entries(res.data)) {
            Array.isArray(data)
              ? data.forEach((element) => {
                  dispatch({ type: actionType, payload: element });
                })
              : dispatch({ type: actionType, payload: data });
          }
        })
        .catch((err) => console.log(err));

      dispatch({ type: SHOW_VALUE_ROUTE });
      dispatch(changeLoadingStatus(false));
    };

    if (null !== userId) {
      axios({
        method: "post",
        url: `/getDealList`,
        headers: tokenConfig(getState).headers,
        data: { userId: userId },
      })
        .then(async (res) => {
          let completeResults = res.data.result;
          const numbersOfContracts = res.data.total;
          if (res.status === 200) {
            if (res.data.total > 50) {
              await Promise.all(
                bitrixRequestHelper(res.data.total, `/getDealList`).map((el) =>
                  axios.post(el, { userId: userId }, { headers: tokenConfig(getState).headers })
                )
              )
                .then(
                  axios.spread((...allResponseData) => {
                    allResponseData.forEach((responseData) => {
                      completeResults = completeResults.concat(responseData.data.result);
                    });
                  })
                )
                .catch((err) => console.log(err));
            }
          }

          getContractsReq(completeResults, numbersOfContracts);
        })
        .catch((err) => console.log(err));
    } else {
      getContractsReq(data, numbersOfContracts);
    }
  };

export const getFilePath = (data) => (dispatch, getState) => {
  const { fullVertragReducer, gesellschaftenReducer } = getState();
  let fileNotExists = true;
  let type = ADD_FILE_PATH_ARRAY;
  let valueToReturn = [];

  const filterMediaResource = (mediaResources) => {
    if (mediaResources.length) {
      const filteredMediaResources = mediaResources.filter(
        (mediaResource) => mediaResource.filesId === data.filesId
      );
      fileNotExists = !Boolean(filteredMediaResources.length);

      if (!fileNotExists && data?.shouldReturn) valueToReturn = filteredMediaResources;
    }
  };

  switch (data?.typeFile) {
    case "companyLogo":
      filterMediaResource(gesellschaftenReducer.companiesLogos);
      type = ADD_COMPANY_LOGO_DATA;
      break;
    default:
      filterMediaResource(fullVertragReducer.fullVertragsPhotoPaths);
  }

  if (valueToReturn.length) return valueToReturn;

  if (fileNotExists) {
    return axios({
      method: "post",
      url: "/vertragsUebersicht/getFilesPath",
      headers: tokenConfig(getState).headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: type,
          payload: {
            filesId: res.data.filesId,
            contractId: res.data.contractId,
            filename: res.data.results[0].filename,
            subDirLogo: res.data.results[0].subDirLogo,
          },
        });

        if (data?.shouldReturn) return res?.data?.results;
      })
      .catch((err) => err);
  }
};

const sendRequestEdit =
  (fullVertrag, contractToEdit, addedFilesText, data) => async (dispatch, getState) => {
    let dataChanged = "";

    dataChanged += "Vertrag ID: " + fullVertrag.contractId + "\n";
    for (let [key, value] of Object.entries(contractToEdit[0])) {
      if (fullVertrag[key] && dbNameHelper(key, value) !== undefined) {
        let checkKey = fullVertrag[dbNameHelper(key)].toString();
        let checkValue = null !== value ? value.toString() : "";
        if (dbNameHelper(key) === "contractBeginning" || dbNameHelper(key) === "endOfContract") {
          checkValue = value.substring(0, 10);
          checkKey = fullVertrag[dbNameHelper(key)].substring(0, 10);
        } else if (dbNameHelper(key) === "zahlweise") {
          checkValue = translatePaymentNames(value);
          checkKey = translatePaymentNames(fullVertrag[dbNameHelper(key)]);
        } else if (dbNameHelper(key) === "kosten") {
          checkValue = convertBitrixAmount(checkValue);
        }

        if (checkKey !== checkValue) {
          dataChanged += dbNameHelper(key) + " war: " + checkValue + " Ist: " + checkKey + "\n";
        }
      }
    }

    dataChanged += addedFilesText;

    const fileToDeleteArr = fullVertrag.filesToDelete.replace(/['"]/g, "").slice(1, -1).split(",");

    if ("" !== fileToDeleteArr[0]) {
      dataChanged += "\nDie Dokumente wurden gelöscht: \n";
      fileToDeleteArr.forEach((el) => {
        getState().fullVertragReducer.fullVertragsPhotoPaths.forEach((element) => {
          if (parseInt(el) === parseInt(element.filesId)) {
            dataChanged += "- " + element.filename + "\n";
          }
        });
      });
    }

    await axios({
      method: "post",
      url: `/updateDeal`,
      headers: tokenConfig(getState).headers,
      data: { urlString: data.toString() },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
      .then((res) => {
        if (res.data.result) {
          axios({
            method: "post",
            url: `/vertragsUebersicht/sendEmailEditContract`,
            headers: tokenConfig(getState).headers,
            data: {
              contractToEdit: dataChanged,
              userData: getState().userReducer.user,
              companyName: fullVertrag.anbieter,
            },
          })
            .then((res) => {
              getState().fullVertragReducer.fullVertragsPhotoPaths = [];
              dispatch(changeLoadingStatusAddEditForm(false));
              dispatch(changeLoadingStatusAddEditForm(null));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => console.log(err));

    dispatch(showProcessSpinner(false));
  };

const sendRequestAdd = (fullVertrag, addedFilesText, data) => async (dispatch, getState) => {
  await axios({
    method: "post",
    url: `/addDeal`,
    headers: tokenConfig(getState).headers,
    data: { urlString: data.toString() },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  })
    .then((res) => {
      axios({
        method: "post",
        url: `/vertragsUebersicht/sendEmailAddContract`,
        headers: tokenConfig(getState).headers,
        data: {
          vertragId: res.data.result,
          user: getState().userReducer.user,
          contract: fullVertrag,
          addedFilesText: addedFilesText,
        },
      })
        .then((res) => dispatch(changeLoadingStatusAddEditForm(false)))
        .catch((err) => console.log(err));
      dispatch(changeLoadingStatus(true));
      dispatch(getFullVertrag(null, [{ ID: res.data.result }], 1));
    })
    .catch((err) => {
      console.log(err);
    });

  dispatch(showProcessSpinner(false));
};

export const addFullVertrag = (fullVertrag) => async (dispatch, getState) => {
  dispatch(showProcessSpinner(true));
  dispatch(changeLoadingStatusAddEditForm(true));
  let data = new URLSearchParams();
  let contractToEdit = [];
  let filesToDelete = [];
  if (fullVertrag.createOrEdit === "edit") {
    data.append(`id`, `${fullVertrag.contractId}`);
    contractToEdit = getState().fullVertragReducer.fullVertrags.filter(
      (el) => el.ID === fullVertrag.contractId
    );

    fullVertrag.file = fullVertrag.file.filter((el) => el[1] instanceof File);
    if (fullVertrag.file.length !== 0) {
      let newFilesArr = [];
      // eslint-disable-next-line
      fullVertrag.file.map((el) => {
        newFilesArr.push(el[1]);
      });
      fullVertrag.file = newFilesArr;
    }
  }

  for (const [key, value] of Object.entries(fullVertrag)) {
    if (dbNameHelper(key, value) !== undefined) {
      data.append(`fields[${dbNameHelper(key)}]`, `${value}`);
    }
  }

  let addedFilesText = "";

  if (fullVertrag.file && fullVertrag.file.length) {
    addedFilesText += "\nDie Dokumente wurden hinzugefügt: \n";
    fullVertrag.file.forEach((el) => {
      addedFilesText += "- " + el.name + "\n";
    });
  }

  const invokeRequest = () => {
    if (fullVertrag.createOrEdit === "edit") {
      dispatch(sendRequestEdit(fullVertrag, contractToEdit, addedFilesText, data));
    } else {
      data.append("fields[TYPE_ID]", "COMPLEX");
      data.append("fields[CATEGORY_ID]", "1");
      dispatch(sendRequestAdd(fullVertrag, addedFilesText, data));
    }
  };

  const adddFiles = (name, encodedFile, index) => {
    data.append(`fields[UF_CRM_1599146921][${index}][fileData][0]`, `${name}`);
    data.append(`fields[UF_CRM_1599146921][${index}][fileData][1]`, `${encodedFile.split(",")[1]}`);
    return true;
  };

  if (fullVertrag.filesToDelete !== undefined) {
    filesToDelete = JSON.parse(fullVertrag.filesToDelete);
  }

  if (fullVertrag.file && (fullVertrag.file.length || filesToDelete.length)) {
    let dataFromBitrix = [];
    let index = 0;
    let existingsFiles = getState().fullVertragReducer.fullVertragsPhotoPaths.filter(
      (el) =>
        el.contractId === fullVertrag.contractId && !filesToDelete.includes(el.filesId.toString())
    );

    if (existingsFiles.length !== 0) {
      dataFromBitrix = await axios({
        method: "post",
        url: "/vertragsUebersicht/getFilesDataFromBitrix",
        headers: tokenConfig(getState).headers,
        data: existingsFiles,
      });
      index = dataFromBitrix.data.filesArr.length;
      // eslint-disable-next-line
      dataFromBitrix.data.filesArr.map((el, i) => {
        adddFiles(el.name, el.encodedFile, i);
      });
    }

    const handleRequest = (i) => {
      if (fullVertrag.file.length === i + 1) {
        invokeRequest();
      }
    };

    const upload = () => {
      fullVertrag.file.forEach(async (el, i) => {
        let file;
        const resizeFile = async () => {
          const resizedFile = await imageFileResizer(fullVertrag.file[i]);
          file = resizedFile;
        };

        if (fullVertrag.file[i].size > 1200000) {
          file = await resizeFile();
        }

        if (file) {
          adddFiles(fullVertrag.file[i].name, file, i + index);
          handleRequest();
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            adddFiles(fullVertrag.file[i].name, reader.result, i + index);
            handleRequest(i);
          };
          reader.onerror = () => console.log("there are some problems");
          reader.readAsDataURL(fullVertrag.file[i]);
        }
      });
    };

    upload();

    if (existingsFiles.length === 0 && fullVertrag.file.length === 0) {
      data.append(`fields[UF_CRM_1599146921]`, `[]`);
    }

    if (fullVertrag.file.length === 0) {
      invokeRequest();
    }
  } else {
    invokeRequest();
  }
};

export const deleteDealById =
  (dealData, interviewDeal = false) =>
  async (dispatch, getState) => {
    dispatch(showProcessSpinner(true));

    if (+dealData.contactId === +getState().userReducer.id) {
      dispatch({ type: REMOVE_DEAL_BY_ID, payload: dealData.dealId });
    }
    if (interviewDeal) {
      dispatch(deleteInterviewDealByDealId(dealData.dealId));
    }

    await axios({
      method: "post",
      url: "/deleteDealById",
      headers: tokenConfig(getState).headers,
      data: { dealId: dealData.dealId },
    })
      .then((res) => {
        dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
        axios.post(
          `/sendInfoEmail`,
          {
            subject: "Vertrag wurde gelöscht",
            text: `Ein Vertrag von ${
              dealData?.contactName + " " + dealData?.contactLastName
            } wurde gerade gelöscht. \nKunde ID: ${dealData?.contactId}\nVertrag ID: ${
              dealData?.dealId
            }`,
          },
          tokenConfig(getState)
        );
      })
      .catch((e) => dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen")));

    dispatch(showProcessSpinner(false));
  };

export const getFullVertragById = (id) => (dispatch, getState) => {
  // eslint-disable-next-line
  getState().fullVertragReducer.fullVertrags.map((el) => {
    if (el.ID === id) {
      dispatch({
        type: GET_FULLVERTRAGBYID,
        payload: el,
      });
      if (el.UF_CRM_1597406248.length !== 0) {
        axios
          .post(`/getCompanyById`, { companyId: el.UF_CRM_1597406248 }, tokenConfig(getState))
          .then((res) => {
            dispatch({
              type: GET_GESELLSCHAFT_BY_ID,
              payload: res.data.result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  });
};

export const terminateContract = (values, imageURL) => (dispatch, getState) => {
  dispatch(updateUserData(values));
  dispatch(updateCompanyAddress(values));
  let currentContract = getState().fullVertragReducer.fullVertrags.filter(
    (el) => el.ID === values.contractId
  );

  let dataChanged = "";
  if (values.contractNumber !== currentContract[0].UF_CRM_1597414634517) {
    dataChanged = `Vertragsnnummer war: ${currentContract[0].UF_CRM_1597414634517} Ist: ${values.contractNumber} \n`;
  }
  if (values.contractEndDate !== currentContract[0].UF_CRM_1597405215572) {
    dataChanged = `Laufzeitende war: ${currentContract[0].UF_CRM_1597405215572} Ist: ${values.contractEndDate} \n`;
  }
  dataChanged += "Vertrag id: " + currentContract[0].ID + " wurde gekündigt.";

  axios({
    method: "post",
    url: `/terminateContract`,
    headers: tokenConfig(getState).headers,
    data: {
      contractId: values.contractId,
      contractNumber: values.contractNumber,
      contractEndDate: values.contractEndDate,
    },
  })
    .then((res) => {
      if (res.data.result) {
        axios({
          method: "post",
          url: "/Kuendigung/:id",
          headers: tokenConfig(getState).headers,
          data: {
            values,
            imageURL: imageURL,
            contractToEdit: dataChanged,
          },
        })
          .then((res) => {
            dispatch({
              type: REMOVE_DEAL_BY_ID,
              payload: currentContract[0].ID,
            });
          })
          .catch((err) => console.log(err));
      }
    })
    .catch((err) => console.log(err));
};

export const getDealById =
  (dealId, firstableRemoveFromStore = false) =>
  async (dispatch, getState) => {
    if (firstableRemoveFromStore) {
      dispatch({ type: REMOVE_DEAL_BY_ID, payload: dealId });
    }

    dispatch(getFullVertrag(null, [{ ID: dealId }], 1));
  };

export const updateKeyDeal = (values, keyDealId, editFormType) => async (dispatch, getState) => {
  const user = getState().userReducer.user;
  await axios({
    method: "post",
    url: "/updateKeyDeal",
    headers: tokenConfig(getState).headers,
    data: {
      values,
      keyDealId: keyDealId,
    },
  })
    .then(async (res) => {
      if (res.data.result) {
        await axios
          .post(`/getDealById`, { dealId: keyDealId }, tokenConfig(getState))
          .then((res) => {
            if (editFormType) {
              dispatch({ type: REMOVE_ACTIVE_KEY_DEAL, payload: keyDealId });
            } else {
              dispatch({ type: REMOVE_NOT_ACTIVE_KEY_DEAL, payload: keyDealId });
              axios.post(
                `/sendInfoEmail`,
                {
                  subject: "Schlüsselfinder aktiviert",
                  text: `Der Kunde ${
                    user.contactVorname + " " + user.contactNachname
                  } aktivierte seinen Schlüsselfinder. Schlüsselfinder ID: ${
                    values.dealId
                  }\nVertrag ID: ${keyDealId}`,
                },
                tokenConfig(getState)
              );
            }
            if (!values.keyDeactive) {
              dispatch({ type: GET_ACTIVE_KEY_DEAL, payload: res.data.result });
            } else {
              axios.post(
                `/sendInfoEmail`,
                {
                  subject: "Schlüsselfinder deaktiviert",
                  text: `Der Kunde ${
                    user.contactVorname + " " + user.contactNachname
                  } deaktivierte seinen Schlüsselfinder. \nSchlüsselfinder ID: ${
                    values.dealId
                  }\nVertrag ID: ${keyDealId}`,
                },
                tokenConfig(getState)
              );
            }
            dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
          })
          .catch((err) =>
            dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"))
          );
      }
    })
    .catch((err) => dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen")));

  dispatch({
    type: HANDLE_PROCESS_SPINNER,
    payload: false,
  });
};

export const updateDealById = (contractId, data) => (dispatch) => {
  dispatch({
    type: UPDATE_DEAL_BY_ID,
    payload: { contractId: contractId, dataToUpdate: data },
  });
};

export const clearContractById = () => (dispatch) => {
  dispatch({
    type: CLEAR_FULLVERTRAGBYID,
    payload: {},
  });
};

const changeLoadingStatus = (status) => (dispatch) => {
  dispatch({
    type: CHANGE_LOADING_STATUS,
    payload: status,
  });
};

export const changeLoadingStatusAddEditForm = (status) => (dispatch) => {
  dispatch({
    type: CHANGE_LOADING_STATUS_ADD_EDIT_FORM,
    payload: status,
  });
};
