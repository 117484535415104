import { GET_ERRORS, CLEAR_ERRORS, ErrorsActionTypes } from "./errorTypes";
import { ErrorStateProps } from "./errorTypes";

const initialState: ErrorStateProps = {
  msg: "",
  status: null,
  id: null,
};

const reducer = (state = initialState, action: ErrorsActionTypes) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        id: action.payload.id,
      };

    case CLEAR_ERRORS:
      return {
        msg: {},
        status: null,
        id: null,
      };

    default:
      return state;
  }
};

export default reducer;
