export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const CHANGE_USER_DATA_SUCCESS = "CHANGE_USER_DATA_SUCCESS";
export const USER_BIRTHDAY_LOADING = "USER_BIRTHDAY_LOADING";
export const USER_BIRTHDAY_LOADED = "USER_BIRTHDAY_LOADED";
export const USER_LAST_NAME_LOADING = "USER_LAST_NAME_LOADING";
export const USER_LAST_NAME_LOADED = "USER_LAST_NAME_LOADED";
export const USER_FIRST_NAME_LOADING = "USER_FIRST_NAME_LOADING";
export const USER_FIRST_NAME_LOADED = "USER_FIRST_NAME_LOADED";
export const USER_ADDRESS_LOADING = "USER_ADDRESS_LOADING";
export const USER_ADDRESS_LOADED = "USER_ADDRESS_LOADED";
export const USER_HANDYNUMMER_LOADING = "USER_HANDYNUMMER_LOADING";
export const USER_HANDYNUMMER_LOADED = "USER_HANDYNUMMER_LOADED";
export const GET_USER_ID = "GET_USER_ID";
export const ADD_USER_PSWD = "ADD_USER_PSWD";
export const PSWD_CONFIRM = "PSWD_CONFIRM";
export const PSWD_CHANGE_MSG = "PSWD_CHANGE_MSG";
export const CLEAN_PSWD_STATE = "CLEAN_PSWD_STATE";
export const HANDLE_MANDAT = "HANDLE_MANDAT";
export const SAVE_USER_AVATAR = "SAVE_USER_AVATAR";
