import { AnyAction, applyMiddleware, legacy_createStore as createStore } from "redux";
import thunkMiddleware, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import rootReducer, { StateType } from "./rootReducer";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const store = createStore(rootReducer, composedEnhancers);

/* Types */
export type AppDispatch = typeof store.dispatch;
export type IRootState = StateType;
export type TypedDispatch = ThunkDispatch<IRootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;
