import React from "react";

const LoadingPage = () => {
  const gesellschaftsImages = require(`../../assets/images/logo1.gif`);

  return (
    <div className="loadingContainer">
      <div className="loadingImageContainer">
        <img alt="icon" src={gesellschaftsImages} className="loadingImage"></img>
      </div>
      <p>Wird geladen . . .</p>
    </div>
  );
};

export default LoadingPage;
