/*
	Welcome to the mess that is this codebase. But trust me, you have it better than me.
	I was tasked with replacing the entire old codebase with this new one, and I had to do it without any details.
	Things changed left and right in development, and everybody had their own idea of how the final page should function.

	Should you ever need to work on this page, I wish you the best of luck. I made this documentation of how the Bitrix24 Webhook works
	so that you can at least understand what's going on, and how the data is pulled. bitrix24 is awful, and I never want to work with it again.
	Here's the documentation: https://yunii.net/bitrix-docs/ (but note that it is entirely in german)

	Also, I'm sorry for the mess. I tried my best to make it as readable as possible, but I had to work with what I was given.
*/

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./Concept.scss";
//eslint-disable-next-line
import {
  getUserName,
  getFamilyMembers,
  getUserContracts,
  getUserQuotes,
  getSingleCompanyData,
  // getMissingContractData,
  getCompanyData,
  getProductDataFromQuote,
} from "./helpers/bitrix-helper";
import {
  calcSavings,
  updateQuoteStatus,
  // updateContractData,
  calcSavingsPercentage,
  calcSavingsInt,
} from "./helpers/quote-helper.js";
import { getProductIDfromQuote } from "./helpers/product-helper";

// components
import Indent from "./components/Indent";
import SparteIcon from "./components/SparteIcon";
import StarRating from "./components/StarRating";
import LiteralHTML from "./components/LiteralHTML";
import ErrorPage from "./components/ErrorPage";

// icons
// import add_shopping_cart from './img/svg/icons/add_shopping_cart.svg';
// import remove_shopping_cart from './img/svg/icons/remove_shopping_cart.svg';

// import { set } from 'date-fns';
import { calcCoffee, calcDinner, calcNights } from "./helpers/conversion-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useParams } from "react-router-dom";
import { getFilePath, showProcessAlert, veBugcheckMail, veMissingDataMail } from "../../redux";
import { useSelector } from "react-redux";
import LoadingPage from "../LoadingPage/LoadingPage";

// img
const veLogo =
  "https://bitrix.vertragsengel.de/docs/pub/396a1d2f172ec9e87fbc6512b87b0751/showPreview/?&token=2dceu5fk61cf";
const img_cafe =
  "https://bitrix.vertragsengel.de/docs/pub/1874f95cb700a4db565ac78290790d34/showPreview/?&token=wt98m4wavson";

const img_diner =
  "https://bitrix.vertragsengel.de/docs/pub/68f08c1e0ae76ebd017087c81040c619/showPreview/?&token=932e4etaahce";

const img_berlin =
  "https://bitrix.vertragsengel.de/docs/pub/be1c6a82a037e61c3e30aa69169dd70e/showPreview/?&token=zwixv7yg4v9a";

const header_bar =
  "https://bitrix.vertragsengel.de/docs/pub/4b853e21db407fc26836574349591a6b/showPreview/?&token=ce3y8h1wqe8a";

//eslint-disable-next-line
class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    const now = new Date();
    const formattedDate = now
      .toISOString()
      .replace(/T/, " ")
      .replace(/\.\d+Z$/, "");
    const unixTimestamp = Math.floor(now.getTime() / 1000);
    this.message = `${formattedDate} (Unix Timestamp: ${unixTimestamp}) - ${message}`;
  }
}

var quotes_response;
var contracts_response;
var familyMemberID;
/* var error = false;
 */ var userID;

function Concept() {
  // const [images, setImages] = useState([]);
  const { contactId } = useParams();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [contracts, setContracts] = useState([]);
  const [quotes, setQuotes] = useState([]);
  /*   const [savings, setSavings] = useState([]);
   */ const [companyData, setCompanyData] = useState([]);
  const [quoteCompanyData, setQuoteCompanyData] = useState([]);
  const [products, setProducts] = useState([]);
  const [globalSavings, setGlobalSavings] = useState(0);
  const [allContractCosts, setAllContractCosts] = useState(0);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showWarenkorb, setShowWarenkorb] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(true);

  const [inputValues, setInputValues] = useState({});

  /*   const [cLogoDir, setCLogoDir] = useState(null);
   */ const [qcLogoDir, setQCLogoDir] = useState(null);

  const [beraterMode, setBeraterMode] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [errorDetails, setErrorDetails] = useState({ uid: null, err: "ERR_DEFAULT_OR_UNKNOWN" });

  // two dimensional array calles setMissingData with useState
  const [missingData, setMissingData] = useState([]);
  //eslint-disable-next-line
  const [companies, setCompanies] = useState([]); // New state variable
  const [redirectToHomePage, setRedirectToHomePage] = useState(false);
  const { isAdmin, isLoading } = useSelector((state) => state.userReducer);

  useEffect(
    () => {
      const urlParams = new URLSearchParams(window.location.search);

      const urlId = urlParams.get("id");
      userID = urlId || contactId;
      /* for (const key of urlParams.keys()) {
        if (key.toLowerCase() === "id") {
          userID = urlParams.get(key);
          break;
        }
      } */

      if (!userID) {
        triggerErrorPage(userID, "ERR_NO_USER_LOGON");
      }

      var _beraterMode = urlParams.get("beraterMode") === "true" ? false : true;
      if (_beraterMode === null || _beraterMode === undefined) {
        _beraterMode = false;
      }
      // _beraterMode = !_beraterMode
      setBeraterMode(_beraterMode);
      async function fetchData() {
        try {
          if (!userID) {
          } else {
            const username_response = await getUserName(userID);
            const name =
              username_response.data.result[0].NAME +
              " " +
              username_response.data.result[0].LAST_NAME;
            setUserName(name);
          }
        } catch (error) {
          triggerErrorPage(userID, "ERR_USER_FETCH");
        }

        try {
          familyMemberID = await getFamilyMembers(userID);
        } catch (error) {
          triggerErrorPage(userID, "ERR_FAMILY_FETCH");
        }

        try {
          contracts_response = await getUserContracts(userID, familyMemberID);
        } catch (error) {
          triggerErrorPage(userID, "ERR_CONTRACTS_FETCH");
        }

        try {
          quotes_response = await getUserQuotes(userID, familyMemberID);
          // sort quotes by UF_CRM_5F5759DD8BDEF (int)
          quotes_response.data.result.sort(function (a, b) {
            return a.UF_CRM_5F5759DD8BDEF - b.UF_CRM_5F5759DD8BDEF;
          });
          setQuotes(quotes_response);
        } catch (error) {
          triggerErrorPage(userID, "ERR_QUOTES_FETCH");
        }

        try {
          const sortedContracts = orderDealsByQuotes(contracts_response, quotes_response);
          setContracts(sortedContracts);
        } catch (error) {
          triggerErrorPage(userID, "ERR_CONTRACTS_SORT");
        }

        if (quotes_response && quotes_response.data && quotes_response.data.result) {
          const savingsData = [];
          const productIDsData = [];

          try {
            for (const quote of quotes_response.data.result) {
              try {
                const savings = await calcSavings(quote.ID);
                savingsData.push(savings);

                const productID = await getProductIDfromQuote(quote.ID);
                productIDsData.push(productID);
              } catch (error) {
                savingsData.push("0.00");
                productIDsData.push(null);
                triggerErrorPage(userID, "ERR_SAVINGS_CALC_INNER");
              }
            }
          } catch (error) {
            triggerErrorPage(userID, "ERR_SAVINGS_CALC");
          }

          /*           setSavings(savingsData);
           */
          // CONTRACT COMPANY DATA
          try {
            // for each quote, get the company data using COMPANY_ID
            const companyDataResponse = await Promise.all(
              contracts_response.map(async (contract) => {
                try {
                  const companyDataAPI = await getCompanyData(contract.UF_CRM_1597406248);
                  return companyDataAPI;
                } catch (error) {
                  return null;
                }
              })
            );

            var _contractCompanyLogos = [];

            try {
              for (var _company1 of companyDataResponse) {
                if (_company1?.LOGO?.id) {
                  let companyLogoDir = await dispatch(
                    getFilePath({ filesId: _company1?.LOGO?.id, shouldReturn: true })
                  );
                  _contractCompanyLogos.push(
                    `https://bitrix.vertragsengel.de/upload/${companyLogoDir[0].subDirLogo}/${companyLogoDir[0].filename}`
                  );
                }
              }
            } catch (error) {
              triggerErrorPage(userID, "ERR_CONTRACT_COMPANY_LOGO_FETCH");
            }
            /*             setCLogoDir(_contractCompanyLogos);
             */
            setCompanyData(companyDataResponse);
          } catch (error) {
            triggerErrorPage(userID, "ERR_CONTRACT_COMPANY_DATA");
            return null;
          }

          // QUOTE COMPANY DATA
          try {
            // for each quote, get the company data using COMPANY_ID
            const QcompanyDataResponse = await Promise.all(
              quotes_response.data.result.map(async (quote, index) => {
                try {
                  const QcompanyDataAPI = await getCompanyData(quote.UF_CRM_5F5759DD9B66E);
                  return QcompanyDataAPI;
                } catch (error) {
                  return null;
                }
              })
            );

            var _quoteCompanyLogos = [];

            try {
              for (var _company of QcompanyDataResponse) {
                var companyLogoDir = await dispatch(
                  getFilePath({
                    filesId: _company?.LOGO?.id,
                    shouldReturn: true,
                  })
                );
                _quoteCompanyLogos.push(
                  `https://bitrix.vertragsengel.de/upload/${companyLogoDir[0].subDirLogo}/${companyLogoDir[0].filename}`
                );
              }
            } catch (error) {
              triggerErrorPage(userID, "ERR_QUOTE_COMPANY_LOGO_FETCH");
            }
            setQCLogoDir(_quoteCompanyLogos);

            setQuoteCompanyData(QcompanyDataResponse);
          } catch (error) {
            triggerErrorPage(userID, "ERR_QUOTE_COMPANY_DATA");
            return null;
          }
        }

        // START MISSINGDATA

        try {
          var missingData = [];

          const spartenIDs = {
            "Internet Kabel": 138,
            Strom: 39,
            Gas: 40,
            "Internet DSL": 41,
            Mobilfunk: 38,
            Datentarif: 196,
          };

          // TODO: GET PERSONALAUSWEIS LINK

          const stromUFs = {
            "Vertragsinhaber Name": "UF_CRM_1689165909",
            "Vertragsinhaber Adresse": "UF_CRM_1689166369",
            "Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
            Vorversorger: "UF_CRM_1689167243",
            Kundennummer: "UF_CRM_1597414622159",
            "Wechsel (Ja/Nein)": "UF_CRM_1689240881",
            Zählernummer: "UF_CRM_1597416212816",
          };

          const gasUFs = {
            "Vertragsinhaber Name": "UF_CRM_1689165909",
            "Vertragsinhaber Adresse": "UF_CRM_1689166369",
            "Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
            Vorversorger: "UF_CRM_1689167243",
            Kundennummer: "UF_CRM_1597414622159",
            "Wechsel (Ja/Nein)": "UF_CRM_1689240881",
            Zählernummer: "UF_CRM_1597416212816",
          };

          const dslUFs = {
            "Letzte Kontoabrechnung": "UF_CRM_1689168018",
            Bankverbindung: "UF_CRM_1603802581409",
            Anbieter: "UF_CRM_1675267632",
            Kundennummer: "UF_CRM_1597414622159",
            Vertragsende: "UF_CRM_1597405215572",
            Geschwindigkeit: "UF_CRM_1689167957",
          };

          const internetkabelUFs = {
            "Letzte Kontoabrechnung": "UF_CRM_1689168018",
            Bankverbindung: "UF_CRM_1603802581409",
            Anbieter: "UF_CRM_1675267632",
            Kundennummer: "UF_CRM_1597414622159",
            Vertragsende: "UF_CRM_1597405215572",
            Geschwindigkeit: "UF_CRM_1689167957",
          };

          const mobilfunkUFs = {
            "Letzte Kontoabrechnung": "UF_CRM_1689168018",
            Bankverbindung: "UF_CRM_1603802581409",
            Anbieter: "UF_CRM_1675267632",
            Kundennummer: "UF_CRM_1597414622159",
            Vertragsende: "UF_CRM_1597405215572",
            Geschwindigkeit: "UF_CRM_1689167957",
            Datenvolumen: "UF_CRM_1689168119",
            Wunschsmartphone: "UF_CRM_1689168072",
          };

          const datentarifUFs = {
            Datenvolumen: "UF_CRM_1689168119",
            Bankverbindung: "UF_CRM_1603802581409",
          };

          for (var contract of contracts_response) {
            var missingDataForContract = [];
            var sparte = contract.UF_CRM_5F365448B9021;

            //eslint-disable-next-line
            var sparteName = getKeyByValue(spartenIDs, sparte);

            //eslint-disable-next-line
            if (sparte == 39) {
              missingDataForContract.push("strom");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(stromUFs)) {
                missingDataForContract.push(key);
              }
            }
            //eslint-disable-next-line
            else if (sparte == 40) {
              missingDataForContract.push("gas");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(gasUFs)) {
                missingDataForContract.push(key);
              }
            }
            //eslint-disable-next-line
            else if (sparte == 41) {
              missingDataForContract.push("dsl");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(dslUFs)) {
                missingDataForContract.push(key);
              }
            }
            //eslint-disable-next-line
            else if (sparte == 138) {
              missingDataForContract.push("kabel");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(internetkabelUFs)) {
                missingDataForContract.push(key);
              }
            }
            //eslint-disable-next-line
            else if (sparte == 38) {
              missingDataForContract.push("mobilfunk");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(mobilfunkUFs)) {
                missingDataForContract.push(key);
              }
            }
            //eslint-disable-next-line
            else if (sparte == 196) {
              missingDataForContract.push("daten");
              //eslint-disable-next-line
              for (const [key, value] of Object.entries(datentarifUFs)) {
                missingDataForContract.push(key);
              }
            } else {
              // throw new CustomError(`Unknown sparteName: ${sparteName}`);
            }

            missingData.push(missingDataForContract);
          }
        } catch (error) {
          triggerErrorPage(userID, "ERR_MISSING_DATA");
        }

        // trim the array, so that empty parts of the array are cut out
        // var filteredMissingData = missingData.filter(item => item.missingData.length > 0);

        setMissingData(missingData);

        try {
          if (missingData.every((arr) => arr.length === 0)) {
            /*  const errorMessage = "Warning: The 'missingData' variable is empty.";
          const stackTrace = new Error().stack; */
          }
        } catch (error) {
          triggerErrorPage(userID, "ERR_MISSING_DATA_CHECK");
        }

        // END MISSINGDATA

        // each quote has quote.TERMS, a string that is split by "; " (semicolon and space) and contains the savings
        // for each quote split the string at "; " and save it as an array, then save all the arrays in an array.
        // make sure that the array is two dimensional

        // var savings = [];

        // for (var quote of quotes_response.data.result) {
        // 	var savingsForQuote = quote.TERMS.split("; ");
        // 	savings.push(savingsForQuote);
        // }

        // // trim the array, so that empty parts of the array are cut out
        // var filteredSavings = savings.filter(item => item.length > 0);

        // setSavings(filteredSavings);

        // products

        //const array for each quote
        var _products = [];

        try {
          for (var quote of quotes_response?.data.result) {
            var product_response = await getProductDataFromQuote(quote);
            let tmp = quote.UF_CRM_5F5759DDBE00D.split("|")[0];
            setGlobalSavings(parseFloat(globalSavings + tmp).toFixed(2));
            _products.push(product_response);
          }
        } catch (error) {
          triggerErrorPage(userID, "ERR_PRODUCTS_FETCH");
        }

        setProducts(_products);

        // allContractCosts - calc by adding all contract.UF_CRM_1597416619059.split("|")[0] together
        var _allContractCosts = 0;

        try {
          for (var _contract of contracts_response) {
            var contractCosts = parseFloat(_contract?.UF_CRM_1597416619059?.split("|")[0]);

            _allContractCosts = parseFloat(_allContractCosts) + parseFloat(contractCosts);
          }
        } catch (error) {
          triggerErrorPage(userID, "ERR_CONTRACT_COSTS_CALC");
        }

        setAllContractCosts(_allContractCosts || 0);

        // TODO: Fix globalSaving to account for all savings.
        setIsDataLoaded(true);

        // Fetch company data for each quote
        const fetchCompanyData = async () => {
          const companiesData = await Promise.all(
            quotes.data.result.map(async (quote, index) => {
              try {
                const companyData = await getSingleCompanyData(quote.COMPANY_ID);
                return companyData;
              } catch (error) {
                triggerErrorPage(userID, "ERR_SINGLE_QUOTE_COMPANY_DATA");
              }
            })
          );
          setCompanies(companiesData);
        };

        if (quotes.data && quotes.data.result) {
          fetchCompanyData();
        }
      }

      fetchData();
    }, //eslint-disable-next-line
    []
  );

  const handleCheckboxChange = (quote) => {
    const index = selectedQuotes.findIndex((selectedQuote) => selectedQuote.ID === quote.ID);

    if (index > -1) {
      const updatedSelectedQuotes = [...selectedQuotes];
      updatedSelectedQuotes.splice(index, 1);
      setSelectedQuotes(updatedSelectedQuotes);
    } else {
      setSelectedQuotes([...selectedQuotes, quote]);
    }
  };

  // const gatherMissingData = () => {
  // 	const allData = [];

  // 	selectedQuotes.forEach((quote) => {
  // 		const quoteData = [quote.ID];  // Add the quoteID at the beginning

  // 		const dataKey = quote.TITLE.toLowerCase().split(" ")[1];  // This will get 'strom' from 'Angebot Strom', 'gas' from 'Angebot Gas', etc.

  // 		// Given the format, this should be valid now
  // 		for (let i = 1; i <= missingData[dataKey].length; i++) {
  // 			let value = document.getElementById(`${dataKey}_${i}`).value;
  // 			quoteData.push(value);
  // 		}

  // 		allData.push(quoteData);
  // 	});

  // 	return allData;
  // };

  const triggerSuccessPage = () => {
    setIsContentVisible(false);

    var _app = document.getElementsByClassName("_content")[0];

    _app.innerHTML = `<div style="height: 100vh;"><h1 style="text-align: center;margin-top: 20px;">Ihre Anfrage war erfolgreich!</h1><br><h3 style="text-align: center;margin-top: 20px;">Unser Team wird sich bald bei ihnen melden.</h3></div>`;
  };

  const handleUpdateContractData = () => {
    /*     const dataToUpdate = {};
     */
    selectedQuotes.forEach((quote) => {
      const quoteType = getQuoteType(quote.TITLE).toLowerCase(); // This should match the keys in ufsMappings
      const inputsForQuote = Object.fromEntries(
        Object.entries(inputValues).filter(([key]) => key.startsWith(`${quoteType}_${quote.ID}_`))
      );

      const mappedData = mapInputsToFields(inputsForQuote, quoteType, quote.ID);

      const response = dispatch(veMissingDataMail(mappedData, quote, userName));

      if (response.status === 400) {
        triggerErrorPage(userID, "ERR_MISSING_DATA_MAIL");
      }

      triggerSuccessPage();
    });
  };

  const mapInputsToFields = (inputValues, quoteType, quoteID) => {
    const ufsMappings = {
      mobilfunk: {
        "Letzte Kontoabrechnung": "UF_CRM_1689168018",
        Bankverbindung: "UF_CRM_1603802581409",
        Anbieter: "UF_CRM_1675267632",
        Kundennummer: "UF_CRM_1597414622159",
        Vertragsende: "UF_CRM_1597405215572",
        Geschwindigkeit: "UF_CRM_1689167957",
        Datenvolumen: "UF_CRM_1689168119",
        Wunschsmartphone: "UF_CRM_1689168072",
      },
      strom: {
        "Vertragsinhaber Name": "UF_CRM_1689165909",
        "Vertragsinhaber Adresse": "UF_CRM_1689166369",
        "Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
        Vorversorger: "UF_CRM_1689167243",
        Kundennummer: "UF_CRM_1597414622159",
        Wechsel: "UF_CRM_1689240881",
        Zählernummer: "UF_CRM_1597416212816",
      },
      gas: {
        "Vertragsinhaber Name": "UF_CRM_1689165909",
        "Vertragsinhaber Adresse": "UF_CRM_1689166369",
        "Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
        Vorversorger: "UF_CRM_1689167243",
        Kundennummer: "UF_CRM_1597414622159",
        Wechsel: "UF_CRM_1689240881",
        Zählernummer: "UF_CRM_1597416212816",
      },
      dsl: {
        "Letzte Kontoabrechnung": "UF_CRM_1689168018",
        Bankverbindung: "UF_CRM_1603802581409",
        Anbieter: "UF_CRM_1675267632",
        Kundennummer: "UF_CRM_1597414622159",
        Vertragsende: "UF_CRM_1597405215572",
        Geschwindigkeit: "UF_CRM_1689167957",
      },
      internetkabel: {
        "Letzte Kontoabrechnung": "UF_CRM_1689168018",
        Bankverbindung: "UF_CRM_1603802581409",
        Anbieter: "UF_CRM_1675267632",
        Kundennummer: "UF_CRM_1597414622159",
        Vertragsende: "UF_CRM_1597405215572",
        Geschwindigkeit: "UF_CRM_1689167957",
      },
    };

    const fieldValues = [];

    const inputFieldsOrder = Object.keys(ufsMappings[quoteType]);

    for (let i = 0; i < inputFieldsOrder.length; i++) {
      const ufFieldName = inputFieldsOrder[i];
      if (inputValues) {
        fieldValues.push(`${ufFieldName}: ${inputValues[`${quoteType}_${quoteID}_${i}`]}`);
      } else {
        fieldValues.push(`${ufFieldName}: fuck`);
      }
    }

    return fieldValues;
  };

  const handleInputChange = (key, value) => {
    setInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [key]: value,
      };
      return updatedValues;
    });
  };

  /*  function generateInputKey(quoteType, quoteId, index) {
    return `${quoteType}_${quoteId}_${index}`;
  } */

  // const updateMissingData = async (allData) => {

  // 	const stromUFs = {
  // 		"Vertragsinhaber Name": "UF_CRM_1689165909",
  // 		"Vertragsinhaber Adresse": "UF_CRM_1689166369",
  // 		"Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
  // 		"Vorversorger": "UF_CRM_1689167243",
  // 		"Kundennummer": "UF_CRM_1597414622159",
  // 		"Wechsel": "UF_CRM_1689240881",
  // 		"Zählernummer": "UF_CRM_1597416212816",
  // 	};

  // 	const gasUFs = {
  // 		"Vertragsinhaber Name": "UF_CRM_1689165909",
  // 		"Vertragsinhaber Adresse": "UF_CRM_1689166369",
  // 		"Vertragsinhaber Geburtsdatum": "UF_CRM_1689167171",
  // 		"Vorversorger": "UF_CRM_1689167243",
  // 		"Kundennummer": "UF_CRM_1597414622159",
  // 		"Wechsel": "UF_CRM_1689240881",
  // 		"Zählernummer": "UF_CRM_1597416212816",
  // 	};

  // 	const dslUFs = {
  // 		"Letzte Kontoabrechnung": "UF_CRM_1689168018",
  // 		"Bankverbindung": "UF_CRM_1603802581409",
  // 		"Anbieter": "UF_CRM_1675267632",
  // 		"Kundennummer": "UF_CRM_1597414622159",
  // 		"Vertragsende": "UF_CRM_1597405215572",
  // 		"Geschwindigkeit": "UF_CRM_1689167957",
  // 	};

  // 	const internetkabelUFs = {
  // 		"Letzte Kontoabrechnung": "UF_CRM_1689168018",
  // 		"Bankverbindung": "UF_CRM_1603802581409",
  // 		"Anbieter": "UF_CRM_1675267632",
  // 		"Kundennummer": "UF_CRM_1597414622159",
  // 		"Vertragsende": "UF_CRM_1597405215572",
  // 		"Geschwindigkeit": "UF_CRM_1689167957",
  // 	};

  // 	const mobilfunkUFs = {
  // 		"Letzte Kontoabrechnung": "UF_CRM_1689168018",
  // 		"Bankverbindung": "UF_CRM_1603802581409",
  // 		"Anbieter": "UF_CRM_1675267632",
  // 		"Kundennummer": "UF_CRM_1597414622159",
  // 		"Vertragsende": "UF_CRM_1597405215572",
  // 		"Geschwindigkeit": "UF_CRM_1689167957",
  // 		"Datenvolumen": "UF_CRM_1689168119",
  // 		"Wunschsmartphone": "UF_CRM_1689168072"
  // 	};

  // 	try {
  // 		// loop through allData, 0th index is quoteID, check for it's DEAL_ID, update all fields from the 1st index of each array using the dictionaries above
  // 		for (const quoteData of allData) {
  // 			const quoteID = quoteData[0];
  // 			const quote = quotes.data.result.find((quote) => quote.ID === quoteID);
  // 			const dealID = quote.DEAL_ID;
  // 			const deal = contracts.find((contract) => contract.ID === dealID);
  // 			const sparte = deal.UF_CRM_5F365448B9021;

  // 			if (sparte == 39) {
  // 				// loop through the array, starting at index 1, and update the UF_CRM_ fields
  // 				for (let i = 1; i < quoteData.length; i++) {
  // 					const uf = stromUFs[missingData[sparte][i - 1]];
  // 					const value = quoteData[i];
  // 					await updateContractData(dealID, uf, value);
  // 				}
  // 			}
  // 			else if (sparte == 40) {
  // 				for (let i = 1; i < quoteData.length; i++) {
  // 					const uf = gasUFs[missingData[sparte][i - 1]];
  // 					const value = quoteData[i];
  // 					await updateContractData(dealID, uf, value);
  // 				}
  // 			}
  // 			else if (sparte == 41) {
  // 				for (let i = 1; i < quoteData.length; i++) {
  // 					const uf = dslUFs[missingData[sparte][i - 1]];
  // 					const value = quoteData[i];
  // 					await updateContractData(dealID, uf, value);
  // 				}
  // 			}
  // 			else if (sparte == 138) {
  // 				for (let i = 1; i < quoteData.length; i++) {
  // 					const uf = internetkabelUFs[missingData[sparte][i - 1]];
  // 					const value = quoteData[i];
  // 					await updateContractData(dealID, uf, value);
  // 				}
  // 			}
  // 			else if (sparte == 38) {
  // 				for (let i = 1; i < quoteData.length; i++) {
  // 					const uf = mobilfunkUFs[missingData[sparte][i - 1]];
  // 					const value = quoteData[i];
  // 					await updateContractData(dealID, uf, value);
  // 				}
  // 			}
  // 			else {
  // 				throw new CustomError(`Unknown sparte: ${sparte}`);
  // 			}
  // 		}
  // 	} catch (error) {
  // 		throw error;
  // 	}
  // };

  function getQuoteType(title) {
    if (title.includes("Strom")) {
      return "strom";
    } else if (title.includes("Gas")) {
      return "gas";
    } else if (title.includes("DSL")) {
      return "dsl";
    } else if (title.includes("Kabel")) {
      return "kabel";
    } else if (title.includes("Mobilfunk")) {
      return "mobilfunk";
    } else if (title.includes("Datentarif")) {
      return "daten";
    } else {
      return null; // Default case
    }
  }

  /*   const getQuoteNewType = (quote) => {
    // Replace these conditions with whatever logic applies to your data.
    if (quote.DEAL_ID) {
      return "Contract"; // or whatever you'd call this type
    } else {
      return "New Offer"; // or whatever you'd call this type
    }
  }; */

  const sanitizeDESC = (desc) => {
    var newDesc = desc.toString().replace("<br>", "");
    newDesc = newDesc.toString().replace("\\n", "");
    newDesc = newDesc.toString().replace("\n", "");
    newDesc = newDesc.split("#");

    for (var line in newDesc) {
      if (
        line === "" ||
        line === " " ||
        line === "" ||
        line === " " ||
        line === null ||
        line === undefined
      ) {
        newDesc.splice(line, 1);
      }
      if (
        line === "" ||
        line === " " ||
        line === "" ||
        line === " " ||
        line === null ||
        line === undefined
      ) {
        newDesc.splice(line, 1);
      }
    }

    return newDesc;
  };

  const triggerErrorPage = (uid, err = "ERR_DEFAULT_OR_UNKNOWN") => {
    try {
      // Do any cleanup here if needed
    } catch (error) {}

    setErrorDetails({ uid, err });
    setHasError(true);

    // TODO: UNCOMMENT
    dispatch(veBugcheckMail(uid, err));
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  function orderDealsByQuotes(contracts_response, quotes_response) {
    const quoteIdToIndexMap = {};

    // Create a map of quote IDs to their indices in the quotes_response array
    for (let i = 0; i < quotes_response.length; i++) {
      const quote = quotes_response[i];
      quoteIdToIndexMap[quote.ID] = i;
    }

    // Sort the contracts_response array based on the corresponding quote indices
    contracts_response.sort((dealA, dealB) => {
      const quoteIdA = dealA.DEAL_ID;
      const quoteIdB = dealB.DEAL_ID;
      const indexA = quoteIdToIndexMap[quoteIdA];
      const indexB = quoteIdToIndexMap[quoteIdB];
      return indexA - indexB;
    });

    return contracts_response;
  }

  const handleSaveQuotes = () => {
    try {
      setShowWarenkorb(true);
      setIsContentVisible(true);
    } catch (error) {
      triggerErrorPage(quotes[0].CONTACT_ID, "ERR_SAVE_QUOTES");
    }
  };

  const handleWarenkorbClose = () => {
    setShowWarenkorb(false);
    setIsContentVisible(true);
  };

  const handlePopupClick = (event) => {
    event.stopPropagation();
  };

  const handleUpdateQuoteStatus = async (quotes) => {
    try {
      const promises = quotes.map((quote) => updateQuoteStatus(quote.ID, "APPROVED"));
      await Promise.all(promises);
    } catch (error) {}
  };

  const formatEurNumber = (number) => {
    number = number.toString();
    if (number.split(".")[1] > 2) {
      number = number + "0";
    }
    return parseFloat(number).toFixed(2).replace(".", ",");
  };

  /* const alignedContractsAndQuotes = */ contracts.map((contract, index) => {
    /*     const relatedQuotes = quotes.data.result.filter((quote) => quote.DEAL_ID === contract.ID);
     */ return {
      contract,
      relatedQuotes: quotes.data.result.filter((quote) => quote.DEAL_ID === contract.ID),
      company: companyData[index], // assuming companyData is also 1:1 aligned with contracts
    };
  });

  function splitStringInMiddle(inputString) {
    // if the string contains the word "SIM" (hard-coded for mobile contracts)
    if (inputString.includes("SIM")) {
      var returnString = inputString.split("SIM");
      returnString[1] = "SIM" + returnString[1];
      return returnString;
    }

    // Calculate the middle index of the string
    const middleIndex = Math.floor(inputString.length / 2);

    // Find the space closest to the middle index
    let spaceIndex = middleIndex;
    while (inputString[spaceIndex] !== " " && spaceIndex < inputString.length) {
      spaceIndex++;
    }

    // If no space was found, just split the string in the middle
    if (spaceIndex === inputString.length) {
      return [inputString.substring(0, middleIndex), inputString.substring(middleIndex)];
    } else {
      return [inputString.substring(0, spaceIndex), inputString.substring(spaceIndex + 1)];
    }
  }

  function getQuoteSparteName(quote) {
    const sparten_ids = {
      124: "DSL",
      122: "Strom",
      123: "Gas",
      186: "Festnetz",
      121: "Mobilfunk",
      198: "Datentarif",
      125: "Pay-TV",
    };

    return sparten_ids[quote.UF_CRM_5F5759DD8BDEF];
  }
  useEffect(() => {
    if (isLoading === false) {
      if (!isAdmin) {
        dispatch(showProcessAlert("info-header2", "Konzept kann nicht geladen werden"));
        setRedirectToHomePage(true);
      }
    }
  }, [isLoading, isAdmin, dispatch]);

  return isLoading !== false ? (
    <LoadingPage />
  ) : redirectToHomePage ? (
    <Navigate to="/vertragsUebersicht" />
  ) : isAdmin ? (
    hasError ? (
      <ErrorPage err={errorDetails.err} />
    ) : (
      <div className="concept-page">
        <div style={{ backgroundImage: `url(${header_bar})` }} className="headerBar">
          <h1 className="greetingHeader">
            Haushaltskonzept
            <br />
            für {userName}
          </h1>
          <span className="bannerLogo">
            <img className="bannerLogoImg" src={veLogo} alt="Vertragsengel Logo" />
          </span>
        </div>
        {isDataLoaded ? (
          <div className="_content">
            <div className={`konzeptpage ${isContentVisible ? "visible" : "hidden"}`}>
              {quotes.data.length > 0 ? (
                <h1 className="sectionHeader">Verträge und Angebote</h1>
              ) : null}
              <br />
              <div
                className="konzeptpageContent"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="contracts" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="contracts-wrapper">
                    {quotes.data.result
                      .filter((_quote) => _quote.STATUS_ID !== "APPROVED")
                      .map((quote, index) => {
                        const relatedContract = contracts.find(
                          (contract) => contract.ID === quote.DEAL_ID
                        );
                        const relatedCompany = companyData.find(
                          (company) => company.ID === relatedContract?.UF_CRM_1597406248
                        );
                        const relatedQCompany = quoteCompanyData.find(
                          (company) => company.ID === quote.UF_CRM_5F5759DD9B66E
                        );
                        const relatedProduct = products.find(
                          (product) => product.ID === quote.UF_CRM_5F5759DDA0920
                        );

                        return relatedContract ? (
                          <div key={quote.DEAL_ID} className="contractOfferWrapper">
                            {/* -------------------------------------------------------------- */}

                            <div className="contract-offer">
                              <div className="contract-icon">
                                <SparteIcon sparte={parseInt(quote.UF_CRM_5F5759DD8BDEF)} />
                              </div>
                              <div className="contract-header">
                                <span className="contract-title">
                                  {" "}
                                  <Indent />
                                  <Indent />
                                  &nbsp;
                                  <strong>{relatedContract.TITLE.split(" ")[0]}</strong>
                                </span>
                              </div>
                              <div className="contract-body">
                                <div className="contract-body-flex">
                                  <div className="contract-body-left">
                                    {sanitizeDESC(relatedProduct?.DESCRIPTION).map(
                                      (point, index3) => {
                                        if (
                                          point !== "" &&
                                          point !== " " &&
                                          point !== "" &&
                                          point !== " " &&
                                          point !== null &&
                                          point !== undefined
                                        ) {
                                          return (
                                            <>
                                              <ul key={index3}>
                                                <LiteralHTML htmlString={point} />
                                              </ul>
                                            </>
                                          );
                                        }

                                        return null;
                                      }
                                    )}
                                    {products
                                      .filter(
                                        (product) => product.ID === quote.UF_CRM_5F5759DDA0920
                                      )
                                      .map((product, index) => {
                                        // only return if the VE Score is between 0 and 5
                                        if (
                                          product?.PROPERTY_91?.value >= 0 &&
                                          product?.PROPERTY_91?.value <= 5
                                        ) {
                                          return (
                                            <p key={product.ID} className="star-rating-p">
                                              VE Score:{" "}
                                              <StarRating rating={product?.PROPERTY_91?.value} />{" "}
                                              {parseInt(product?.PROPERTY_91?.value).toPrecision(2)}
                                            </p>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                  </div>
                                  <div className="contract-logo-box">
                                    <img
                                      className="contract-logo"
                                      src={qcLogoDir[index]}
                                      alt="Vertragsengel Logo"
                                    />
                                  </div>
                                  <div className="contract-body-right">
                                    <div className="vertical-line" />

                                    <div className="cbr-top">
                                      <div className="cbr-badge">
                                        <p className="cbr-badge-top">
                                          <i>Anbieter</i>
                                        </p>
                                        <p className="cbr-badge-bottom">
                                          {relatedQCompany?.TITLE.split("&")[0]}
                                        </p>
                                      </div>
                                      <div className="cbr-badge">
                                        <p className="cbr-badge-top">
                                          {splitStringInMiddle(relatedProduct.NAME)[0]}
                                        </p>
                                        <p className="cbr-badge-bottom">
                                          {splitStringInMiddle(relatedProduct.NAME)[1]}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="cbr-bottom">
                                      {relatedProduct?.PROPERTY_89?.value ? (
                                        <span className="cbr-bottom-highlights">
                                          <a
                                            className="cbr-bottom-highlights-button"
                                            href={relatedProduct?.PROPERTY_89?.value}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            &#9654; YouTube-Video
                                          </a>
                                        </span>
                                      ) : (
                                        <span className="cbr-bottom-highlights">
                                          <span className="cbr-bottom-highlights-button-red">
                                            Kein Video Verfügbar
                                          </span>
                                        </span>
                                      )}
                                      <div className="cbr-bottom-highlights">
                                        <span className="cbr-bottom-highlights-badge">
                                          GRUNDPREIS
                                        </span>
                                        <span className="cbr-bottom-highlights-text">
                                          {quote.TERMS.split(";")[0]}
                                        </span>
                                      </div>
                                      <div className="cbr-bottom-highlights">
                                        <span className="cbr-bottom-highlights-badge">
                                          ZUSATZKOSTEN
                                        </span>
                                        <span className="cbr-bottom-highlights-text">
                                          {quote.TERMS.split(";")[1]}
                                        </span>
                                      </div>
                                      {calcSavingsInt(
                                        relatedContract.UF_CRM_1597416619059.split("|")[0],
                                        quote.UF_CRM_5F5759DDBE00D.split("|")[0]
                                      ) > 0 ? (
                                        <div className="cbr-bottom-highlights">
                                          <span className="cbr-bottom-highlights-badge">
                                            PREISVORTEIL
                                          </span>
                                          <span className="cbr-bottom-highlights-text">
                                            {calcSavings(
                                              relatedContract.UF_CRM_1597416619059.split("|")[0],
                                              quote.UF_CRM_5F5759DDBE00D.split("|")[0]
                                            )}
                                          </span>
                                        </div>
                                      ) : null}

                                      <br />
                                    </div>
                                  </div>
                                </div>
                                <div className="pricing-section">
                                  <div className="vertical-line" />
                                  <div className="old-offer">
                                    <p className="cbr-badge-top">
                                      <b>Bisher:</b>
                                    </p>
                                    <p className="cbr-badge-bottom">{relatedCompany?.TITLE}</p>
                                    <p
                                      className="cbr-badge-bottom"
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "-20px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      {relatedContract.TITLE}
                                    </p>
                                    <p className="cbr-badge-bottom">
                                      {formatEurNumber(
                                        relatedContract.UF_CRM_1597416619059.split("|")[0]
                                      )}{" "}
                                      €/mo
                                    </p>
                                    <hr className="horizontalBreak" />
                                  </div>
                                  <div style={{ height: "35px" }} />
                                  <div className="pricing-savings">
                                    <div className="pricing-savings-badge">
                                      {calcSavingsPercentage(
                                        parseInt(
                                          relatedContract.UF_CRM_1597416619059.split("|")[0]
                                        ),
                                        parseInt(quote.UF_CRM_5F5759DDBE00D.split("|")[0])
                                      )}
                                    </div>
                                  </div>
                                  <br />
                                  <div className="pricing-bottom">
                                    <p className="monthly-cost">
                                      {formatEurNumber(quote.UF_CRM_5F5759DDBE00D.split("|")[0])}{" "}
                                      €/mo
                                    </p>
                                    {beraterMode === true ? (
                                      <>
                                        {/*<span className="checkbox-text">
																						Jetzt Wechseln:
																					</span>*/}

                                        <br />
                                        <br />
                                        <br />

                                        <div className="checkboxContainer">
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              unchecked="true"
                                              onClick={() => handleCheckboxChange(quote)}
                                            />
                                            <span className="slider-green round"></span>
                                          </label>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      })}
                  </div>
                </div>

                {quotes.data.result
                  .filter((_quote) => _quote.DEAL_ID == null)
                  .filter((_quote) => _quote.STATUS_ID !== "APPROVED").length > 0 ? (
                  <div className="new-offers">
                    <h1 className="sectionHeader">Neue Angebote</h1>
                    <div className="new-offers-list">
                      {quotes.data.result
                        .filter((_quote) => _quote.DEAL_ID == null)
                        .filter((_quote) => _quote.STATUS_ID !== "APPROVED")
                        .map((quote, index) => {
                          const relatedCompany = companyData.find(
                            (company) => company.ID === quote?.UF_CRM_5F5759DD9B66E
                          );
                          const relatedProduct = products.find(
                            (product) => product.ID === quote.UF_CRM_5F5759DDA0920
                          );

                          return (
                            <div key={quote.ID} className="contractOfferWrapper">
                              <div className="contract-offer">
                                <div className="contract-icon">
                                  <SparteIcon sparte={parseInt(quote.UF_CRM_5F5759DD8BDEF)} />
                                </div>
                                <div className="contract-header">
                                  <span className="contract-title">
                                    {" "}
                                    <Indent />
                                    <Indent />
                                    &nbsp;
                                    <strong>{getQuoteSparteName(quote)}</strong>
                                  </span>
                                </div>
                                <div className="contract-body">
                                  <div className="contract-body-flex">
                                    <div className="contract-body-new">
                                      <div>
                                        <div className="vertical-line" />
                                        <div className="cbr-top">
                                          <div className="cbr-badge-new">
                                            <div>
                                              <p className="cbr-badge-top">
                                                <i>Anbieter</i>
                                              </p>
                                              <p className="cbr-badge-bottom">
                                                {relatedCompany?.TITLE}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="cbr-badge-new">
                                            <p className="cbr-badge-top">
                                              {splitStringInMiddle(relatedProduct.NAME)[0]}
                                            </p>
                                            <p className="cbr-badge-bottom">
                                              {splitStringInMiddle(relatedProduct.NAME)[1]}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="cbr-new-line">
                                        {relatedProduct?.PROPERTY_89?.value ? (
                                          <span className="cbr-bottom-highlights">
                                            <a
                                              className="cbr-bottom-highlights-button"
                                              href={relatedProduct?.PROPERTY_89?.value}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              &#9654; YouTube-Video
                                            </a>
                                          </span>
                                        ) : (
                                          <span className="cbr-bottom-highlights">
                                            <span className="cbr-bottom-highlights-button-red">
                                              Kein Video Verfügbar
                                            </span>
                                          </span>
                                        )}
                                        <div className="cbr-bottom-new">
                                          <div className="cbr-bottom-highlights">
                                            <span className="cbr-bottom-highlights-badge">
                                              GRUNDPREIS
                                            </span>

                                            <span className="cbr-bottom-highlights-text">
                                              {quote.TERMS.split(";")[0]}
                                            </span>
                                          </div>

                                          <div className="cbr-bottom-highlights">
                                            <span className="cbr-bottom-highlights-badge">
                                              ZUSATZKOSTEN
                                            </span>

                                            <span className="cbr-bottom-highlights-text">
                                              {quote.TERMS.split(";")[1]}
                                            </span>
                                          </div>

                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pricing-section">
                                    <div className="vertical-line" />
                                    <p
                                      className="monthly-cost-new"
                                      style={{
                                        marginBottom: "-25px",
                                        marginTop: "75px",
                                      }}
                                    >
                                      {formatEurNumber(quote.UF_CRM_5F5759DDBE00D.split("|")[0])}{" "}
                                      €/mo
                                    </p>
                                    <div className="pricing-bottom">
                                      {beraterMode === true ? (
                                        <>
                                          {/*<span className="checkbox-text">
																							Jetzt Wechseln:
																						</span>*/}

                                          <br />
                                          <br />
                                          <br />

                                          <div className="checkboxContainerNew">
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                unchecked="true"
                                                onClick={() => handleCheckboxChange(quote)}
                                              />
                                              <span className="slider-green round"></span>
                                            </label>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : null}
              </div>
              <br />
              <br />

              {/* ONLY UNCOMMENT THIS IN THE FINAL RELEASE */}
              <div className="ebContainer">
                <button className="epicButton" onClick={handleSaveQuotes}>
                  <FontAwesomeIcon icon={faCartShopping} />
                  In den Warenkorb
                </button>
              </div>

              {showWarenkorb && (
                <div className="overlay open" onClick={handleWarenkorbClose}>
                  <div className="popup" onClick={handlePopupClick}>
                    <button className="close-button" onClick={handleWarenkorbClose}>
                      Schliessen
                    </button>
                    <br />
                    <br />
                    <h2>Ausgewählte Angebote:</h2>
                    <ul className="angeboteOverview" key="1">
                      {selectedQuotes?.map((quote, index) => {
                        const missingDataItem = missingData?.find((item) => {
                          const quoteType = getQuoteType(quote.TITLE); // You need to implement this function
                          return quoteType && item[0] === quoteType;
                        });

                        const quoteType = getQuoteType(quote.TITLE); // This should be a function that determines the quote type

                        if (!missingDataItem) {
                          try {
                            return (
                              <li key={quote.ID}>
                                <h4>
                                  {quote.TITLE.split(" ")[1]}:
                                  {companies
                                    .filter((company) => company.ID === quote.COMPANY_ID)
                                    .map((company) => (
                                      <span key={company.ID}>{company.TITLE}</span>
                                    ))}
                                  {products
                                    .filter((product) => product.ID === quote.UF_CRM_5F5759DDA0920)
                                    .map((product) => (
                                      <span key={product.ID}>{product.NAME}</span>
                                    ))}
                                </h4>
                                <p>Keine fehlenden Angaben</p>
                              </li>
                            );
                          } catch (error) {
                            // Handle error appropriately
                          }
                        }

                        try {
                          if (quoteType && missingDataItem[0] === quoteType) {
                            return (
                              <li key={quote.ID}>
                                <h4>
                                  {quote.TITLE}: {quoteCompanyData[index]?.TITLE} -{" "}
                                  {products[index]?.NAME}
                                </h4>
                                <p>Uns fehlen folgende Angaben:</p>
                                <form id="missingDataForm">
                                  {missingDataItem.slice(1).map((placeholder, i) => {
                                    const inputKey = `${quoteType}_${quote.ID}_${i}`;
                                    return (
                                      <input
                                        key={inputKey}
                                        type="text"
                                        placeholder={placeholder}
                                        value={inputValues[inputKey] || ""}
                                        onChange={(e) =>
                                          handleInputChange(inputKey, e.target.value)
                                        }
                                        required
                                      />
                                    );
                                  })}
                                </form>
                              </li>
                            );
                          }
                        } catch (error) {
                          // Handle error appropriately
                        }

                        return null;
                      })}
                    </ul>

                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        if (document.getElementById("missingDataForm").checkValidity()) {
                          handleUpdateContractData(selectedQuotes);
                          handleUpdateQuoteStatus(selectedQuotes);
                        } else {
                          alert("Bitte füllen Sie alle Felder aus!");
                        }
                      }}
                      className="sendData-button"
                      form="missingDataForm"
                    >
                      Abschicken
                    </button>
                  </div>
                </div>
              )}

              {/* Hasans Sabotage */}
              <div className="savingEndcardContainer">
                <div className="savingEndcardTotal">
                  <div className="savingEndcard">
                    <h1>Geben Sie ihr Geld lieber für die schönen Dinge des Lebens aus.</h1>
                    <div className="savingComparisson">
                      <div className="savingBeforeAfter">
                        <p className="savingBefore">
                          Derzeit:{" "}
                          <span className="highlightCurrent">
                            {formatEurNumber(allContractCosts)}
                          </span>{" "}
                          €/mo
                        </p>
                        <p className="savingAfter">
                          Zukünftig:{" "}
                          <span className="highlightSavings">
                            {formatEurNumber(allContractCosts - globalSavings)}
                          </span>{" "}
                          €/mo
                        </p>
                      </div>
                      <h2>
                        Sie sparen{" "}
                        <span className="highlightSavings">
                          {formatEurNumber(
                            parseFloat((globalSavings / allContractCosts) * 100) || 0
                          )}
                          %
                        </span>
                        .<br />
                        Dies entspricht einer Ersparnis von{" "}
                        <span className="highlightSavings">
                          {formatEurNumber(parseFloat(globalSavings))} €
                        </span>{" "}
                        pro Jahr
                        <br />
                        oder{" "}
                        <span className="highlightSavings">
                          {formatEurNumber(parseFloat(globalSavings * 5))} €
                        </span>{" "}
                        über einen Zeitraum von 5 Jahren.
                      </h2>
                      <div className="hugeEqualSign">=</div>
                      <div className="betterThingsToSpend">
                        <div className="cafeToGo">
                          <img src={img_cafe} className="img" alt="Cafè ToGo" />
                          <span className="betterThings-text">
                            {calcCoffee(globalSavings)} - Mal: <br />
                            Café ToGo
                          </span>
                        </div>
                        <div className="essenGehen">
                          <img src={img_diner} className="img" alt="Restaurant" />
                          <span className="betterThings-text">
                            {calcDinner(globalSavings)} - Mal <br />
                            Essen gehen
                          </span>
                        </div>
                        <div className="uebernachtungBerlin">
                          <img src={img_berlin} className="img" alt="Berlin" />
                          <span className="betterThings-text">
                            {calcNights(globalSavings)} - Mal <br />
                            in Berlin übernachten
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* <h2 className='loadingText'>Bitte warten...</h2><br /><br /> */}
            <div className="custom-loader"></div>
          </>
        )}
        {/* footer section */}
        <div className="footer">
          <span>
            <a href="https://vertragsengel.de/datenschutz">Datenschutz</a>
          </span>
          &nbsp;|&nbsp;
          <span>
            <a href="https://vertragsengel.de/impressum">Impressum</a>
          </span>
        </div>
      </div>
    )
  ) : null;
}

export default Concept;
