import React from "react";

const emptyStar =
  "https://bitrix.vertragsengel.de/docs/pub/4b22a8c28b37059a1e47ad1061212478/showPreview/?&token=rj0pqsne9w07";
const fullStar =
  "https://bitrix.vertragsengel.de/docs/pub/dced05e43088ffca92ca07647d89be4e/showPreview/?&token=lyu3rp15x8xh";
const halfStar =
  "https://bitrix.vertragsengel.de/docs/pub/1990463ee3b6a2fb0ce3ecda187afd7e/showPreview/?&token=licfvvndxbvl";

function StarRating(props) {
  // Validate that rating is within the range of 0 to 5 and is a number
  const rating = parseFloat(props.rating);
  if (isNaN(rating) || rating < 0 || rating > 5) {
    return null;
  }

  let fullStars = Math.floor(rating);
  let hasHalfStar = rating % 1 >= 0.5;

  // Create an array of full stars
  const fullStarArray = Array.from({ length: fullStars }, (_, index) => (
    <img key={index} src={fullStar} alt="Full Star" className="rating-star" />
  ));

  // Check if there should be a half star
  const halfStarArray = hasHalfStar
    ? [<img key="half" src={halfStar} alt="Half Star" className="rating-star" />]
    : [];

  // Calculate the number of empty stars needed
  let emptyStarsCount = 5 - fullStars - (hasHalfStar ? 1 : 0);

  // Create an array of empty stars
  const emptyStarArray = Array.from({ length: emptyStarsCount }, (_, index) => (
    <img key={index + 5} src={emptyStar} alt="Empty Star" className="rating-star" />
  ));

  return (
    <span className="star-rating">
      {fullStarArray}
      {halfStarArray}
      {emptyStarArray}
    </span>
  );
}

export default StarRating;
