import React from "react";
import { useField } from "formik";
import { SelectProps } from "@mui/material/Select";
import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  StyledSelect,
  StyledMenuItem,
} from "../../../../Helper/StyledMUIComponentsHelper/StyledComponents";

interface PropsElement {
  name: string;
  readOnly: boolean;
}

const MySelectField: React.FC<SelectProps> = ({ label, placeholder, type, ...props }) => {
  const [field, meta] = useField<PropsElement>({
    name: props.name!,
    readOnly: props.readOnly,
  });
  const errorText = meta.error && meta.touched ? meta.error : null;

  return (
    <FormControl variant="outlined" style={{ marginBottom: "20px" }}>
      <InputLabel id="outlined-adornment-amount">Wie hast du uns kennengelernt?</InputLabel>
      <StyledSelect
        {...field}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        type={type}
        error={errorText ? true : false} //or error={!!errorText}
      >
        <StyledMenuItem value="berater">Berater</StyledMenuItem>
        <StyledMenuItem value="internet">Internet</StyledMenuItem>
        <StyledMenuItem value="radioWerbung">Radio Werbung</StyledMenuItem>
        <StyledMenuItem value="zeitung">Zeitung</StyledMenuItem>
        <StyledMenuItem value="empfehlung">Empfehlung</StyledMenuItem>
        <StyledMenuItem value="sonstiges">Sonstiges</StyledMenuItem>
      </StyledSelect>
      {errorText ? (
        <FormHelperText style={{ color: "#f44336" }}>Dieses Field ist erforderlich</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default MySelectField;
