import Resizer from "react-image-file-resizer";

export const imageFileResizer = (file: any) => {
  try {
    return Resizer.imageFileResizer(
      file,
      1600,
      900,
      "JPEG",
      100,
      0,
      (uri) => {
        return uri;
      },
      "base64"
    );
  } catch (e) {
    console.log(e);
    return e;
  }
};
