export const ADD_FULLVERTRAG = "ADD_FULLVERTRAG";
export const GET_FULLVERTRAG = "GET_FULLVERTRAG";
export const GET_FULLVERTRAGBYID = "GET_FULLVERTRAGBYID";
export const CHANGE_STATUS_FULLVERTRAG = "CHANGE_STATUS_FULLVERTRAG";
export const ADD_FILE_PATH_ARRAY = "ADD_FILE_PATH_ARRAY";
export const CHANGE_LOADING_STATUS = "CHANGE_LOADING_STATUS";
export const MAKE_PHOTO_ARR_EMPTY = "MAKE_PHOTO_ARR_EMPTY";
export const ADD_TERMINATED_DEAL = "ADD_TERMINATED_DEAL";
export const CLEAR_FULLVERTRAGBYID = "CLEAR_FULLVERTRAGBYID";
export const CHANGE_LOADING_STATUS_ADD_EDIT_FORM = "CHANGE_LOADING_STATUS_ADD_EDIT_FORM";
export const SHOW_VALUE_ROUTE = "SHOW_VALUE_ROUTE";
export const GET_ACTIVE_KEY_DEAL = "GET_ACTIVE_KEY_DEAL";
export const GET_NOT_ACTIVE_KEY_DEAL = "GET_NOT_ACTIVE_KEY_DEAL";
export const REMOVE_NOT_ACTIVE_KEY_DEAL = "REMOVE_NOT_ACTIVE_KEY_DEAL";
export const REMOVE_ACTIVE_KEY_DEAL = "REMOVE_ACTIVE_KEY_DEAL";
export const ALLOW_TO_SHOW_KEY_DEAL = "ALLOW_TO_SHOW_KEY_DEAL";
export const REMOVE_DEAL_BY_ID = "REMOVE_DEAL_BY_ID";
export const UPDATE_DEAL_BY_ID = "UPDATE_DEAL_BY_ID";
