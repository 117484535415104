import axios from "axios";
import { tokenConfig } from "../index";
import {
  GET_ALL_GESELLSCHAFTEN,
  ALL_COMPANIES_LOADING,
  GET_GESELLSCHAFT_BY_ID,
  CLEAR_GESELLSCHAFT_BY_ID,
} from "./gesellschaftenTypes";

export const getGesellschaftById = (id) => (dispatch, getState) => {
  /* dispatch(gesellschaftenLoading()); */
  axios({
    method: "get",
    url: `/getGesellschaftById/${id}`,
    headers: tokenConfig(getState).headers,
  })
    .then((res) => {
      const gesObj = res.data.gesellschaftByID;
      Object.keys(gesObj).forEach((el) => {
        if (gesObj[el] === null) {
          gesObj[el] = "";
        }
      });
      dispatch({
        type: GET_GESELLSCHAFT_BY_ID,
        payload: gesObj,
      });
    })
    .catch((err) => console.log(err));
};

export const clearGesellschaftById = () => (dispatch) => {
  dispatch({
    type: CLEAR_GESELLSCHAFT_BY_ID,
    payload: {},
  });
};

export const getAllGesellschaften = () => (dispatch, getState) => {
  dispatch(gesellschaftenLoading());
  axios({
    method: "get",
    url: `/getCompanyList`,
    headers: tokenConfig(getState).headers,
  })
    .then(async (res) => {
      dispatch({
        type: GET_ALL_GESELLSCHAFTEN,
        payload: res.data,
      });
      await dispatch(organizeCompanyWithSection());
      dispatch(gesellschaftenLoading());
    })
    .catch((err) => console.log(err));
};

export const organizeCompanyWithSection = () => (dispatch, getState) => {
  const vertragsListReducer = getState().vertragsListReducer;
  const gesellschaftenReducer = getState().gesellschaftenReducer;
  for (let i = 0; i < vertragsListReducer.vertragsList.length; i++) {
    if (
      !vertragsListReducer.vertragsList[i].vertragsList2.length &&
      !vertragsListReducer.vertragsList[i].vertragsList.length
    ) {
      for (let j = 0; j < vertragsListReducer.vertragsGesellschaften.length; j++) {
        if (
          vertragsListReducer.vertragsGesellschaften[j].sectionId ===
          vertragsListReducer.vertragsList[i].productSectionId
        ) {
          for (let k = 0; k < gesellschaftenReducer.gesellschaftenList.length; k++) {
            if (
              vertragsListReducer.vertragsGesellschaften[j].companyId ===
              parseInt(gesellschaftenReducer.gesellschaftenList[k].ID)
            ) {
              vertragsListReducer.vertragsList[i].vertragsList2.push({
                id: gesellschaftenReducer.gesellschaftenList[k].ID,
                title: gesellschaftenReducer.gesellschaftenList[k].TITLE,
              });
              vertragsListReducer.vertragsList[i].vertragsList.push({
                id: gesellschaftenReducer.gesellschaftenList[k].ID,
                title: gesellschaftenReducer.gesellschaftenList[k].TITLE,
              });
            }
          }
        }
      }
    }
  }
};

export const updateCompanyAddress = (companyData) => (dispatch, getState) => {
  let whatIsChanged = "";

  let { UF_CRM_1597139724920, UF_CRM_1597139781866, UF_CRM_1597139791692 } =
    getState().gesellschaftenReducer.gesellschaftById;

  const { companyId, companyStrasse, companyPostleitzahl, companyOrt } = companyData;

  let compareArr = [
    [UF_CRM_1597139724920, companyStrasse, "Strasse"],
    [UF_CRM_1597139781866, companyPostleitzahl, "PLZ"],
    [UF_CRM_1597139791692, companyOrt, "Ort"],
  ];

  // eslint-disable-next-line
  compareArr.map((el) => {
    if (el[0] !== el[1]) {
      whatIsChanged += el[2] + " War: " + el[0] + " Ist: " + el[1] + "<br>";
    }
  });

  if (whatIsChanged !== "") {
    whatIsChanged += "Gesellschaft id: " + companyId;

    axios
      .post(
        "/changeCompanyAddress",
        {
          companyId: companyId,
          companyStrasse: companyStrasse,
          companyPostleitzahl: companyPostleitzahl,
          companyOrt: companyOrt,
        },
        tokenConfig(getState)
      )
      .then((res) => {
        if (res.data.result) {
          axios
            .post(
              "/changeCompanyAddressEmail",
              { whatIsChanged: whatIsChanged },
              tokenConfig(getState)
            )
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      });
  }
};

export const gesellschaftenLoading = () => {
  return {
    type: ALL_COMPANIES_LOADING,
  };
};
