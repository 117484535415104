const axios = require('axios');

const baseUrl = 'https://bitrix.vertragsengel.de/rest/1/0ri0va83oo73pmyf/';

class CustomError extends Error {
	constructor(message) {
		super(message);
		this.name = this.constructor.name;
	}
}

export const getProductDataFromQuote = async (quote) => {
	try {
		const response = await axios.get(baseUrl + 'crm.product.list.json?filter[ID]=' + quote.UF_CRM_5F5759DDA0920 + '&select[]=ID&select[]=DESCRIPTION&select[]=PRICE&select[]=PROPERTY_89&select[]=NAME&select[]=PROPERTY_91');
		const product = response.data.result[0];
		return product;
	} catch (error) {
		throw error; // Handle or rethrow the error as needed
	}
};



// This function retrieves the contact data associated with a specific user.
// It takes the request (req) and response (res) objects and queries the database to fetch the relevant vertrags based on the user ID.
// usage: const userName = await getUserName(id);
export const getUserName = async (id) => {
	try {
		const userName = await axios.get(baseUrl + 'crm.contact.list.json?filter[ID]=' + id);
		return userName;
	} catch (error) {
		throw new CustomError(`Error fetching user name for ID ${id}: ${error.message}`);
	}
};

export const getFamilyMembers = async (id) => {
	try {
		const wife = await axios.get(baseUrl + 'crm.contact.list.json?filter[ID]=' + id + '&select[]=UF_CRM_1604482379');
		const husband = await axios.get(baseUrl + 'crm.contact.list.json?filter[ID]=' + id + '&select[]=UF_CRM_1604482419');

		const wifeID = wife.data.result[0]?.UF_CRM_1604482379 || null;
		const husbandID = husband.data.result[0]?.UF_CRM_1604482419 || null;

		if (wifeID === null && husbandID === null) {
			console.log("No family members found for ID " + id);
			return null;
		} else if (wifeID === null && husbandID !== null) {
			console.log("Husband found for ID " + id);
			return husbandID;
		} else if (wifeID !== null && husbandID === null) {
			console.log("Wife found for ID " + id);
			return wifeID;
		}
	} catch (error) {
		throw new CustomError(`Error fetching family members for ID ${id}: ${error.message}`);
	}
}

// This function retrieves the contracts associated with a specific user.
// It takes the request (req) and response (res) objects and queries the database to fetch the relevant vertrags based on the user ID.
// usage: const contracts = await getUserContracts(id);
export const getUserContracts = async (id, fid = null) => {
	try {
		const contracts = await axios.get(baseUrl + 'crm.deal.list.json?filter[CONTACT_ID]=' + id + '&select[]=UF_CRM_5F365448B9021&select[]=TITLE&select[]=UF_CRM_1597406248&select[]=UF_CRM_1597416619059&select[]=TITLE&select[]=UF_CRM_1597406248');
		if (fid !== null) { const f_contracts = await axios.get(baseUrl + 'crm.deal.list.json?filter[CONTACT_ID]=' + fid + '&select[]=UF_CRM_5F365448B9021&select[]=TITLE&select[]=UF_CRM_1597406248&select[]=UF_CRM_1597416619059&select[]=TITLE&select[]=UF_CRM_1597406248'); contracts.data.result = contracts.data.result.concat(f_contracts.data.result); }

		const ContractSpartenIDs = [
			38, /*mobilfunk*/
			40, /*gas*/
			39, /*strom*/
			183, /*internet kabel*/
			41 /*internet dsl*/
		]

		// debug logs
		const filteredContracts = [];
		for (const contract of contracts.data.result) {
			if (ContractSpartenIDs.includes(parseInt(contract.UF_CRM_5F365448B9021))) {
				filteredContracts.push(contract);
			}
		}

		return filteredContracts;
	} catch (error) {
		throw new CustomError(`Error fetching user contracts for ID ${id}: ${error.message}`);
	}
};

// This function retrieves a single contract based on the provided sparteId, gesellschaftId, and vertragId.
// It performs a filtered request to fetch the specific contract details from the bitrix webhook.
// returns the contract details in JSON format. [vertrag: { _id, name, nameId, anbieter, anbieterId, anbieterLogoDir, anbieterLogoName }]
// (anbiterLogoDir points to the directory of the logo, anbieterLogoName points to the name of the logo file)
// usage: const contract = await getSingleUserContract(req, res);
export const getSingleUserContract = async (req, res) => {
	const { sparteId, gesellschaftId, vertragId } = req.body;
	if (vertragId && sparteId && gesellschaftId) {
		try {
			const results = await axios.get(baseUrl + 'crm.deal.list.json?filter[ID]=' + vertragId);
			if (results.data.result.length > 0) {
				const { ID, TITLE, COMPANY_ID } = results.data.result[0];

				res.json({
					vertrag: {
						_id: ID,
						name: TITLE,
						nameId: sparteId,
						anbieter: COMPANY_ID,
						anbieterId: gesellschaftId,
						anbieterLogoDir: COMPANY_ID,
						anbieterLogoName: COMPANY_ID,
					},
				});
			} else {
				res.json({
					vertrag: null
				});
			}
		} catch (err) {
			res.json({
				vertrag: null
			});
		}
	}
};

export const getSingleCompanyData = async (id) => {
	try {
		const results = await axios.get(baseUrl + 'crm.company.list.json?filter[ID]=' + id);
		if (results.data.result.length > 0) {
			return results.data.result[0];
		} else {
			return null;
		}
	} catch (err) {
		return null;
	}
};

// This function retrieves the quotes associated with a specific user.
// It takes the request (req) and response (res) objects and queries the database to fetch the relevant quotes based on the user ID.
// usage: const quotes = await getUserQuotes(id);
export const getUserQuotes = async (id, fid = null) => {
	try {
		var quotes = await axios.get(baseUrl + 'crm.quote.list.json?filter[CONTACT_ID]=' + id + '&select[]=ID&select[]=TITLE&select[]=UF_CRM_5F5759DDBE00D&select[]=TERMS&select[]=UF_CRM_5F5759DD8BDEF&select[]=COMPANY_ID&select[]=DEAL_ID&select[]=UF_CRM_5F5759DD9B66E&select[]=UF_CRM_5F5759DDA0920&select[]=STATUS_ID&select[]=UF_CRM_5F5759DD9B66E');
		if (fid !== null) { var f_quotes = await axios.get(baseUrl + 'crm.quote.list.json?filter[CONTACT_ID]=' + fid + '&select[]=ID&select[]=TITLE&select[]=UF_CRM_5F5759DDBE00D&select[]=TERMS&select[]=UF_CRM_5F5759DD8BDEF&select[]=COMPANY_ID&select[]=DEAL_ID&select[]=UF_CRM_5F5759DD9B66E&select[]=UF_CRM_5F5759DDA0920&select[]=STATUS_ID&select[]=UF_CRM_5F5759DD9B66E'); quotes.data.result = quotes.data.result.concat(f_quotes.data.result); }

		const sparte_check = [
			124,
			122,
			123,
			186,
			121,
			198,
			125
		];

		const filteredQuotes = [];
		for (const quote of quotes.data.result) {
			if (sparte_check.includes(quote.UF_CRM_5F5759DD8BDEF)) {
				if (quote.STATUS_ID === "APPROVED") {
					continue;
				} else {
					filteredQuotes.push(quote);
				}
			}
		}
		return quotes;
	} catch (e) {
		return e;
	}
};

// This function retrieves a single quote based on the provided vertragId.
// It performs a filtered request to fetch the specific quote details from the bitrix webhook.
// returns the quote details in JSON format. [vertrag: { _id, name }]
// usage: const quote = await getSingleUserQuote(req, res);
export const getSingleUserQuote = async (req, res) => {
	const { vertragId } = req.body;
	if (
		vertragId !== null &&
		vertragId !== "null"
	) {
		try {
			var results = await axios.get(baseUrl + 'crm.quote.list.json?filter[ID]=' + vertragId);
			let id = results.data.result[0].ID;
			let name = results.data.result[0].TITLE;

			res.json({
				vertrag: {
					_id: id,
					name: name,
				},
			});
		} catch (e) {
			res.json({
				vertrag: null,
			});
		}
	} else {
		res.json({
			vertrag: null,
		});
	}
};

export const getCompanyData = async (id) => {
	try {
		const results = await axios.get(baseUrl + 'crm.company.list.json?filter[ID]=' + id);
		if (results.data.result.length > 0) {
			// return ALL data of the company
			return results.data.result[0];
		} else {
			return null;
		}
	} catch (err) {
		return null;
	}
};


export const getUserSingleQuoteTarifID = async (quoteID) => {
	try {
		const response = await axios.get(baseUrl + 'crm.quote.list.json?filter[ID]=' + quoteID + "&select[]=UF_CRM_5F5759DDA0920");
		const fieldValue = response.data.result[0]?.UF_CRM_5F5759DDA0920 || null;
		return fieldValue;
	} catch (error) {
		return null; // Handle or rethrow the error as needed
	}
};

export const getQuoteSavingsAmount = async (quoteID) => {
	try {
		const response = await axios.get(baseUrl + 'crm.quote.list.json?filter[ID]=' + quoteID + "&select[]=UF_CRM_5F5759DDBE00D");
		const fieldValue = response.data.result[0]?.UF_CRM_5F5759DDBE00D || null;

		if (fieldValue) {
			const numberString = fieldValue.split("|")[0];
			const parsedFloat = parseFloat(numberString);
			return parsedFloat;
		} else {
			return 0.00;
		}
	} catch (error) {
		throw error; // Handle or rethrow the error as needed
	}
};

export const getContractMonthlyPaymentAmount = async (contractID) => {
	try {
		const response = await axios.get(baseUrl + 'crm.deal.list.json?filter[ID]=' + contractID + "&select[]=UF_CRM_1597416619059");
		const fieldValue = response.data.result[0]?.UF_CRM_1597416619059 || null;

		if (fieldValue) {
			const numberString = fieldValue.split("|")[0];
			const parsedFloat = parseFloat(numberString);
			return parsedFloat;
		} else {
			return 0.00;
		}
	} catch (error) {
		throw error; // Handle or rethrow the error as needed
	}
};
