import {
  HANDLE_PROCESS_SPINNER,
  HANDLE_PROCESS_ALERT,
  HANDLE_REDIRECT_TO,
  CLEAR_APP_SETTINGS,
  ADD_INTERVAL_ID,
} from "./appSettingsTypes";
import { logout, tokenConfig } from "../index";
import axios from "axios";
import { convertDbDate } from "../../components/Helper/DateHelper/convertDbDate";
import { convertDbDateToTime } from "../../components/Helper/DateHelper/convertDbDateToTime";

export const showProcessSpinner = (val) => (dispatch, getState) => {
  dispatch({ type: HANDLE_PROCESS_SPINNER, payload: val });
};

export const redirectTo = (path) => (dispatch, getState) => {
  dispatch({
    type: HANDLE_REDIRECT_TO,
    payload: path,
  });
};

export const showProcessAlert =
  (alertType, alertMessage, userActivity = false) =>
  (dispatch, getState) => {
    userActivity && dispatch(incrementUserActivity());
    dispatch({
      type: HANDLE_PROCESS_ALERT,
      payload: { alertType: alertType, alertMessage: alertMessage },
    });

    setTimeout(() => {
      dispatch({
        type: HANDLE_PROCESS_ALERT,
        payload: { alertType: "", alertMessage: "" },
      });
    }, 3000);
  };

const noActivityUserEmail = () => (dispatch, getState) => {
  const { id, user } = getState().userReducer;

  const date = convertDbDate(user.lastLoginDate);
  const time = convertDbDateToTime(user.lastLoginDate);

  axios.post(
    `/sendInfoEmail`,
    {
      subject: "Neuer App-Login ohne Aktivität",
      text: `${user.contactVorname} ${user.contactNachname} hat sich am ${date} um ${time} in die app eingeloggt aber keine neuen Daten eingetragen.\nKunden-ID: ${id}`,
    },
    tokenConfig(getState)
  );
};

export const checkUserActivity = () => (dispatch, getState) => {
  const userActivityCount = localStorage.getItem("userActivity");

  if (!userActivityCount) {
    dispatch(noActivityUserEmail());
    dispatch(incrementUserActivity());
  }
};

/* const startCheckingUserActivity = () => (dispatch, getState) => {
  const checkUserActicityTimeout = setTimeout(() => {
    dispatch({
      type: REMOVE_SETTIMEOUT_ID,
      payload: checkUserActicityTimeout,
    });

    dispatch(checkUserActivity());
  }, 1200000);

  dispatch({
    type: ADD_TIMEOUT_ID,
    payload: checkUserActicityTimeout,
  });
}; */

const startCheckingIfShouldLogout =
  (updateExpiredTime, updateExpiredTimeTypes) => (dispatch, getState) => {
    updateExpiredTimeTypes.forEach((type) => window.addEventListener(type, updateExpiredTime));

    const interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem("_expiredTime"));
      const tokenExists = localStorage.getItem("token");

      if (!tokenExists || Date.now() - expiredTime > 3600000) {
        dispatch(logout());
      }
    }, 1000);

    dispatch({
      type: ADD_INTERVAL_ID,
      payload: interval,
    });
    updateExpiredTime();
  };
const updateExpiredTimeTypes = ["mousemove", "scroll", "keydown"];

const updateExpiredTime = () => {
  localStorage.setItem("_expiredTime", Date.now());
};

export const runAppSettings = () => (dispatch, getState) => {
  /*   dispatch(startCheckingUserActivity());
   */ dispatch(startCheckingIfShouldLogout(updateExpiredTime, updateExpiredTimeTypes));
};

export const clearAppSettings = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_APP_SETTINGS });
  dispatch(checkUserActivity());

  updateExpiredTimeTypes.forEach((type) => window.removeEventListener(type, updateExpiredTime));
  localStorage.removeItem("userActivity");
  localStorage.removeItem("_expiredTime");
};

export const incrementUserActivity = () => (dispatch, getState) => {
  const userActivityCount = localStorage.getItem("userActivity");
  if (!userActivityCount) {
    localStorage.setItem("userActivity", 1);
  } else {
    localStorage.setItem("userActivity", parseInt(userActivityCount) + 1);
  }
};

export const veBugcheckMail = (uid, err) => (dispatch, getState) => {
  axios({
    method: "post",
    url: `/veBugcheckMail`,
    headers: tokenConfig(getState).headers,
    data: {
      uid: uid,
      err: err,
    },
  });
};

export const veMissingDataMail = (data_, quote, name) => async (dispatch, getState) => {
  try {
    const _data = data_;
    const missingData = _data.filter(
      (item) => item.value === null || item.value === undefined || item.value === ""
    );
    const missingDataJSON = JSON.stringify(missingData);
    const quoteJSON = JSON.stringify(quote);
    const nameJSON = JSON.stringify(name);

    // if data is empty, cancel
    if (missingDataJSON.length === 0) {
      return;
    }

    // prepare missingDataJSON and quoteJSON to be used as JSON data during a POST request
    const data = {
      missingData: missingDataJSON,
      quote: quoteJSON,
      name: nameJSON,
    };

    console.log("DATA", data);

    const response = await axios({
      method: "post",
      url: `/veMissingDataMail`,
      headers: tokenConfig(getState).headers,
      data: data,
    });

    console.warn(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
