import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserId,
  getAllGesellschaften,
  getVertragsArray,
  getGesellschaften,
  loadUser,
  getFullVertrag,
  addProductionSectionIdToVertragsList,
  runAppSettings,
} from "./redux/index";
import "./assets/fonts/Poppins/Poppins-Regular.ttf";
import "./App.scss";
import { ProcessLoadingSpinner } from "./components/Helper/ComponentsHelper/ProcessLoadingSpinner";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import Login from "./components/Authorization/Login/Login2";
import PasswortZuruecksetzen from "./components/Authorization/PasswortZuruecksetzen/PasswortZuruecksetzen";
import PasswortVergessen from "./components/Authorization/PasswortVergessen/PasswortVergessen";
import Register from "./components/Authorization/Register/Register2";
import ErrorBoundary from "./components/Helper/ErrorBoundary/ErrorBoundary";
import Concept from "./components/Concept/Concept";
/* import Dashboard from "./components/HouseholdInterview/Dashboard/Dashboard";
 */
const VertragsUebersicht = lazy(() => import("./components/vertragsUebersicht/VertragsUebersicht"));
const PrivateRoute = lazy(() => import("./components/Helper/URLHelper/PrivateRoute"));
const KeyFinderLogin = lazy(() => import("./components/Authorization/Login/KeyFinderLogin.tsx"));
const KeyCenter = lazy(() => import("./components/KeyCenter/KeyCenter"));
const Valuables = lazy(() => import("./components/Valuables/Valuables"));
const Profil = lazy(() => import("./components/Profil/Profil"));
const Kuendigung = lazy(() => import("./components/Kuendigung/Kuendigung.tsx"));
const VertragsDetail = lazy(() =>
  import("./components/vertragsUebersicht/VertragsDetail/VertragsDetail")
);
const HouseholdInterview = lazy(() => import("./components/HouseholdInterview/HouseholdInterview"));
const Kontakt = lazy(() => import("./components/kontaktPage/Kontakt"));
const VertragsAuswahl = lazy(() => import("./components/vertragsAuswahl/VertragsAuswahl"));

const App = ({
  getUserId,
  token,
  getVertragsArray,
  productSectionList,
  vertragsList,
  getGesellschaften,
  loadUser,
  getFullVertrag,
  getAllGesellschaften,
  user,
  showValueRoute,
  showKeyDealsRoute,
  addProductionSectionIdToVertragsList,
  runAppSettings,
}) => {
  const [state, setState] = useState({
    showSidebar: false,
    showMessageNew: false,
  });
  const { showProcessSpinner, alertType, alertMessage } = useSelector(
    (state) => state.appSettingsReducer
  );

  const openSidebar = () => {
    setState({ showSidebar: !state.showSidebar });
  };

  const closeSidebar = () => {
    setState({ showSidebar: false });
  };

  useEffect(() => {
    if (token) {
      const loadAppState = async () => {
        runAppSettings();
        getVertragsArray();
        getAllGesellschaften();
        getGesellschaften();
        const id = await getUserId();
        if (id) {
          loadUser(id);
          getFullVertrag(id);
        }
      };

      loadAppState();
    }
  }, [
    token,
    getVertragsArray,
    getAllGesellschaften,
    loadUser,
    getFullVertrag,
    getGesellschaften,
    getUserId,
    runAppSettings,
  ]);

  useEffect(() => {
    if (Array.isArray(vertragsList) && Array.isArray(productSectionList)) {
      addProductionSectionIdToVertragsList();
    }
  }, [vertragsList, productSectionList, addProductionSectionIdToVertragsList]);

  let data = sessionStorage.getItem("login");
  return (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        <ErrorBoundary>
          <div className="App">
            {showProcessSpinner && <ProcessLoadingSpinner />}
            {alertMessage && <div className={alertType}>{alertMessage}</div>}
            {data || token ? (
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="vertragsUebersicht/"
                  element={
                    <VertragsUebersicht
                      showSidebar={state.showSidebar}
                      closeSidebar={closeSidebar}
                      openSidebar={openSidebar}
                    />
                  }
                >
                  <Route path="kontakt" element={<Kontakt />} />
                  <Route path="detail/:id" element={<VertragsDetail />} />
                  <Route path="gekuendigteVertraege" />
                  <Route path="gekuendigteVertraege/:id" element={<VertragsDetail />} />
                  <Route exact path="gekuendigteVertraege/profil" element={<Profil />} />
                  <Route path="profil" element={<Profil />} />
                </Route>
                <Route
                  path="vertragsAuswahl"
                  element={
                    <VertragsAuswahl
                      openSidebar={openSidebar}
                      showSidebar={state.showSidebar}
                      closeSidebar={closeSidebar}
                    />
                  }
                >
                  <Route path="profil" element={<Profil />} />
                </Route>

                <Route
                  path="/Kuendigungs/:id"
                  element={
                    <Kuendigung
                      closeSidebar={closeSidebar}
                      openSidebar={openSidebar}
                      showSidebar={state.showSidebar}
                    />
                  }
                >
                  <Route path="profil" element={<Profil />} />
                </Route>
                {showKeyDealsRoute ? (
                  <Route
                    path="/keyCenter"
                    element={
                      <KeyCenter openSidebar={openSidebar} showSidebar={state.showSidebar} />
                    }
                  />
                ) : null}

                {user.user.contactDocumentsId && showValueRoute && showValueRoute.length ? (
                  <Route
                    path="/values"
                    element={
                      <Valuables openSidebar={openSidebar} showSidebar={state.showSidebar} />
                    }
                  />
                ) : null}
                <Route path="/householdInterview/:contactId" element={<HouseholdInterview />} />
                <Route path="/konzept/:contactId" element={<Concept />} />
                <Route path="*" element={<Navigate to="/vertragsUebersicht" />} />
              </Routes>
            ) : (
              <Routes>
                {/*                 <Route path="/dashboard" element={<Dashboard />} />
                 */}{" "}
                <Route path="/login" element={<Login />} />
                <Route path="/schluesselzentrale" element={<KeyFinderLogin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgotPassword" element={<PasswortVergessen />} />
                <Route path="/resetPassword/:token" element={<PasswortZuruecksetzen />} />
                <Route path="/householdInterview/:clientId" element={<PrivateRoute />} />
                <Route path="/konzept/:clientId" element={<PrivateRoute />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            )}
          </div>
        </ErrorBoundary>
      </Router>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.userReducer.isAuthenticated,
  token: state.userReducer.token,
  id: state.userReducer.id,
  user: state.userReducer,
  vertragsList: state.vertragsListReducer.vertragsList,
  productSectionList: state.vertragsListReducer.productSectionList,
  showValueRoute: state.fullVertragReducer.showValueRoute,
  showKeyDealsRoute: state.fullVertragReducer.showKeyDealsRoute,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserId,
      getVertragsArray,
      getGesellschaften,
      loadUser,
      getFullVertrag,
      getAllGesellschaften,
      addProductionSectionIdToVertragsList,
      runAppSettings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
