import {
  GET_SECTIONS,
  SECTIONS_LOADING,
  GET_COMPANIES,
  COMPANIES_LOADING,
  ADD_PRODUCT_SECTION_LIST,
  CompaniesArrayStateType,
  DealsArrayActionTypes,
} from "./vertragsArrayTypes";

const initialState: CompaniesArrayStateType = {
  vertragsList: [],
  vertragsGesellschaften: [],
  productSectionList: [],
  loading: false,
};

const reducer = (state = initialState, action: DealsArrayActionTypes) => {
  switch (action.type) {
    case SECTIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SECTIONS:
      return {
        ...state,
        vertragsList: action.payload,
        loading: false,
      };
    case COMPANIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES:
      return {
        ...state,
        vertragsGesellschaften: action.payload,
        loading: false,
      };
    case ADD_PRODUCT_SECTION_LIST:
      return {
        ...state,
        productSectionList: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
