import React from "react";
import { useField } from "formik";
import { InputAdornment, FormControl } from "@mui/material";
import { StyledTextField } from "../../../../Helper/StyledMUIComponentsHelper/StyledComponents";

const MyTextField = ({
  className = null,
  prefix = null,
  suffix = null,
  prefixContent = null,
  suffixContent = null,
  readOnly = false,
  defaultValue = null,
  fieldgroup = null,
  label,
  placeholder,
  type,
  formikHandleChange = null,
  invokeExternFuncOnChange = null,
  valueRegex = null,
  onChange = null,
  style = {},
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : null;

  return (
    <FormControl className={className} variant="outlined">
      {formikHandleChange ? (
        <StyledTextField
          {...field}
          onChange={(e) => {
            if (valueRegex && !e.target.value.match(valueRegex)) {
              return;
            }

            formikHandleChange(e);
            invokeExternFuncOnChange(e);
          }}
          label={label}
          helperText={errorText}
          placeholder={placeholder}
          fieldgroup={fieldgroup}
          variant="outlined"
          type={type}
          error={errorText ? true : false} //or error={!!errorText}
          style={{ ...style }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment:
              prefix !== null ? (
                <InputAdornment position="start">{prefixContent}</InputAdornment>
              ) : null,
            endAdornment: suffix !== null ? suffixContent : null,
            readOnly: readOnly,
          }}
        />
      ) : (
        <StyledTextField
          {...field}
          onChange={onChange ? onChange : field.onChange}
          label={label}
          helperText={errorText}
          placeholder={placeholder}
          fieldgroup={fieldgroup}
          variant="outlined"
          type={type}
          error={errorText ? true : false} //or error={!!errorText}
          style={{ ...style }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment:
              prefix !== null ? (
                <InputAdornment position="start">{prefixContent}</InputAdornment>
              ) : null,
            endAdornment: suffix !== null ? suffixContent : null,
            readOnly: readOnly,
          }}
        />
      )}
    </FormControl>
  );
};

export default MyTextField;
