import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <h1>Ups ... Irgendetwas ist schief gelaufen</h1>
        <p>
          <Link reloadDocument to="/vertragsUebersicht">
            Weiter zur Startseite
          </Link>
        </p>
      </div>
    </Fragment>
  );
}

export default NotFound;
