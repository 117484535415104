import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { AxiosRequestConfig } from "axios";
import { DealInterface } from "../../components/Helper/Interfaces/dealInterface";
import {
  ContactInterface,
  SPAUserDataType,
} from "../../components/Helper/Interfaces/userInterface";

export const LOAD_CONTACT = "LOAD_CONTACT";
export const LOAD_STATE = "LOAD_STATE";
export const START_LOADING_FORM_FIELD = "START_LOADING_FORM_FIELD";
export const SUCCESS_LOADED_FORM_FIELD = "SUCCESS_LOADED_FORM_FIELD";
export const CLEAR_INTERVIEW_FIELDS_ARR = "CLEAR_INTERVIEW_FIELDS_ARR";
export const LOAD_CONTACT_RELATIONSHIP = "LOAD_CONTACT_RELATIONSHIP";
export const CHANGE_CURRENT_CONTACT_DATA = "CHANGE_CURRENT_CONTACT_DATA";
export const SET_STEP_INVALID_ELEMENT = "SET_STEP_INVALID_ELEMENT";
export const CLEAR_STEP_INVALID_ELEMENT = "CLEAR_STEP_INVALID_ELEMENT";
export const RESET_STEP = "RESET_STEP";
export const REMOVE_RELATION = "REMOVE_RELATION";
export const MANAGE_DEALS = "MANAGE_DEALS";
export const REMOVE_DEALS_BY_CONTACT_ID = "REMOVE_DEALS_BY_CONTACT_ID";
export const SET_STATUS = "SET_STATUS";
export const REMOVE_DEAL_BY_DEAL_ID = "REMOVE_DEAL_BY_DEAL_ID";
export const UPDATE_RELATION_STATE_BY_ID = "UPDATE_RELATION_STATE_BY_ID";
export const SET_SAVING_DATA_STATUS = "SET_SAVING_DATA_STATUS";
export const DISABLE_REINITIALIZE_FORM = "DISABLE_REINITIALIZE_FORM";
export const ADD_INCOMPLETE_CONTACT = "ADD_INCOMPLETE_CONTACT";
export const REMOVE_INCOMPLETE_CONTACT = "REMOVE_INCOMPLETE_CONTACT";
export const SET_CURRENT_PERSON = "SET_CURRENT_PERSON";

export type Step = "stepTwo" | "stepThree" | "stepFour" | "stepFive" | "stepSix" | "stepSeven";

export interface StepIsValidDataProp {
  dealsList: Array<ExistingDeal>;
}

export interface SaveDataInterviewValueType {
  [key: string]: string | number | boolean | Array<number>;
}

export interface SaveDetailFieldsProp {
  [key: string]: string | boolean | Array<string> | number;
}

export interface IncompleteContactDataToRemove {
  data: { NAME: string; LAST_NAME: string; name: string };
}

export interface IncompleteContact {
  id: number;
  dataId?: string;
  group: number;
  familyMemberDE: string;
  name: string;
}

interface StepTwoState {
  id: number;
  sectionId: number;
  name: string;
  icon: IconDefinition;
  radioCount: number;
}

export interface ContactListItem {
  name?: string;
  contactId: string;
  contactName: string;
  contactLastName: string;
}

export interface RelationContactsAxiosResType extends AxiosRequestConfig {
  relationName: string;
}

export interface RelationData {
  id: number;
  name: string;
  dataId: string;
  multiple: boolean;
  count: number;
  icon: IconDefinition;
  familyMemberDE: string;
  group: number;
}

export interface ContactData {
  contactToInterview: ContactInterface;
  SPAUserData: SPAUserDataType;
}

interface CompanyElement {
  id: string;
  title: string;
}

export interface ExistingDeal {
  amount?: string;
  companyId?: string;
  companiesArray?: Array<CompanyElement> | [];
  companyName?: string;
  contactId?: string;
  contactLastName?: string;
  contactName?: string;
  dealId: number | string;
  elementId?: string;
  insuredContact?: string;
  paymentName?: string;
  paymentValue?: string;
  sectionId: string;
  sectionName?: string;
  expirySum?: string;
  bruttoPension?: string;
  endOfContract?: string;
  contractBeginning?: string;
  insuranceNumber?: string;
  customerNumber?: string;
  dealNumber?: string;
  name?: string;
  interest?: string;
  monthlyCoverageAmount?: string;
}

export interface SaveStepProps {
  existingDeals?: Array<ExistingDeal>;
  initialExistingDeals?: Array<ExistingDeal>;
  dealsList?: Array<ExistingDeal>;
}

export interface ContactRelationData extends IncompleteContact {
  data: ContactData;
}

export interface HouseholdInterviewStateProps {
  currentPerson: string;
  contactsList: Array<ContactListItem>;
  status: { allRelationsAreLoaded: boolean };
  deals: {
    allDeals: Array<DealInterface>;
    stepTwoSectionIds: Array<number>;
    stepThreeSectionIds: Array<number>;
    stepFourSectionIds: Array<number>;
    stepFiveSectionIds: Array<number>;
    stepSixSectionIds: Array<number>;
    stepSevenSectionIds: Array<number>;
    sortedDealsStepTwo: Array<DealInterface>;
    sortedDealsStepThree: Array<DealInterface>;
    sortedDealsStepFour: Array<DealInterface>;
    sortedDealsStepFive: Array<DealInterface>;
    sortedDealsStepSix: Array<DealInterface>;
    sortedDealsStepSeven: Array<DealInterface>;
  };
  initialStepStates: { stepTwo: Array<StepTwoState> };
  relationsData: Array<RelationData>;
  mainContactData: ContactData;
  currentContactData: ContactData;
  contactRelationsData: Array<ContactRelationData>;
  incompleteContactRelationsData: {
    [kidArray: string]: Array<IncompleteContact>;
    marriedPartnerArray: Array<IncompleteContact>;
  };
  interviewFields: Array<any>;
  stepsValidation: {
    stepTwo: { valid: boolean; invalidFields: Array<any> };
    stepThree: { valid: boolean; invalidFields: Array<any> };
    stepFour: { valid: boolean; invalidFields: Array<any> };
    stepFive: { valid: boolean; invalidFields: Array<any> };
    stepSix: { valid: boolean; invalidFields: Array<any> };
    stepSeven: { valid: boolean; invalidFields: Array<any> };
  };
  resetState: {
    stepTwo: boolean;
    stepThree: boolean;
    stepFour: boolean;
    stepFive: boolean;
    stepSix: boolean;
    stepSeven: boolean;
  };
  savingDataStatus: boolean;
  enableReinitializeForm: boolean;
}

interface LoadContact {
  type: typeof LOAD_CONTACT;
  payload: ContactInterface;
}

interface LoadState {
  type: typeof LOAD_STATE;
  payload: object;
}

interface StartLoadingFormField {
  type: typeof START_LOADING_FORM_FIELD;
  payload: string;
}

interface SuccessLoadedFormField {
  type: typeof SUCCESS_LOADED_FORM_FIELD;
  payload: { title: string; data: object };
}

interface ClearInterviewFieldsArr {
  type: typeof CLEAR_INTERVIEW_FIELDS_ARR;
}

interface LoadContactRelationship {
  type: typeof LOAD_CONTACT_RELATIONSHIP;
  payload: {
    dataId: string;
    id: number;
    group: number;
    familyMemberDE: string;
    relationName: string;
    relationObj: ContactData;
  };
}

interface ChangeCurrentContactData {
  type: typeof CHANGE_CURRENT_CONTACT_DATA;
  payload: ContactData;
}

interface SetStepInvalidElement {
  type: typeof SET_STEP_INVALID_ELEMENT;
  payload: { step: Step; data: object };
}

interface ClearStepInvalidElement {
  type: typeof CLEAR_STEP_INVALID_ELEMENT;
  payload: { step: Step };
}

interface ResetStep {
  type: typeof RESET_STEP;
  payload: { step: Step };
}

interface RemoveRelation {
  type: typeof REMOVE_RELATION;
  payload: { relationName: string };
}

interface ManageDeals {
  type: typeof MANAGE_DEALS;
  payload: DealInterface;
}

interface RemoveDealsByContactId {
  type: typeof REMOVE_DEALS_BY_CONTACT_ID;
  payload: string;
}

interface SetStatus {
  type: typeof SET_STATUS;
  payload: { statusName: string; statusValue: boolean };
}

interface RemoveDealByDealId {
  type: typeof REMOVE_DEAL_BY_DEAL_ID;
  payload: string;
}

interface UpdateRelationStateById {
  type: typeof UPDATE_RELATION_STATE_BY_ID;
  payload: { relationId: string; relationState: SPAUserDataType; updateCurrentContact: boolean };
}

interface SetSavingDataStatus {
  type: typeof SET_SAVING_DATA_STATUS;
  payload: boolean;
}

interface DisableReinitializeForm {
  type: typeof DISABLE_REINITIALIZE_FORM;
  payload: boolean;
}

interface AddIncompleteContact {
  type: typeof ADD_INCOMPLETE_CONTACT;
  payload: IncompleteContact;
}

interface RemoveIncompleteContact {
  type: typeof REMOVE_INCOMPLETE_CONTACT;
  payload: IncompleteContactDataToRemove;
}

interface SetCurrentPerson {
  type: typeof SET_CURRENT_PERSON;
  payload: string;
}

export type HouseholdInterviewActionTypes =
  | SetCurrentPerson
  | RemoveIncompleteContact
  | AddIncompleteContact
  | DisableReinitializeForm
  | SetSavingDataStatus
  | UpdateRelationStateById
  | RemoveDealByDealId
  | SetStatus
  | RemoveDealsByContactId
  | ManageDeals
  | RemoveRelation
  | ResetStep
  | LoadContact
  | LoadState
  | StartLoadingFormField
  | SuccessLoadedFormField
  | ClearInterviewFieldsArr
  | LoadContactRelationship
  | ChangeCurrentContactData
  | SetStepInvalidElement
  | ClearStepInvalidElement;
