const calcCoffee = (eur) => {
    if (eur <= 0) {
        return 0;
    }
    if ((eur / 3.5).toFixed(1) < 1) {
        return 0;
    } else {
        return (eur / 3.5).toFixed(1);
    }
}

const calcDinner = (eur) => {
    if (eur <= 0) {
        return 0;
    }
    if ((eur / 60).toFixed(1) < 1) {
        return 0;
    } else {
        return (eur / 60).toFixed(1);
    }
}

const calcNights = (eur) => {
    if (eur <= 0) {
        return 0;
    }
    if ((eur / 90).toFixed(1) < 1) {
        return 0;
    } else {
        return (eur / 90).toFixed(1);
    }
}


// export
export { calcCoffee, calcDinner, calcNights };