import React from "react";

const ErrorPage = ({ err }) => {
    return (
        <div
            className="errorPage"
            style={{
                color: "#fff",
                textAlign: "center",
                marginTop: "50px",
                padding: "20px",
                backgroundColor: "red"
            }}
        >
            <h1>Es ist ein Fehler aufgetreten.</h1>
            <br />
            <h2>Bitte versuchen Sie es später erneut.</h2>
            <br /><br />
            <h3>
                Wenn der Fehler besteht, melden sie sich bitte bei vertragsengel GmbH <br />
                Das Entwicklerteam wurde bereits über diesen Fehler informiert und meldet sich bei ihnen, sobald das Problem behoben ist.<br />
                Bitte haben sie Gedult.
            </h3>
            <br />
            <h4>Fehlercode: {err}</h4>
        </div>
    );
};

export default ErrorPage;
