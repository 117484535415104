export const convertDbDate = (date: Date | string, yearFirst: boolean = false) => {
  if (!date || "" === date) {
    return "";
  }

  const dt = new Date(date);

  const day = dt.getDate();
  const month = dt.getMonth() + 1;
  const year = dt.getFullYear();

  const current_day = day < 10 ? "0" + day : day;
  const current_month = month < 10 ? "0" + month : month;

  return yearFirst
    ? year + "." + current_month + "." + current_day
    : current_day + "." + current_month + "." + year;
};
