import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import { Button } from "@mui/material";
import * as Yup from "yup";
import { Link } from "react-router-dom";
// @ts-ignore
import MySelectField2 from "../../vertragsUebersicht/VertragsContract/Contracts/ContractsElements/MySelectField2.tsx";
import MyTextField from "../../vertragsUebersicht/VertragsContract/Contracts/ContractsElements/MyTextField";
import { register, clearErrors } from "../../../redux/index";
import Spinner from "../../Helper/ComponentsHelper/Spinner";

const SignupSchema = Yup.object().shape({
  lastname: Yup.string().required("Dieses Feld ist erforderlich"),
  firstname: Yup.string().required("Dieses Feld ist erforderlich"),
  email: Yup.string().email().required("Dieses Feld ist erforderlich"),
  password: Yup.string()
    .required("Dieses Feld ist erforderlich")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Das Passwort muss mindestens 8 Zeichen, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten"
    ),
  registerOptionen: Yup.string().required(),
  berater: Yup.mixed().when("registerOptionen", {
    is: (val: any) => val === "berater",
    then: Yup.string().required("Dieses Feld ist erforderlich"),
    otherwise: Yup.number(),
  }),
  empfehlung: Yup.mixed().when("registerOptionen", {
    is: (val: any) => val === "empfehlung",
    then: Yup.string().required("Dieses Feld ist erforderlich"),
    otherwise: Yup.string(),
  }),
});

interface RegisterInterface {
  lastname: string;
  firstname: string;
  email: string;
  password: string;
  registerOptionen: string;
  empfehlung: string;
  berater: string;
}

interface Props {
  clearErrors: () => void;
  register: (data: RegisterInterface) => void;
  error: string;
}

const Register: React.FC<Props> = ({ register, error, clearErrors }) => {
  const [state, setState] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const backgroundImage = require(`../../../assets/images/logo1.gif`);
  const backgroundImage2 = require(`../../../assets/images/logo.png`);

  useEffect(() => {
    if (error.length !== undefined) {
      setIsLoader(false);
    }
    setState(error);
  }, [error]);

  useEffect(
    () => {
      return () => clearErrors();
    }, // eslint-disable-next-line
    []
  );

  return (
    <div className="loginWrapper">
      <div className="drawTopLeftLine"></div>
      <div className="drawTopRightLine"></div>
      <div className="drawDownLeftLine"></div>
      <div className="drawDownRightLine"></div>
      <div className="drawMiddleLeftLine"></div>
      <div className="drawMiddleRightLine"></div>
      <div className="loginContainer">
        <div
          className="logo1"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        ></div>
        <div
          className="logo2"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        ></div>
        <div className="loginContentContainer">
          <div className="my-text-field">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
              }}
            ></div>
            {isLoader ? (
              <Spinner
                text={{ extraText: true, text: "" }}
                style={{ marginBottom: "20px", flexFlow: "row-reverse" }}
                imgStyle={{
                  extraStyle: true,
                  style: {
                    width: "30px",
                    height: "30px",
                    padding: "5px",
                    background: "white",
                    borderRadius: "50%",
                  },
                }}
              />
            ) : null}
            {state === "Benutzer existiert bereits" ? (
              <h4 className="alert-info">{state}</h4>
            ) : null}
            {state === "Erfolgreich registriert" ? (
              <Fragment>
                <h4 className="alert-info3 fs-18">{state}</h4>
                <h4 className="alert-info3 c-white">
                  Eine Bestätigung mit Ihren Zugangsdaten wird an Ihre E-Mail-Adresse gesendet
                </h4>
                <h4 className="alert-info3 c-white">Sie können sich mit diesen Daten anmelden.</h4>
              </Fragment>
            ) : null}
          </div>
          {state === "Erfolgreich registriert" ? (
            <Link to="/login">
              <Button className="buttonLogin" variant="contained">
                Einloggen
              </Button>
            </Link>
          ) : (
            <Formik
              initialValues={{
                lastname: "",
                firstname: "",
                email: "",
                password: "",
                registerOptionen: "",
                empfehlung: "",
                berater: "",
              }}
              enableReinitialize={true}
              validationSchema={SignupSchema}
              onSubmit={(data, error) => {
                if (data.berater !== "" && data.registerOptionen !== "1") {
                  data.berater = "";
                }
                if (data.empfehlung !== "" && data.registerOptionen !== "5") {
                  data.empfehlung = "";
                }
                setIsLoader(true);
                register(data);
              }}
            >
              {({ values }) => (
                <Form
                  className="register-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "300px",
                    margin: "auto",
                  }}
                >
                  <MyTextField placeholder="Vorname" name="firstname" type="text" label="Vorname" />
                  <MyTextField
                    placeholder="Nachname"
                    name="lastname"
                    type="text"
                    label="Nachname"
                  />
                  <MyTextField placeholder="E-Mail" name="email" type="email" label="E-Mail" />
                  <MyTextField
                    placeholder="Passwort"
                    name="password"
                    type="password"
                    label="Passwort"
                  />
                  <MySelectField2
                    name="registerOptionen"
                    label="Wie hast du uns kennengelernt?"
                    placeholder="Wie hast du uns kennengelernt?"
                    readOnly={false}
                  />
                  {values.registerOptionen === "berater" ? (
                    <MyTextField
                      placeholder="Berater ID"
                      name="berater"
                      type="berater"
                      label="Berater ID"
                    />
                  ) : null}
                  {values.registerOptionen === "empfehlung" ? (
                    <MyTextField
                      placeholder="Wer hat empfohlen?"
                      name="empfehlung"
                      type="empfehlung"
                      label="Wer hat empfohlen?"
                    />
                  ) : null}
                  <Button className="buttonRegister mt-5" type="submit" variant="contained">
                    Registrieren
                  </Button>
                  <Link to="/login">
                    <p className="text">Habe doch schon einen Zugang</p>
                  </Link>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.userReducer.isAuthenticated,
  error: state.errorReducer.msg,
  user: state.userReducer,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ register, clearErrors }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
