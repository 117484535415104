import { getUserSingleQuoteTarifID } from './bitrix-helper';
import axios from 'axios';
const baseUrl = 'https://bitrix.vertragsengel.de/rest/47/gus00mh5gyiunl2g/';

const getProductIDfromQuote = async (quoteID) => {
    const quoteTarifID = await getUserSingleQuoteTarifID(quoteID);

    // Remember to handle potential exceptions in axios.get call
    try {
        const response = await axios.get(baseUrl + 'crm.product.list.json?filter[ID]=' + quoteTarifID);
        return response.data.result[0].NAME;
    } catch (error) {
        // Handle or rethrow the error as needed
        return 'null';
    }
}

export {
    getProductIDfromQuote
};
