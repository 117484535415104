import { DateType } from "@date-io/type";

export const makeCurrentDate = (date: DateType) => {
  let dt = new Date(date),
    current_date = dt.getDate(),
    current_month = dt.getMonth() + 1,
    current_year = dt.getFullYear(),
    current_hrs = dt.getHours(),
    current_mins = dt.getMinutes(),
    current_secs = dt.getSeconds(),
    current_datetime;

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  const newDate = current_date < 10 ? "0" + current_date : current_date;
  const month = current_month < 10 ? "0" + current_month : current_month;
  const hrs = current_hrs < 10 ? "0" + current_hrs : current_hrs;
  const mins = current_mins < 10 ? "0" + current_mins : current_mins;
  const secs = current_secs < 10 ? "0" + current_secs : current_secs;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  current_datetime =
    current_year + "-" + month + "-" + newDate + "T" + hrs + ":" + mins + ":" + secs;

  return current_datetime;
};
