export const initialContactToInterview = {
  ID: "",
  NAME: "",
  LAST_NAME: "",
  BIRTHDATE: "",
  UF_CRM_1604487523: [],
  UF_CRM_1605020723: [],
  UF_CRM_1583875740207: "",
  UF_CRM_1604482419: "",
  UF_CRM_1604482379: "",
  UF_CRM_1662712140: [],
};

export const initialSPAUserData = {
  id: 0,
  ufCrm1_1643978538: 0,
  ufCrm1_1681465108: "",
  ufCrm1_1643982771: "",
  ufCrm1_1643982854: "",
  ufCrm1_1677681158: "",
  ufCrm1_1683034884: "",
  ufCrm1_1643983161: "",
  ufCrm1_1677681193: "",
  ufCrm1_1689843804: 0,
  ufCrm1_1696257773: null,
  ufCrm1_1696257946: null,
  ufCrm1_1696257961: null,
  ufCrm1_1698319061: null,
};
