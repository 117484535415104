export const convertDbDateToTime = (date: Date | string) => {
  if (!date || "" === date) {
    return "";
  }

  const dt = new Date(date);

  const hour = dt.getHours();
  const minute = dt.getMinutes();
  const second = dt.getSeconds();

  const current_hour = hour < 10 ? "0" + hour : hour;
  const current_minute = minute < 10 ? "0" + minute : minute;
  const current_second = second < 10 ? "0" + second : second;

  return current_hour + ":" + current_minute + ":" + current_second;
};
