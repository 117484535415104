import axios from "axios";
import { tokenConfig } from "../index";

export const addSubFolder = (id, name) => (dispatch, getState) => {
  return axios
    .post(`/addSubFolder`, { id: id, name: name }, tokenConfig(getState))
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};

export const diskFolderuploadFile = (data) => (dispatch, getState) => {
  return axios({
    method: "post",
    url: `/diskFolderUploadFile`,
    headers: tokenConfig(getState).headers,
    data: { urlString: data.toString() },
  })
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      return err;
    });
};
