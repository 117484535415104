export const dbNameHelper = (data: string) => {
  if (data === "kosten" || data === "amount") {
    return "UF_CRM_1597416619059";
  } else if (data === "vertragsNameId" || data === "sectionId") {
    return "UF_CRM_5F365448B9021";
  } else if (data === "anbieterId" || data === "companyId") {
    return "UF_CRM_1597406248";
  } else if (data === "isGekuendigt") {
    return "UF_CRM_1597417346297";
  } else if (data === "notizen") {
    return "COMMENTS";
  } else if (data === "userId" || data === "contactId") {
    return "CONTACT_ID";
  } else if (data === "contractBeginning") {
    return "UF_CRM_1597915827842";
  } else if (data === "endOfContract") {
    return "UF_CRM_1597405215572";
  } else if (data === "insuranceNumber" || data === "dealNumber") {
    return "UF_CRM_1597414634517";
  } else if (data === "zahlweise" || data === "paymentValue") {
    return "UF_CRM_1597405738946";
  } else if (data === "customerNumber") {
    return "UF_CRM_1597414622159";
  } else if (data === "insuredContact") {
    return "UF_CRM_1597416212816";
  } else if (data === "monthlyCoverageAmount") {
    return "UF_CRM_1682623393";
  } else if (data === "expirySum") {
    return "UF_CRM_1630570168";
  } else if (data === "bruttoPension") {
    return "UF_CRM_1682623417";
  } else if (data === "interest") {
    return "UF_CRM_1599750851";
  } else if (data === "UF_CRM_1597416619059") {
    return "kosten";
  } else if (data === "UF_CRM_1682623393") {
    return "monthlyCoverageAmount";
  } else if (data === "UF_CRM_5F365448B9021") {
    return "vertragsNameId";
  } else if (data === "UF_CRM_1597406248") {
    return "anbieterId";
  } else if (data === "UF_CRM_1597417346297") {
    return "isGekuendigt";
  } else if (data === "COMMENTS") {
    return "notizen";
  } else if (data === "CONTACT_ID") {
    return "userId";
  } else if (data === "UF_CRM_1597915827842") {
    return "contractBeginning";
  } else if (data === "UF_CRM_1597405215572") {
    return "endOfContract";
  } else if (data === "UF_CRM_1597414634517") {
    return "dealNumber";
  } else if (data === "UF_CRM_1597405738946") {
    return "zahlweise";
  } else if (data === "UF_CRM_1597414622159") {
    return "customerNumber";
  } else if (data === "UF_CRM_1597416212816") {
    return "insuredContact";
  } else if (data === "UF_CRM_1599750851") {
    return "interest";
  }
};
