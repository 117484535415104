export const getURLParam = (url: string, param: string) => {
  let getParam;

  try {
    let newUrl = new URL(url);
    getParam = newUrl.searchParams.get(`${param}`);
  } catch (error) {
    getParam = "";
  }

  return getParam;
};
