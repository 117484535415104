export const translatePaymentNames = (data: string) => {
  if (data === "59") {
    return "Monatlich";
  } else if (data === "61") {
    return "Vierteljährlich";
  } else if (data === "62") {
    return "Halbjährlich";
  } else if (data === "60") {
    return "Jährlich";
  } else if (data === "63") {
    return "Einmalig";
  }
};
