import { GET_ERRORS, CLEAR_ERRORS } from "./errorTypes";

export const returnErrors = (msg: object, status: string, id: string | null = null) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status, id },
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
