export const GET_SECTIONS = "GET_SECTIONS";
export const SECTIONS_LOADING = "SECTIONS_LOADING";
export const GET_COMPANIES = "GET_COMPANIES";
export const COMPANIES_LOADING = "COMPANIES_LOADING";
export const ADD_PRODUCT_SECTION_LIST = "ADD_PRODUCT_SECTION_LIST";

export interface DealsArray {
  id: number;
  sparteId: number;
  text: string;
  selectId: string;
  isOpen: boolean;
  vertragsList: [];
  vertragsList2: [];
}

export interface SectionListElement {
  DEF: string;
  ID: string;
  SORT: string;
  VALUE: string;
}

export interface Companies {
  companyId: number;
  sectionId: number;
}

export interface SectionList {
  CATALOG_ID: string;
  CODE: string | null;
  ID: string;
  NAME: string;
  SECTION_ID: string | null;
  XML_ID: string | null;
}

export interface SectionElement {
  id: number;
  sparteId: number;
  text: string;
  selectId: string;
  isOpen: boolean;
  productSectionId: number;
  vertragsList: Array<CompanyElement>;
  vertragsList2: Array<CompanyElement>;
}

export interface CompanyElement {
  id: string;
  title: string;
}

export interface CompaniesArrayStateType {
  vertragsList: Array<SectionElement>;
  vertragsGesellschaften: Array<Companies>;
  productSectionList: Array<SectionList>;
  loading: boolean;
}

interface GetSections {
  type: typeof GET_SECTIONS;
  payload: Array<DealsArray>;
}

interface SectionsLoading {
  type: typeof SECTIONS_LOADING;
}

interface GetCompanies {
  type: typeof GET_COMPANIES;
  payload: Array<Companies>;
}

interface CompaniesLoading {
  type: typeof COMPANIES_LOADING;
}

interface AddProductSectionList {
  type: typeof ADD_PRODUCT_SECTION_LIST;
  payload: Array<SectionList>;
}

export type DealsArrayActionTypes =
  | GetSections
  | SectionsLoading
  | GetCompanies
  | CompaniesLoading
  | AddProductSectionList;
