import { styled } from "@mui/system";
import styles from "../../../mainSCSS/_variables.module.scss";
import {
  TextField,
  Switch,
  Tab,
  Backdrop,
  Select,
  Button,
  DialogTitle,
  Tabs,
  Slider,
  Paper,
  ListItemText,
  MenuItem,
  Typography,
  Autocomplete,
} from "@mui/material";
import { TabList } from "@mui/lab";

export const StyledSwitch = styled(Switch)({
  "& .MuiButtonBase-root": {
    color: styles.lila + " !important",
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: styles.lila + " !important",
  },
});

export const StyledSelectInputLabel = styled("div")({
  label: {
    fontFamily: "PoppinsRegular !important",
  },
  "label.MuiInputLabel-outlined": {
    fontSize: "13px",
    transform: "translate(14px, 12px) scale(1) !important",
  },
  "label.MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -8px) scale(0.65) !important",
  },
});

export const StyledBackdrop = styled(Backdrop)({
  zIndex: 100000000,
  color: "#fff",
});

export const StyledSelect = styled(Select)((props) => ({
  minWidth: props?.style?.minWidth ? props?.style?.minWidth : "none",
  height: "40px !important",
  backgroundColor: props?.style?.backgroundColor
    ? props?.style?.backgroundColor + " !important"
    : styles?.backgroundGrey + " !important",
  border: props?.style?.border ? styles[props.style.border] : null,
  "& .MuiSelect-select": {
    "& .MuiButtonBase-root": {
      fontFamily: "PoppinsRegular !important",
      fontSize: "14px",
      padding: "0px !important",
    },
  },
}));

export const StyledMenuItem = styled(MenuItem)((props) => ({
  color: styles.textColorGrey,
  fontSize: "14px",
}));

export const Container = styled("div")({
  maxWidth: "1100px",
  margin: "60px auto",
  position: "relative",
});

export const MyTab = styled(Tab)((props) => ({
  textTransform: "none",
  fontWeight: 700,
  color:
    "lila" === props.color ? styles.lila + " !important" : styles.textColorGrey + " !important",
  fontFamily: "PoppinsRegular !important",
  fontSize: "17px",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
}));

export const MyTabs = styled(Tabs)((props) => ({
  border: "none",
  boxShadow: "unset !important",
  "& .MuiButtonBase-root": {
    borderRight: "none",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
}));

export const MyTabList = styled(TabList)((props) => ({
  border: `2px solid ${styles.lila}`,
  "& .MuiTabs-indicator": {
    backgroundColor: styles.lilaBackgroundLight,
    height: "100%",
  },
}));

export const MyBackdrop = styled(Backdrop)({
  zIndex: 100000,
  color: "#fff",
});

export const StyledListItemText = styled(ListItemText)((props) => ({
  "& .MuiTypography-root": {
    fontSize: props.style?.fontSize ? props.style.fontSize : null,
    textAlign: props.style?.textAlign ? props.style.textAlign : null,
    fontWeight: props.style?.fontWeight ? props.style.fontWeight : null,
  },
}));

export const StyledTypography = styled(Typography)((props) => ({
  display: props.style?.display ? props.style.display : null,
  justifyContent: props.style?.justifyContent ? props.style.justifyContent : null,
  fontSize: props.style?.fontSize ? props.style.fontSize : null,
}));

export const StyledTextField = styled(TextField)((props) => ({
  maxWidth: props?.style?.maxWidth ? props?.style?.maxWidth : "none",
  width: "100%",
  marginBottom: props?.marginBottom ? props.marginBottom : "20px",
  marginRight: props?.style?.marginRight ? props.style.marginRight : "unset",
  ".MuiInputBase-root": {
    height: "textarea" === props.type ? "auto !important" : "40px !important",
    paddingLeft: "textarea" === props.type ? "14px" : "0px !important",
    textarea: {
      padding: "0px !important",
    },
    backgroundColor: props?.style?.backgroundColor
      ? props?.style?.backgroundColor + "!important"
      : styles?.backgroundGrey + "!important",
    border: props?.style?.border ? styles[props.style.border] : null,
  },
  ".MuiInputBase-input": {
    fontFamily: "PoppinsRegular !important",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "PoppinsRegular !important",
    fontSize: "13px",
    color: "black",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "70%",
  },
  "& .Mui-focused, .MuiFormLabel-filled": {
    width: "100%",
  },
  ".MuiInputLabel-outlined": {
    transform: "translate(14px, 11px) scale(1) !important",
  },
  ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75) !important",
  },
  ".MuiFormHelperText-root": {
    color: styles.errorTextColor + " !important",
  },
  ".Mui-disabled": {
    color: styles.textColorGrey + " !important",
  },
}));

export const StyledAutocomplete = styled(Autocomplete)((props) => ({
  ".MuiFormControl-root": {
    ".MuiInputBase-root": {
      height: "40px !important",
      backgroundColor: styles.backgroundGrey + " !important",
      ".MuiInputBase-input": {
        paddingLeft: "14px",
      },
    },
  },
}));

export const StyledButton = styled(Button)((props) => ({
  cursor: "pointer",
  borderRadius: props?.style?.borderRadius ? props?.style?.borderRadius : null,
  color:
    "lila" === props?.style?.color
      ? styles.lila
      : "white" === props?.style?.color
      ? "white"
      : "textColorGrey" === props?.style?.color
      ? styles.textColorGrey + " !important"
      : "black",
  backgroundColor:
    "lila" === props?.style?.backgroundColor
      ? styles.lila + " !important"
      : "green" === props?.style?.backgroundColor
      ? styles.green
      : "greenColor" === props?.style?.backgroundColor
      ? styles.greenColor
      : "redColor" === props?.style?.backgroundColor
      ? styles.backgroundRed
      : "blueColor" === props?.style?.backgroundColor
      ? styles.backgroundBlue
      : null,
  "&:hover": {
    backgroundColor:
      "lila" === props?.style?.backgroundColor
        ? styles.lila + " !important"
        : props.style && "green" === props.style.backgroundColor
        ? styles.green
        : props.style && "greenColor" === props.style.backgroundColor
        ? styles.greenColor
        : "redColor" === props?.style?.backgroundColor
        ? styles.backgroundRed
        : "blueColor" === props?.style?.backgroundColor
        ? styles.backgroundBlue
        : null,
    filter: props.style && props.style.backgroundColor ? "brightness(95%)" : null,
    boxShadow: styles.boxShadowLight,
  },
  textTransform: "none",
  fontWeight: props.fontWeight ? props.fontWeight : null,
  width: props?.style?.width && props.style.width,
  margin: props?.style?.margin && props.style.margin,
  fontFamily: "PoppinsRegular !important",
  border: props?.style?.border ? styles[props.style.border] : null,
}));

export const StyledDialogTitle = styled(DialogTitle)({
  fontWeight: 700,
});

export const StyledPaper = styled(Paper)((props) => ({
  "& .MuiPaper-root": {
    color: props.style && "#fff" === props.style?.color ? "#fff !important" : null,
  },
  backgroundColor:
    props.style && "lila" === props.style.backgroundColor ? styles.lila + " !important" : null,
}));

export const StyledSlider = styled(Slider)({
  color: styles.lila,
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: styles.lila,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(315deg) scale(1)",
      width: "40px",
      height: "20px",
      color: styles.lila,
      backgroundColor: "white",
      fontWeight: 800,
      top: "60px",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
