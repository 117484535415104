import React from 'react';

import {ReactComponent as FinanzenIcon} from '../img/svg/icons/app_icons/Finanzen.svg';
import {ReactComponent as GasIcon} from '../img/svg/icons/app_icons/Gas.svg';
import {ReactComponent as GesundheitIcon} from '../img/svg/icons/app_icons/Gesundheit.svg';
import {ReactComponent as MediaIcon} from '../img/svg/icons/app_icons/Media.svg';
import {ReactComponent as MobilIcon} from '../img/svg/icons/app_icons/Mobil.svg';
import {ReactComponent as StromIcon} from '../img/svg/icons/app_icons/Strom.svg';
import {ReactComponent as InternetIcon} from '../img/svg/icons/app_icons/Internet.svg';

// 

// const sparte_check = [
//     124, // DSL
//     122, // Strom
//     123, // Gas
//     186, // Festnetz
//     121, // Mobilfunk
//     198, // Datentarif
//     125 // Pay-TV
// ];

function SparteIcon(props) {
    return (
        <div className="sparte-icon">
            {props.sparte === 122 ? <StromIcon className='sparteIcon' /> : null}
            {props.sparte === 123 ? <GasIcon className='sparteIcon' /> : null}
            {props.sparte === 121 ? <MobilIcon className='sparteIcon' /> : null}
            {props.sparte === 198 ? <MobilIcon className='sparteIcon' /> : null}
            {props.sparte === 124 ? <InternetIcon className='sparteIcon' /> : null}
            {props.sparte === 125 ? <MediaIcon className='sparteIcon' /> : null}
            {props.sparte === 186 ? <MediaIcon className='sparteIcon' /> : null}

            {props.sparte === 999 ? <FinanzenIcon className='sparteIcon' /> : null}
            {props.sparte === 998 ? <GesundheitIcon className='sparteIcon' /> : null}
        </div>
    );
}

export default SparteIcon;