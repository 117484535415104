// eslint-disable-next-line
/* import {
  getSingleUserContract,
  getSingleUserQuote,
  getQuoteSavingsAmount,
  getContractMonthlyPaymentAmount,
} from "./bitrix-helper"; */
import axios from "axios";

const baseUrl = "https://bitrix.vertragsengel.de/rest/1/0ri0va83oo73pmyf/";

const calcSavings = (contractEur = 0, quoteEur) => {
  try {
    var amount = parseFloat(contractEur) - parseFloat(quoteEur);
    amount = amount.toFixed(2);

    if (amount.split(".")[1] > 2) {
      amount = amount + "0";
    }

    amount = parseFloat(amount);

    var number = amount.toString();
    if (number.split(".")[1] > 2) {
      number = number + "0";
    }

    // check the length of the string of amount
    if (number.length < 5) {
      number = number + "0;";
    }

    if (parseFloat(amount) < 0) {
      return `Sie bezahlen ${Math.abs(amount).toString()} € monatlich mehr!`;
    } else if (parseFloat(amount) === 0) {
      return "Der Preis ändert sich nicht!";
    } else {
      return `Sie sparen ${amount.toString()} €/Monat`;
    }
  } catch (error) {
    return "0.00";
  }
};

const calcSavingsPercentage = (contractEur = 0, quoteEur) => {
  try {
    var contractAmount = parseFloat(contractEur);
    var quoteAmount = parseFloat(quoteEur);

    // Calculate the percentage of money saved
    var savingsPercentage = ((contractAmount - quoteAmount) / contractAmount) * 100;
    savingsPercentage = parseInt(savingsPercentage.toFixed(2));

    // Check if the savingsPercentage is negative (indicating higher cost)
    if (savingsPercentage < 0) {
      return `${Math.abs(savingsPercentage)}% teurer!`;
    } else if (savingsPercentage === "0.00") {
      return "Gleicher Preis!";
    } else {
      return `${savingsPercentage}% sparen!`;
    }
  } catch (error) {
    return "0.00";
  }
};

const calcSavingsInt = (contractEur, quoteEur) => {
  try {
    return parseFloat(contractEur) - parseFloat(quoteEur);
  } catch (error) {
    return "0.00";
  }
};

const updateQuoteStatus = async (quoteID, newStatus) => {
  try {
    const data = {
      id: quoteID,
      fields: {
        STATUS_ID: newStatus,
      },
    };

    const url = `${baseUrl}crm.quote.update.json`;

    const response = await axios.post(url, data); // Use await here

    return response.data.result;
  } catch (error) {
    throw error;
  }
};

const updateContractData = async (contractID, newDataUF, newDataValue) => {
  try {
    const url = `${baseUrl}crm.deal.update.json?ID=${contractID}&fields[${newDataUF}]=${newDataValue}`;

    const response = await axios.post(url);

    console.log(response);
    return response.data.result;
  } catch (error) {
    throw error;
  }
};

const sendMissingDataMail = async (data_, quote, name) => {
  try {
    const _data = data_;
    const missingData = _data.filter(
      (item) => item.value === null || item.value === undefined || item.value === ""
    );
    const missingDataJSON = JSON.stringify(missingData);
    const quoteJSON = JSON.stringify(quote);
    const nameJSON = JSON.stringify(name);

    // if data is empty, cancel
    if (missingDataJSON.length === 0) {
      return;
    }

    // prepare missingDataJSON and quoteJSON to be used as JSON data during a POST request
    const data = {
      missingData: missingDataJSON,
      quote: quoteJSON,
      name: nameJSON,
    };

    console.log("DATA", data);

    const url = `https://yuniiworks.de:5038/veMissingDataMail`;

    const response = await axios.post(url, data);
    console.warn(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  calcSavings,
  calcSavingsInt,
  updateQuoteStatus,
  updateContractData,
  calcSavingsPercentage,
  sendMissingDataMail,
};
