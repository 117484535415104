import React from "react";

interface SpinnerProps {
  style: React.CSSProperties;
  text: { extraText: boolean; text: string };
  imgStyle: { extraStyle: boolean; style: React.CSSProperties };
}

const Spinner = ({
  style,
  text = { extraText: false, text: "" },
  imgStyle = { extraStyle: false, style: {} },
}: SpinnerProps) => {
  const gesellschaftsImages = require(`../../../assets/images/logo1.gif`);

  return (
    <div className="spinner-container" style={style}>
      <img
        alt="icon"
        src={gesellschaftsImages}
        style={!imgStyle.extraStyle ? {} : imgStyle.style}
        className="loadingImage"
      ></img>
      <p>{!text.extraText ? "Wird geladen. . ." : text.text}</p>
    </div>
  );
};

export default Spinner;
