import axios from "axios";
import { dbNameHelper } from "../../components/Helper/BitrixHelper/bitrixDatabaseHelper";
import { DealInterface } from "../../components/Helper/Interfaces/dealInterface";
import {
  ContactInterface,
  ContactMultipleRelations,
  SPAUserDataType,
} from "../../components/Helper/Interfaces/userInterface";
import { showProcessAlert, showProcessSpinner } from "../appSettings/appSettingsActions";
import { HANDLE_PROCESS_SPINNER } from "../appSettings/appSettingsTypes";
import { IRootState, TypedDispatch } from "../store";
import { tokenConfig } from "../users/userActions";
import {
  LOAD_STATE,
  LOAD_CONTACT,
  START_LOADING_FORM_FIELD,
  SUCCESS_LOADED_FORM_FIELD,
  CLEAR_INTERVIEW_FIELDS_ARR,
  LOAD_CONTACT_RELATIONSHIP,
  CHANGE_CURRENT_CONTACT_DATA,
  SET_STEP_INVALID_ELEMENT,
  CLEAR_STEP_INVALID_ELEMENT,
  RESET_STEP,
  REMOVE_RELATION,
  MANAGE_DEALS,
  REMOVE_DEALS_BY_CONTACT_ID,
  UPDATE_RELATION_STATE_BY_ID,
  REMOVE_DEAL_BY_DEAL_ID,
  SET_STATUS,
  SET_SAVING_DATA_STATUS,
  DISABLE_REINITIALIZE_FORM,
  ADD_INCOMPLETE_CONTACT,
  REMOVE_INCOMPLETE_CONTACT,
  SET_CURRENT_PERSON,
  IncompleteContact,
  RelationData,
  ContactRelationData,
  SaveStepProps,
  ExistingDeal,
  Step,
  StepIsValidDataProp,
  SaveDetailFieldsProp,
  SaveDataInterviewValueType,
  RelationContactsAxiosResType,
  IncompleteContactDataToRemove,
} from "./householdInterviewTypes";
import { makeCurrentDate } from "../../components/Helper/DateHelper/makeCurrentDate";

export const loadStateInterview =
  (contactId: string, shouldReturn: boolean = false) =>
  (dispatch: TypedDispatch, getState: () => IRootState) => {
    return axios({
      method: "post",
      url: `/loadStateInterview`,
      headers: tokenConfig(getState).headers,
      data: { contactId: contactId },
    })
      .then((res) => {
        if (shouldReturn) return res;
        dispatch({ type: LOAD_STATE, payload: res.data.result.items[0] });
      })
      .catch((err) => shouldReturn && err);
  };

export const loadContactInterview =
  (contactId: string, shouldReturn: boolean = false) =>
  (dispatch: TypedDispatch, getState: () => IRootState) => {
    return axios({
      method: "post",
      url: `/loadContactInterview`,
      headers: tokenConfig(getState).headers,
      data: { contactId: contactId },
    })
      .then((res) => {
        if (shouldReturn) return res;
        dispatch({ type: LOAD_CONTACT, payload: res.data.result });
        dispatch(getDealsListByUserId(res.data.result.ID));
        dispatch(getAllRelationsFromMainContact(res.data.result));
      })
      .catch((err) => {
        if (shouldReturn) return err;
      });
  };

export const getAllRelationsFromMainContact =
  (data: ContactInterface) => async (dispatch: TypedDispatch, getState: () => IRootState) => {
    const relationsData = getState().householdInterviewReducer.relationsData;
    const axiosArray: Array<{ id: string; relationName: string }> = [];

    const pushNewRequest = (id: string, relationName: string) => {
      axiosArray.push({ id: id, relationName: relationName });
    };

    if (data) {
      relationsData.forEach((relation: RelationData) => {
        if (data[relation.dataId as keyof ContactInterface]?.length) {
          if (relation.multiple) {
            data[relation.dataId as keyof ContactMultipleRelations].forEach((id, i) => {
              pushNewRequest(id, relation.name + "-" + i);
            });
          } else {
            pushNewRequest(
              data[relation.dataId as keyof ContactInterface] as string,
              relation.name
            );
          }
        }
      });
    }

    try {
      const relationContacts = await axios({
        method: "post",
        url: `/getAllRelationsFromMainContact`,
        headers: tokenConfig(getState).headers,
        data: { axiosArray: axiosArray },
      });

      const relationPromises = await relationContacts.data.map(
        async (contact: { config: RelationContactsAxiosResType; result: ContactInterface }) => {
          const SPAUserData = await dispatch(loadStateInterview(contact?.result?.ID, true));
          const relationName = contact.config?.relationName;
          const relationArrIndex = relationsData.findIndex(
            (contact: RelationData) => relationName.split("-")[0] === contact.name
          );

          dispatch({
            type: LOAD_CONTACT_RELATIONSHIP,
            payload: {
              dataId: relationsData[relationArrIndex].dataId,
              id: relationsData[relationArrIndex].id,
              group: relationsData[relationArrIndex].group,
              familyMemberDE: relationsData[relationArrIndex].familyMemberDE,
              relationName: relationName,
              relationObj: {
                contactToInterview: contact?.result,
                SPAUserData: SPAUserData.data?.result?.items[0],
              },
            },
          });

          await dispatch(getDealsListByUserId(contact?.result?.ID));
        }
      );

      await Promise.all(relationPromises);

      dispatch(setStatus("allRelationsAreLoaded", true));
    } catch (e) {
      console.log(e);
    }
  };

export const refreshRelation =
  (relationId: string) => async (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(showProcessSpinner(true));

    try {
      const relationState = await dispatch(loadStateInterview(relationId, true));
      dispatch({
        type: UPDATE_RELATION_STATE_BY_ID,
        payload: {
          relationId: relationId,
          relationState: relationState.data.result.items[0],
          updateCurrentContact: true,
        },
      });
      if (!relationState.data.result.items) {
        dispatch(
          showProcessAlert(
            "info-header1",
            "Daten wurden nicht geladen, bitte versuchen Sie es später erneut"
          )
        );
      }
    } catch (e) {
      dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"));
    }
    dispatch(showProcessSpinner(false));
  };

export const buildFormFields =
  (entityId: string) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch({ type: START_LOADING_FORM_FIELD, payload: entityId });
    return axios({
      method: "post",
      url: `/buildFormFields`,
      headers: tokenConfig(getState).headers,
      data: { entityId: entityId },
    })
      .then((res) => {
        dispatch({
          type: SUCCESS_LOADED_FORM_FIELD,
          payload: { title: entityId, data: res.data.result },
        });
        return res.data.result;
      })
      .catch((err) => {
        return err;
      });
  };

export const buildContactUF =
  (fieldName: string) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch({ type: START_LOADING_FORM_FIELD, payload: fieldName });

    return axios({
      method: "post",
      url: `/buildContactUF`,
      headers: tokenConfig(getState).headers,
      data: { fieldName: fieldName },
    })
      .then((res) => {
        dispatch({
          type: SUCCESS_LOADED_FORM_FIELD,
          payload: { title: fieldName, data: res.data.result },
        });
        return res.data.result;
      })
      .catch((err) => {
        return err;
      });
  };

export const getAllSmartProcessFields =
  (processId: string) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch({ type: START_LOADING_FORM_FIELD, payload: "Process:" + processId });

    return axios({
      method: "post",
      url: `/getAllSmartProcessFields`,
      headers: tokenConfig(getState).headers,
      data: { processId: processId },
    })
      .then((res) => {
        dispatch({
          type: SUCCESS_LOADED_FORM_FIELD,
          payload: { title: "Process:" + processId, data: res.data.result },
        });
        return res.data.result;
      })
      .catch((err) => {
        return err;
      });
  };

interface ContactDataQuery {
  [key: string]: any;
}

export const saveDataInterview =
  (spaId: number, contactId: string, values: SaveDataInterviewValueType, currentPerson: string) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    if (!values) {
      dispatch({ payload: false, type: SET_SAVING_DATA_STATUS });
      return;
    }

    let spaUserDataQuery = "";
    let contactDataQuery: ContactDataQuery = {};

    const handleExtraFields = (fieldType: string, key: string) => {
      const fieldId = values[key + "__ID"];

      contactDataQuery[fieldType] = contactDataQuery[fieldType]?.length
        ? contactDataQuery[fieldType]
        : [];

      contactDataQuery[fieldType].push({
        ...{ VALUE: values[key], VALUE_TYPE: key.split("__")[1] },
        ...(fieldId ? { ID: fieldId } : {}),
      });
    };

    for (const key in values) {
      if (key.includes("SPAUserData_")) {
        spaUserDataQuery += `&fields[${key.split("__")[0].replace("SPAUserData_", "")}]=${
          isNaN(parseInt(values[key] as string)) ? values[key] : parseInt(values[key] as string)
        }`;
      }

      if (key.includes("contact_")) {
        let fieldType = key.split("__")[0].replace("contact_", "");

        if ("EMAIL" === fieldType) {
          if (!key.includes("ID")) {
            handleExtraFields(fieldType, key);
          }
        } else if ("PHONE" === fieldType) {
          if (!key.includes("ID")) {
            handleExtraFields(fieldType, key);
          }
        } else if ("BIRTHDATE" === fieldType) {
          contactDataQuery[fieldType] =
            values[key] && !isNaN(new Date(values[key] as string).getTime())
              ? new Date(values[key] as string).toISOString()
              : "";
        } else {
          if (Array.isArray(values[key]) && (values[key] as Array<string | number>).length) {
            contactDataQuery[fieldType] = [];
            for (var i = 0; i < (values[key] as Array<string | number>).length || 0; i++) {
              contactDataQuery[fieldType].push((values[key] as Array<string | number>)[i]);
            }
          } else {
            contactDataQuery[fieldType] = values[key];
          }
        }
      }
    }

    await axios({
      method: "post",
      url: `/saveDataInterview`,
      headers: tokenConfig(getState).headers,
      data: {
        spaId: spaId,
        spaUserDataQuery: spaUserDataQuery,
        contactId: contactId,
        contactDataQuery: contactDataQuery,
      },
    })
      .then(async (res) => {
        const updatedOnlyContact = !res?.data[1]?.item;
        await dispatch(changePhaseFromGeneralDetail(spaId));
        const loadUserData = await dispatch(loadContactInterview(contactId, true));
        if ("me" === currentPerson) {
          dispatch({ type: LOAD_CONTACT, payload: loadUserData.data.result });
          !updatedOnlyContact && dispatch({ type: LOAD_STATE, payload: res.data[1].item });
        } else {
          const householdInterview = getState().householdInterviewReducer;
          const relationData = householdInterview.contactRelationsData.filter(
            (data: ContactRelationData) => data.name === currentPerson
          )[0];

          dispatch({
            type: LOAD_CONTACT_RELATIONSHIP,
            payload: {
              dataId: relationData.dataId,
              id: relationData.id,
              group: relationData.group,
              familyMemberDE: relationData.familyMemberDE,
              relationName: currentPerson,
              relationObj: {
                contactToInterview: loadUserData.data.result,
                SPAUserData: updatedOnlyContact
                  ? householdInterview.currentContactData.SPAUserData
                  : res.data[1].item,
              },
            },
          });
        }
        dispatch({ payload: false, type: SET_SAVING_DATA_STATUS });
      })
      .catch((err) => console.log(err));

    dispatch({ type: HANDLE_PROCESS_SPINNER, payload: false });
  };

export const changePhaseFromGeneralDetail =
  (spaId: number) => async (dispatch: TypedDispatch, getState: () => IRootState) => {
    try {
      const response = await axios({
        method: "post",
        url: `/changePhaseFromGeneralDetail`,
        headers: tokenConfig(getState).headers,
        data: { spaId: spaId },
      });

      return response;
    } catch (e) {
      return false;
    }
  };

export const saveDetailFields =
  (data: SaveDetailFieldsProp, spaId: number, contact: string, updateCurrentContact: boolean) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    let spaUserDataQuery = "";
    Object.keys(data).forEach((key, index) => {
      if (Array.isArray(data[key]) && (data[key] as Array<string>).length) {
        (data[key] as Array<string>).map(
          (el, i) => (spaUserDataQuery += `&fields[${key}][${i}]=${el}`)
        );
      } else {
        spaUserDataQuery += `&fields[${key}]=${data[key]}`;
      }
    });

    try {
      if (!spaId) throw new Error();

      const response = await axios({
        method: "post",
        url: `/saveDetailFields`,
        headers: tokenConfig(getState).headers,
        data: { spaId: spaId, spaUserDataQuery: spaUserDataQuery },
      });

      await dispatch(changePhaseFromGeneralDetail(spaId));

      if (response.data && "me" === contact) {
        dispatch({ type: LOAD_STATE, payload: response.data.result.item });
      } else {
        dispatch({
          type: UPDATE_RELATION_STATE_BY_ID,
          payload: {
            relationId: response.data.result.item.contactId,
            relationState: response.data.result.item,
            updateCurrentContact: updateCurrentContact,
          },
        });
      }
      dispatch({ payload: false, type: SET_SAVING_DATA_STATUS });
    } catch (e) {
      dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"));
    }
  };

export const changeCurrentUserData =
  (id: string, familyMember: string) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    const householdInterview = getState().householdInterviewReducer;

    if (id === householdInterview.mainContactData.contactToInterview.ID) {
      dispatch({
        type: CHANGE_CURRENT_CONTACT_DATA,
        payload: {
          contactToInterview: householdInterview.mainContactData.contactToInterview,
          SPAUserData: householdInterview.mainContactData.SPAUserData,
        },
      });
    } else {
      const relationData = householdInterview.contactRelationsData.filter(
        (data: ContactRelationData) => data.name === familyMember
      )[0];
      dispatch({
        type: CHANGE_CURRENT_CONTACT_DATA,
        payload: {
          contactToInterview: relationData.data.contactToInterview,
          SPAUserData: relationData.data.SPAUserData,
        },
      });
    }
    dispatch({ payload: familyMember, type: SET_CURRENT_PERSON });
  };

export const stepIsValid =
  (stepIsValidData: StepIsValidDataProp, step: Step) =>
  (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(clearStepInvalidElement(step));
    let i = 0;
    if (stepIsValidData.dealsList.length) {
      stepIsValidData.dealsList.forEach((deal) => {
        if (!deal.contactId || !deal.companyId) {
          i++;
          dispatch({
            type: SET_STEP_INVALID_ELEMENT,
            payload: { step: step, data: deal },
          });
        }
      });
    }

    return i ? false : true;
  };

export const clearStepInvalidElement =
  (step: string) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch({ type: CLEAR_STEP_INVALID_ELEMENT, payload: { step: step } });
  };

export const saveStep =
  (data: SaveStepProps, step?: string) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(showProcessSpinner(true));

    if (
      data.existingDeals &&
      JSON.stringify(data.existingDeals) !== JSON.stringify(data.initialExistingDeals)
    ) {
      const updatedDeal: Array<{ urlSearchParams: URLSearchParams; existingDeal: ExistingDeal }> =
        [];
      data.existingDeals.forEach((existingDeal, i) => {
        if (
          data.initialExistingDeals &&
          existingDeal.dealId === data.initialExistingDeals[i].dealId &&
          JSON.stringify(existingDeal) !== JSON.stringify(data.initialExistingDeals[i])
        ) {
          const editedExistingDeal = new URLSearchParams();
          editedExistingDeal.append(`id`, `${existingDeal.dealId}`);

          for (let [key, value] of Object.entries(existingDeal)) {
            const formattedKey = dbNameHelper(key);
            if (formattedKey) {
              if (key === "contractBeginning" || key === "endOfContract") {
                value = makeCurrentDate(value);
              }

              editedExistingDeal.append(`fields[${formattedKey}]`, `${value}`);
            }
          }

          updatedDeal.push({ urlSearchParams: editedExistingDeal, existingDeal: existingDeal });
        }
      });
      try {
        await axios
          .all(
            updatedDeal.map((deal, i) =>
              axios.post(
                `/updateDeal`,
                {
                  urlString: deal.urlSearchParams.toString(),
                },
                tokenConfig(getState)
              )
            )
          )
          .then(async (response) => {
            const deals = await axios.all(
              updatedDeal.map((deal) =>
                axios.post(
                  `/getDealById`,
                  { dealId: deal.existingDeal.dealId },
                  tokenConfig(getState)
                )
              )
            );

            deals.forEach((deal) => {
              dispatch(deleteInterviewDealByDealId(deal.data.result.ID));
              dispatch({ payload: deal.data.result, type: MANAGE_DEALS });
            });

            dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
          })
          .catch((err) =>
            dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"))
          );
      } catch (e) {
        console.log(e);
      }
    }

    if (data.dealsList && data.dealsList.length) {
      const dealsArrToPost: Array<URLSearchParams> = [];

      data.dealsList.forEach((deal) => {
        const dealData = new URLSearchParams();

        dealData.append("fields[TYPE_ID]", "COMPLEX");
        dealData.append("fields[CATEGORY_ID]", "1");

        for (const [key, value] of Object.entries(deal)) {
          if (dbNameHelper(key) !== undefined) {
            dealData.append(`fields[${dbNameHelper(key)}]`, `${value}`);
          }
        }

        dealsArrToPost.push(dealData);
      });

      const addedDeals = [];
      const updatedDeals = [];

      try {
        for (let i = 0; i < dealsArrToPost.length; i++) {
          const response = await axios.post(
            `/addDeal`,
            {
              urlString: dealsArrToPost[i].toString(),
            },
            tokenConfig(getState)
          );
          addedDeals.push(response);
        }

        for (let i = 0; i < addedDeals.length; i++) {
          const response = await axios.post(
            `/getDealById`,
            { dealId: addedDeals[i].data.result },
            tokenConfig(getState)
          );

          updatedDeals.push(response);
        }

        updatedDeals.forEach((updatedDeal) => {
          dispatch({ payload: updatedDeal.data.result, type: MANAGE_DEALS });
        });

        dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
      } catch (e) {
        console.log(e);
        dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"));
      }
    }

    dispatch(showProcessSpinner(false));
    step && dispatch(resetStep(step));
  };

export const removeRelation =
  (relationToDel: ContactRelationData) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(showProcessSpinner(true));
    const householdInterview = getState().householdInterviewReducer;
    const removedRelation = householdInterview.contactRelationsData.filter(
      (relation: ContactRelationData) => relationToDel.name === relation.name
    );

    let queryRequest = "";
    if (
      Array.isArray(
        householdInterview.mainContactData.contactToInterview[
          relationToDel.dataId as keyof ContactInterface
        ]
      )
    ) {
      const idIndex = householdInterview.mainContactData.contactToInterview[
        relationToDel.dataId as keyof ContactMultipleRelations
      ].indexOf(removedRelation[0].data.contactToInterview.ID);

      householdInterview.mainContactData.contactToInterview[
        relationToDel.dataId as keyof ContactMultipleRelations
      ].splice(idIndex, 1);

      queryRequest = !householdInterview.mainContactData.contactToInterview[
        relationToDel.dataId as keyof ContactMultipleRelations
      ].length
        ? `&fields[${relationToDel.dataId}]=`
        : "";

      householdInterview.mainContactData.contactToInterview[
        relationToDel.dataId as keyof ContactMultipleRelations
      ].forEach((id: string, i: number) => {
        queryRequest += `&fields[${relationToDel.dataId}][${i}]=${id}`;
      });
    } else {
      queryRequest += `&fields[${relationToDel.dataId}]=`;
    }

    await axios({
      method: "post",
      url: `/removeRelation`,
      headers: tokenConfig(getState).headers,
      data: {
        contactId: householdInterview.mainContactData.contactToInterview.ID,
        queryRequest: queryRequest,
      },
    })
      .then((res) => {
        dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
        if (
          householdInterview.currentContactData.contactToInterview.ID ===
          removedRelation[0].data.contactToInterview.ID
        ) {
          dispatch({
            type: CHANGE_CURRENT_CONTACT_DATA,
            payload: {
              contactToInterview: householdInterview.mainContactData.contactToInterview,
              SPAUserData: householdInterview.mainContactData.SPAUserData,
            },
          });
        }
        dispatch({ payload: "me", type: SET_CURRENT_PERSON });
        dispatch({ type: REMOVE_RELATION, payload: { relationName: relationToDel.name } });
        dispatch(removeDealsByUserId(relationToDel.data.contactToInterview.ID));
      })
      .catch((err) => {
        dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"));
      });

    dispatch(showProcessSpinner(false));
  };

export const addRelation =
  (relationName: string, contactId: string) =>
  async (dispatch: TypedDispatch, getState: () => IRootState) => {
    dispatch(showProcessSpinner(true));
    const householdInterview = getState().householdInterviewReducer;
    const relationsData = householdInterview.relationsData;
    const contactRelationsData = householdInterview.contactRelationsData;
    const dataId = relationsData.filter(
      (relation: RelationData) => relation.name === relationName
    )[0].dataId;

    let queryRequest = "";
    if (
      Array.isArray(
        householdInterview.mainContactData.contactToInterview[
          dataId as keyof ContactMultipleRelations
        ]
      )
    ) {
      const newDataArr =
        householdInterview.mainContactData.contactToInterview[
          dataId as keyof ContactMultipleRelations
        ];
      newDataArr.push(contactId);

      newDataArr.forEach(
        (id: string, i: number) => (queryRequest += `&fields[${dataId}][${i}]=${id}`)
      );
    } else {
      queryRequest += `&fields[${dataId}]=${contactId}`;
    }

    await axios({
      method: "post",
      url: `/addRelation`,
      headers: tokenConfig(getState).headers,
      data: {
        contactId: householdInterview.mainContactData.contactToInterview.ID,
        queryRequest: queryRequest,
      },
    })
      .then(async (res) => {
        if (res.data.result) {
          const SPAUserData = await dispatch(loadStateInterview(contactId, true));
          const relationArrIndex = relationsData.findIndex(
            (relation: RelationData) => relationName.split("-")[0] === relation.name
          );
          const countOfRelations = contactRelationsData.filter(
            (relation: ContactRelationData) =>
              relationName.split("-")[0] === relation.name.split("-")[0]
          );

          await axios({
            method: "post",
            url: `/getUserById`,
            headers: tokenConfig(getState).headers,
            data: { userId: contactId },
          })
            .then((res) => {
              const relationNameSuffix =
                countOfRelations.length && relationsData[relationArrIndex].multiple
                  ? `-${countOfRelations.length + 1}`
                  : "";
              dispatch({
                type: LOAD_CONTACT_RELATIONSHIP,
                payload: {
                  dataId: relationsData[relationArrIndex].dataId,
                  id: relationsData[relationArrIndex].id,
                  group: relationsData[relationArrIndex].group,
                  familyMemberDE: relationsData[relationArrIndex].familyMemberDE,
                  relationName: relationName + relationNameSuffix,
                  relationObj: {
                    contactToInterview: res.data.result,
                    SPAUserData: SPAUserData.data.result.items[0],
                  },
                },
              });

              dispatch(getDealsListByUserId(res.data.result.ID));
              dispatch(
                changeCurrentUserData(res.data.result.ID, relationName + relationNameSuffix)
              );
              dispatch(showProcessAlert("info-header", "Die Daten wurden aktualiesiert"));
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) =>
        dispatch(showProcessAlert("info-header2", "Irgendetwas ist schief gelaufen"))
      );

    dispatch(showProcessSpinner(false));
  };

export const getDealsListByUserId =
  (userId: string) => async (dispatch: TypedDispatch, getState: () => IRootState) => {
    const dealList = await axios({
      method: "post",
      url: `/getDealList`,
      headers: tokenConfig(getState).headers,
      data: { userId: userId },
    })
      .then((res) => res)
      .catch((e) => e);

    const dealsById = dealList?.data?.result?.map(async (dealIdResponse: { ID: string }) => {
      return await axios
        .all([axios.post(`/getDealById`, { dealId: dealIdResponse.ID }, tokenConfig(getState))])
        .then((res) => res)
        .catch((e) => e);
    });

    try {
      if (dealsById) {
        const deals: Array<Array<{ data: { result: DealInterface } }>> = await Promise.all(
          dealsById
        );

        deals?.forEach((deal: Array<{ data: { result: DealInterface } }>) => {
          if (deal.length) {
            dispatch({ payload: deal[0].data.result as DealInterface, type: MANAGE_DEALS });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const checkIncompleteRelations =
  (data: SPAUserDataType) => (dispatch: TypedDispatch, getState: () => IRootState) => {
    const contactToInterview =
      getState().householdInterviewReducer.mainContactData.contactToInterview;
    const mainContactChildRelations = contactToInterview.UF_CRM_1604487523;
    const mainContactPartnerRelationValue = contactToInterview.UF_CRM_1583875740207;

    if (data.ufCrm1_1643978538 > mainContactChildRelations.length) {
      for (let i = mainContactChildRelations.length; i < data.ufCrm1_1643978538; i++) {
        dispatch(
          addIncompleteContact({
            id: i,
            dataId: "UF_CRM_1604487523",
            group: 2,
            familyMemberDE: "Kind",
            name: "kid-" + i,
          })
        );
      }
    }
    if (
      "154" === mainContactPartnerRelationValue &&
      !contactToInterview.UF_CRM_1604482419 &&
      !contactToInterview.UF_CRM_1604482379
    ) {
      dispatch(
        addIncompleteContact({
          id: 0,
          group: 1,
          familyMemberDE: "Ehefrau / Ehemann",
          name: "marriedPartner",
        })
      );
    }
  };

export const setCurrentPerson = (currentPerson: string) => (dispatch: TypedDispatch) => {
  dispatch({ payload: currentPerson, type: SET_CURRENT_PERSON });
};

export const removeIncompleteContact =
  (contactData: IncompleteContactDataToRemove) => (dispatch: TypedDispatch) => {
    dispatch({ payload: contactData, type: REMOVE_INCOMPLETE_CONTACT });
  };

export const addIncompleteContact =
  (contactData: IncompleteContact) => (dispatch: TypedDispatch) => {
    dispatch({ payload: contactData, type: ADD_INCOMPLETE_CONTACT });
  };

export const handleReinitializeFormStatus = (status: boolean) => (dispatch: TypedDispatch) => {
  dispatch({ payload: status, type: DISABLE_REINITIALIZE_FORM });
};

export const savingFormDataStatus = (status: boolean) => (dispatch: TypedDispatch) => {
  dispatch({ payload: status, type: SET_SAVING_DATA_STATUS });
};

export const setStatus =
  (statusName: string, statusValue: boolean) => (dispatch: TypedDispatch) => {
    dispatch({ payload: { statusName: statusName, statusValue: statusValue }, type: SET_STATUS });
  };

export const removeDealsByUserId = (userId: string) => async (dispatch: TypedDispatch) => {
  dispatch({ payload: userId, type: REMOVE_DEALS_BY_CONTACT_ID });
};

export const deleteInterviewDealByDealId = (dealId: string) => async (dispatch: TypedDispatch) => {
  dispatch({ payload: dealId, type: REMOVE_DEAL_BY_DEAL_ID });
};
export const resetStep = (step: string) => (dispatch: TypedDispatch) => {
  dispatch({ payload: { step: step }, type: RESET_STEP });
};

export const clearInterviewFieldsArr = () => (dispatch: TypedDispatch) => {
  dispatch({ type: CLEAR_INTERVIEW_FIELDS_ARR });
};
