import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MyTextField from "../../vertragsUebersicht/VertragsContract/Contracts/ContractsElements/MyTextField";
import { passwortVergessen } from "../../../redux/index";
import { ErrorStateProps } from "../../../redux/errors/errorTypes";

interface DataProps {
  email: string;
}

interface PasswortVergessenProps {
  passwortVergessen: (data: DataProps) => void;
  errorReducer: ErrorStateProps;
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email().required("Dieses Feld ist erforderlich"),
});

export const PasswortVergessen = ({ passwortVergessen, errorReducer }: PasswortVergessenProps) => {
  const backgroundImage = require(`../../../assets/images/logo1.gif`);
  const backgroundImage2 = require(`../../../assets/images/logo.png`);
  return (
    <div className="loginWrapper">
      <div className="drawTopLeftLine"></div>
      <div className="drawTopRightLine"></div>
      <div className="drawDownLeftLine"></div>
      <div className="drawDownRightLine"></div>
      <div className="drawMiddleLeftLine"></div>
      <div className="drawMiddleRightLine"></div>
      <div className="loginContainer">
        <div
          className="logo1"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        ></div>
        <div
          className="logo2"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        ></div>
        <div className="loginContentContainer">
          <div className="my-text-field">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
              }}
            ></div>
            {errorReducer.id === "FORGOT_PASSWORD" ? (
              <h4 className={`${errorReducer.status === 200 ? "alert-info3" : "alert-info"}`}>
                {errorReducer.msg}
              </h4>
            ) : null}
            <h4 className="m-0 c-white fw-100">Passwort vergessen?</h4>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(data) => {
              passwortVergessen(data);
            }}
          >
            {() => (
              <Form className="flex-column">
                <MyTextField placeholder="E-mail" name="email" type="email" label="E-mail" />
                <Button type="submit" variant="contained" className="buttonPasswordForgot">
                  Passwort zurücksetzen
                </Button>
                <Button variant="contained" className="button">
                  <Link to="/login">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </Button>
                <Link to="/register">
                  <p className="text">Konto anlegen</p>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  errorReducer: state.errorReducer,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ passwortVergessen }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswortVergessen);
