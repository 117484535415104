import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  CHANGE_USER_DATA_SUCCESS,
  GET_USER_ID,
  ADD_USER_PSWD,
  PSWD_CONFIRM,
  PSWD_CHANGE_MSG,
  CLEAN_PSWD_STATE,
  HANDLE_MANDAT,
  SAVE_USER_AVATAR,
} from "./userTypes";

const initialState = {
  token: localStorage.getItem("token"),
  id: null,
  isAuthenticated: null,
  isLoading: null,
  userPswd: "",
  pswdIsConfirm: "",
  pswdChangeMsg: {},
  showMandat: false,
  contactAvatar: null,
  contactAvatarName: null,
  countsOfLogin: null,
  isAdmin: false,
  user: {
    contactEmail: "",
    contactVorname: "",
    contactNachname: "",
    contactStrasse: "",
    contactPostleitzahl: "",
    contactOrt: "",
    contactLand: "",
    contactGeburtsdatum: "",
    contactHandynummer: "",
    contactHandynummerId: "",
    contactEmailId: "",
    contactDocumentsLink: "",
    contactDocumentsId: "",
    contactBIC: "",
    contactIBAN: "",
    personalBitrixId: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_MANDAT:
      return {
        ...state,
        showMandat: action.payload,
      };

    case PSWD_CONFIRM:
      return {
        ...state,
        pswdIsConfirm: action.payload,
      };
    case CLEAN_PSWD_STATE:
      return {
        ...state,
        userPswd: "",
        pswdIsConfirm: "",
        pswdChangeMsg: {},
      };
    case PSWD_CHANGE_MSG:
      return {
        ...state,
        pswdChangeMsg: action.payload,
      };
    case GET_USER_ID:
      return {
        ...state,
        id: action.payload,
      };
    case ADD_USER_PSWD:
      return {
        ...state,
        userPswd: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        id: action.payload._id,
        isAdmin: action.payload.isAdmin,
        isAuthenticated: true,
        isLoading: false,
        user: { ...state.user, ...action.payload.user },
      };
    case LOGIN_SUCCESS:
      sessionStorage.setItem("login", "true");
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        id: action.payload.user.id,
        user: { ...state.user, id: action.payload.user.id },
        countsOfLogin: action.payload.user.countsOfLogin,
        isAuthenticated: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case SAVE_USER_AVATAR:
      return {
        ...state,
        contactAvatar: action.payload.contactAvatar,
        contactAvatarName: action.payload.contactAvatarName,
      };
    case CHANGE_USER_DATA_SUCCESS:
      return {
        ...state,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      sessionStorage.clear();
      return { ...state, token: null };
    default:
      return state;
  }
};

export default reducer;
