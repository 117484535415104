import {
  HANDLE_PROCESS_SPINNER,
  HANDLE_PROCESS_ALERT,
  HANDLE_REDIRECT_TO,
  ADD_TIMEOUT_ID,
  CLEAR_APP_SETTINGS,
  REMOVE_SETTIMEOUT_ID,
  ADD_INTERVAL_ID,
} from "./appSettingsTypes";

const initialState = {
  showProcessSpinner: false,
  alertType: "",
  alertMessage: "",
  redirectToPath: "",
  setTimeoutIds: [],
  setIntervalIds: [],
  eventListeners: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_SETTIMEOUT_ID:
      return {
        ...state,
        setTimeoutIds: state.setTimeoutIds.filter((id) => id !== action.payload),
      };
    case CLEAR_APP_SETTINGS:
      state.setTimeoutIds.forEach((id) => clearTimeout(id));
      state.setIntervalIds.forEach((id) => clearInterval(id));

      return {
        ...state,
        setTimeoutIds: [],
        setIntervalIds: [],
      };
    case ADD_INTERVAL_ID:
      return {
        ...state,
        setIntervalIds: [...state.setIntervalIds, action.payload],
      };
    case ADD_TIMEOUT_ID:
      return {
        ...state,
        setTimeoutIds: [...state.setTimeoutIds, action.payload],
      };
    case HANDLE_REDIRECT_TO:
      return {
        ...state,
        redirectToPath: action.payload,
      };
    case HANDLE_PROCESS_SPINNER:
      return {
        ...state,
        showProcessSpinner: action.payload,
      };
    case HANDLE_PROCESS_ALERT:
      return {
        ...state,
        alertType: action.payload.alertType,
        alertMessage: action.payload.alertMessage,
      };

    default:
      return state;
  }
};

export default reducer;
